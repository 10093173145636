import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// Item Component
import Meter from '../../items/meter'
import LoadingMeter from '../../items/loadingmeter'

// function
import { get_colorclass_from_class } from '../../func/getcolor'
import { JSTToMMDDHHmm } from '../../func/datetoview'

MstShelterList.propTypes = {
    // 選択中の市町村コード
    ctvCode: PropTypes.number,
    // 選択中の避難所名
    shelterName: PropTypes.string,
    // 選択中の避難所種類
    shelterType: PropTypes.string,
    // 選択中の市町村名
    ctvName: PropTypes.string,
    // 選択水位計更新関数
    updateChooseShelter: PropTypes.func,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
}

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 選択した市町村の開設中避難所表示
 * @param {number} CtvCode 市町村コード
 * @param {string} shelterName 避難所名
 * @param {string} shelterType 避難所種類
 * @param {string} ctvName 選択中の市町村名
 * @param {func} updateCHooseShelter 選択中の避難所を変更する関数
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @returns {JSX.Element}
 */
export default function MstShelterList(props){
    const { ctvCode, shelterName, shelterType, ctvName, updateChooseShelter, disasterId, checkpoint, isArchive } = props

    const onclickFunc = (props) => {
        updateChooseShelter(props[0], props[1], props[2])
    }
    
    const { data, error } = useSWR(
        () => ctvCode &&
            isArchive ? `${config.restUrl}/${disasterId}/${checkpoint}/pref/shelter/mst/list/${ctvCode}/`
            : `${config.restUrl}/pref/shelter/mst/list/${ctvCode}/`,
            fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は5回まで
                if(retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount : retryCount + 1}), 50000)
            },
            refreshInterval: 300000
        }
    )

    useEffect(() => {
        if(data && !error){
            updateChooseShelter(data.results[0].name, data.results[0].suitable_name, data.results[0].shelter_code)
        }
    }, [ctvCode, ctvName, data])


    const isLoading = !data && !error
    if(isLoading){
        return(
        <ListWrapper>
            <TitleWrapper>{ctvName}避難所一覧</TitleWrapper>
            {
                [...Array(15)].map((elem, i) => (
                    <LoadingMeter key={`loading-mtr-${i}`} />
                ))
            }
        </ListWrapper>
        )
    }

    if(error){
        return(
            <React.Fragment>
                <TitleWrapper>{ctvName}避難所一覧</TitleWrapper>
                <ErrorMsg msg={'避難所はありません'} />
            </React.Fragment>
        )
    }
    
    return(
        <ListWrapper>
            <TitleWrapper>{ctvName}避難所一覧</TitleWrapper>
            {
                data.results.map((elem, i) => (
                    <Meter
                        key={`mst_shelter_${i}`}
                        headtitle={elem.name}
                        subtitle={elem.dates ? JSTToMMDDHHmm(elem.dates) : ''}
                        value={elem.sort_name === '閉鎖' ? '閉鎖' : elem.cap_factor !== null  && elem.color !== 0 ? elem.cap_factor : '避難者なし'}
                        colorclass={get_colorclass_from_class(elem.color)}
                        is_btn={shelterName !== elem.name ? true : shelterType !== elem.suitable_name ? true : false}
                        isChoosed={shelterName === elem.name && shelterType === elem.suitable_name}
                        unit={elem.color === 1 ? '人' : elem.color === 0 ? '' : '%'}
                        changes={elem.change_flag}
                        updateStateFunc={onclickFunc}
                        funcProps={[elem.name, elem.suitable_name, elem.shelter_code]}
                    />
                ))
            }
        </ListWrapper>
    )
}

const ListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    &&& .meter{
        margin: 0.3rem;
        padding: 0.5rem;
        min-width: 180px;
        max-width: 240px;
        min-height: 82px;
    }
`
const TitleWrapper = styled.div`
    margin: 1rem 0;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    width: 100%;
`
