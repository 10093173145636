import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'


// Contents Components
import ShelterGraph from '../sheltergraph'
import ShelterMap from '../sheltermap'

// Atom Components
import ErrorMsg from '../../atoms/errormsg'

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 避難所マップを構成するコンポーネント
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @returns {JSX.Element}
 */
export default function ShelterMapView({disasterId='', checkpoint='', isArchive=false}){
    // 選択中の避難所名
    const [shelterName, setShelterName] = useState('')
    // 選択中の避難所タイプ
    const [shelterType, setShelterType] = useState('')
    // 選択中の行政区域コード
    const [shelterCtv, setShelterCtv] = useState('')
    // 選択中の避難所ID
    const [shelterCode, setShelterCode] = useState('')

    const { data, error } = useSWR(
        () =>
            isArchive ? `${config.restUrl}/${disasterId}/${checkpoint}/pref/shelter/map/`
            : `${config.restUrl}/pref/shelter/map/`,
            fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は5回まで
                if(retryCount >= 2) return
                // 5病後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000)
            },
            refreshInterval: 300000
        }
    )

    const isLoading = !data && !error
    const isData = !isLoading && !error
    const latlngData = isData && data.results

    function updateChooseShelter(name, type, ctv, shelter_code){
        setShelterName(name)
        setShelterType(type)
        setShelterCtv(ctv)
        setShelterCode(shelter_code)
    }

    if(isData && !shelterName){
        setShelterName(latlngData[0].shelter_name)
        setShelterType(latlngData[0].suitable)
        setShelterCtv(latlngData[0].ctv_code)
        setShelterCode(latlngData[0].shelter_code)
    }

    if(error){
        return(
        <React.Fragment>
            <ListWrapper>
                <ShelterMap
                    latlngData={latlngData}
                    firstZoomLevel={10}
                    updateChooseShelter={updateChooseShelter}
                />
            </ListWrapper>
            <GraphWrapper>
                <ErrorWrapper>
                    <ErrorMsg msg={'現在開設されている避難所はありません'} />
                </ErrorWrapper>
            </GraphWrapper>
        </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <ListWrapper>
                <ShelterMap
                    latlngData={latlngData}
                    firstZoomLevel={10}
                    updateChooseShelter={updateChooseShelter}
                />
            </ListWrapper>
            <GraphWrapper>
                <ShelterGraph
                    shelterName={shelterName}
                    shelterType={shelterType}
                    disasterId={disasterId}
                    checkpoint={checkpoint}
                    isArchive={isArchive}
                    ctvCode={shelterCtv}
                    shelterCode={shelterCode}
                />
            </GraphWrapper>
        </React.Fragment>
    )

}

// マップラッパー
const ListWrapper = styled.div`
    display: flex;
    width: 50vw;
    height: 100%;
    padding-left: 5vw;
    margin: 0.5rem 0;
`

// グラフラッパー
const GraphWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding-right: 5vw;
    padding-left: 1rem;
    margin: 0.5rem 0;
    height: auto;
    overflow-y: scroll;
`

// エラーラッパー
const ErrorWrapper = styled.div`
    display: flex;
    height: 100%;
`
