import { Typography } from '@mui/material';
import styled from '@emotion/styled';

export const TypographyBase = styled(Typography)(() => ({
    // fontSize: props.fontSize,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color:'white',
    textOverflow: 'ellipsis'
}));
export const TypographyNomal = styled(TypographyBase)(() => ({
    textAlign: 'left',
    fontSize: '0.9rem'
}));
export const TypographySmall = styled(TypographyBase)(() => ({
    textAlign: 'left',
    margin:'6px 0 3px 6px',
    fontSize: '0.8rem'
}));
export const TypographySmallMargin = styled(TypographyBase)(() => ({
    textAlign: 'left',
    margin:'16px 6px 3px 12px',
    fontSize: '0.8rem'
}));
export const TypographySubtitle = styled(TypographyBase)(() => ({
    textAlign: 'left',
    fontSize: '1.1rem',
    textOverflow:'unset',
    wordBreak:'normal'
}));

export const TypographyBold = styled(TypographyBase)(() => ({
    margin: '0',
    fontWeight: 'bold',
}));

export const TypographyTitle = styled(TypographyBold)(() => ({
    fontSize: '1.5rem',
}));
export const TypographyTitleCenter = styled(TypographyTitle)(() => ({
    padding: '5px',
    color:'white',
    textAlign: 'center'
}));
