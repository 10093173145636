import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { styled as muiStyled } from '@mui/system'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Clear from '@mui/icons-material/Clear'

import GraphControl from '../graphcontrol'

// MUI
import Drawer from '@mui/material/Drawer'

RainGraph.propTypes = {
    // 選択箇所のメシュコード
    latlng : PropTypes.array,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
    // シミュレーションID
    simulationId: PropTypes.string,
    // シミュレーションか否か
    isSimulation: PropTypes.bool,
}

const GraphWrapper = styled.div`
    height: calc(50vh - 30px - 2.5rem);
    background-color: #152451;
`

const StyleTabs = muiStyled(Tabs)(({ theme }) => ({
    minHeight: '32px',
    height: '32px',
    backgroundColor: '#152451',
    color: '#FFFFFF',
    arignItems: 'center',
}))
const StyleTab = muiStyled(Tab)(({ theme }) => ({
    minHeight: '32px',
    height: '32px',
    backgroundColor: '#152451',
    color: '#FFFFFF',
    arignItems: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    scrollbarWidth: 'none',
    "&:hover": {
        backgroundColor: '#223C6D',
    },
}))


/**
 * グラフのIDとクラス名を取得する
 * @param {string} index 値の種類
 * @returns {object}
 */
function allyProps(index){
    return {
        id: `graph-tap-${index}`,
        'aria-controls' : `graph-tap-${index}`,
    };
}

/**
 * 雨量グラフ
 * @param {array} latlng 緯度経度
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @param {bool} isSimulation シミュレーションか否か
 * @param {string} simulationId シミュレーションID
 * @returns {JSX.Element}
 */
export default function RainGraph(props){
    const {
        latlng,
        disasterId='',
        checkpoint='',
        isArchive=false,
        simulationId='',
        isSimulation=false,
        isRainGraphDrawerOpen,
        displayNavigationDrawer
    } = props
    const [chooseCategory, setChooseCategory] = useState(GraphSettings[0].category)
    const handleChange = (event, newValue) => {
        setChooseCategory(newValue)
    }


    return(
        <Drawer anchor="bottom" open={isRainGraphDrawerOpen} sx={{
            '& .MuiDrawer-paper': {
                backgroundColor: 'transparent',
                color: '#fff',
                overflow: 'visible'
            }
        }}>
            <IconButton
                sx={{
                position: 'absolute',
                right: 0,
                top: '-40px',
                color: '#fff'
                }}
                onClick={() => displayNavigationDrawer('map')}
            ><Clear /></IconButton>
            <GraphWrapper>
                <AppBar position='static'>
                    <StyleTabs
                        value={chooseCategory}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable force tabs"
                        TabIndicatorProps={{
                            style: {
                            backgroundColor: "#318AB7"
                            }
                        }}
                    >
                    {
                        GraphSettings.map((elem, i) =>
                            <StyleTab
                                key={i.toString()}
                                value={elem.category}
                                label={elem.text}
                                {...allyProps(elem.category)}
                            />
                        )
                    }
                    </StyleTabs>
                </AppBar>
                {
                    GraphSettings.map((elem, i) =>
                        <GraphControl
                            key={elem.category}
                            chooseCategory={chooseCategory}
                            category={elem.category}
                            latlng={latlng}
                            info={elem}
                            disasterId={disasterId}
                            checkpoint={checkpoint}
                            isArchive={isArchive}
                            simulationId={simulationId}
                            isSimulation={isSimulation}
                        />
                    )
                }
            </GraphWrapper>
        </Drawer>
    )

}

const GraphSettings = [
    {
        category: 'h1_msh',
        text: '1時間雨量',
        unit: 'mm/h',
        ticks: [20, 30, 50, 80],
        domains: [0, 80],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'h24_msh',
        text: '24時間雨量',
        unit: 'mm',
        ticks: [150, 200, 250, 300],
        domains: [0, 300],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'h48_msh',
        text: '48時間雨量',
        unit: 'mm',
        ticks: [200, 250, 300, 400],
        domains: [0, 400],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'sr_msh',
        text: '土壌雨量指数',
        unit: '',
        ticks: [80, 120, 150, 200],
        domains: [0, 200],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: false
    },
    {
        category: 'rsk_msh',
        text: '土砂災害発生リスク',
        unit: '',
        ticks: [300, 600, 1000, 2000],
        tickLabel: ['レベル1', 'レベル2', 'レベル3', 'レベル4'],
        domains: [0, 200],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: false
    },
    // {
    //   category: 'rfs_msh',
    //   text: '河川洪水発生リスク',
    //   unit: '',
    //   ticks: [50, 75, 100, 150],
    //   tickLabel: ['レベル1', 'レベル2', 'レベル3', 'レベル4'],
    //   domains: [0, 200],
    //   xaxisKeyList: ['date'],
    //   dataKey: 'value',
    //   isUnit: false
    // },
    {
        category: 'h1_bsn',
        text: '流域1時間雨量',
        unit: 'mm/h',
        ticks: [20, 30, 50, 80],
        domains: [0, 80],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'h24_bsn',
        text: '流域24時間雨量',
        unit: 'mm',
        ticks: [150, 200, 250, 300],
        domains: [0, 300],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'h48_bsn',
        text: '流域48時間雨量',
        unit: 'mm',
        ticks: [200, 250, 300, 400],
        domains: [0, 400],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'of_bsn',
        text: '流域流出量',
        unit: '',
        ticks: [2, 5, 8, 15],
        domains: [0, 15],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: false
    },

]
