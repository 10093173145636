import React, { useState } from 'react'
import styped from 'styled-components'

// Template components
import ShelMapDashboard from '../../templates/shelmapdashboard'

/**
 * 避難所マップ画面
 * @returns {JSX.Element} 避難所マップテンプレート
 */
export default function ShelterMap(){

  return(
    <ShelMapDashboard/>
  )

}
