import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

MeterTitle.propTypes = {
  children: PropTypes.string
}

const StyleMeterTitle = styled.div`
  margin: 0 0 1rem 0;
  text-align: center;
  border-radius: 5px;
  border-bottom: thin ridge;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05em;
`

/**
 * メーターカードのタイトル
 * @param {string} children タイトルテキスト 
 * @returns {JSX.Element}
 */
export default function MeterTitle(props) {
  const { children } = props

  return(
    <StyleMeterTitle>
      {children}
    </StyleMeterTitle>
  )

}
