import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Item component
import BarGraph from '../../items/bargraph'
import LineGraph from '../../items/linegraph'

// Atom component
import ErrorMsg from '../../atoms/errormsg'

// original function
import { getTickList } from '../../func/getticklist'
import { getGraphLine } from '../../func/getgraphline'

const fetcher = url => axios.get(url).then(res => res.data)

GraphControl.propTypes = {
    // タブで選択している種類
    chooseCategory: PropTypes.string,
    // 自分の種類，一致すればグラフを表示
    category: PropTypes.string,
    // 選択している場所の緯度経度
    latlng: PropTypes.array,
    // グラフ情報
    info: PropTypes.object,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
    // シミュレーションID
    simulationId: PropTypes.string,
    // シミュレーションか否か
    isSimulation: PropTypes.bool,
}

/**
 * グラフのプロット
 * @param {string} chooseCategory タブで選択している値の種類
 * @param {string} category 値自身の種類
 * @param {array} latlng 緯度経度
 * @param {object} info グラフ情報
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @param {string} simulationId シミュレーションID
 * @param {bool} isSimulation シミュレーションか否か
 * @returns {JSX.Element}
 */
function GraphPlot(props){
    const { latlng, info, disasterId, checkpoint, isArchive, simulationId, isSimulation } = props
    // データ取得
    const { data, error } = useSWR(
        () => (latlng && info) ?
            isSimulation ? `${config.restUrl}/sim/${simulationId}/${checkpoint}/rain/msh/graph/${info.category}/${latlng[0]}/${latlng[1]}/`
            : isArchive ? `${config.restUrl}/${disasterId}/${checkpoint}/rain/msh/graph/${info.category}/${latlng[0]}/${latlng[1]}/`
            : `${config.restUrl}/rain/msh/graph/${info.category}/${latlng[0]}/${latlng[1]}/`
            : null,
            fetcher,
            {
                onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                    // 再試行は5回まで
                    if(retryCount >= 2) return
                    // 5秒後に再試行
                    setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
                },
                refreshInterval: 300000
            }
    )
    const isLoading = !data && !error

    if(isLoading){
        return(<React.Fragment />)
    }
    if(error){
        return(<ErrorMsg msg={'データが見つかりませんでした'} />)
    }

    // グラフの設定
    // グラフの設定
    const stdLength = data.RainStd.length
    const maxMonthlyValue = stdLength !== 0 ? data.RainStd[stdLength-1] : null
    // 月間最多雨量があるならば，グラフの基準線を追加する
    const domains = info.category === 'sr_msh' ?
                getTickList(data.results, data.RainStd, maxMonthlyValue, false)
                : getTickList(data.results, info.ticks, maxMonthlyValue, false)
    const graphLines = info.category === 'sr_msh' && data.RainStd.length !== 0 ?
                getGraphLine(data.RainStd, ['注意報', '警報', '特別警報'], info.category, null, '', data.dates)
                : getGraphLine(info.ticks, info.tickLabel, info.category, maxMonthlyValue, '', data.dates)

    if(info.isUnit){
        return(
            <BarGraph
                category={info.category.split('_')[0]}
                data={data.results}
                domains={domains}
                unit={info.unit}
                xaxisKeyList={info.xaxisKeyList}
                dataKey={info.dataKey}
                lines={graphLines}
                lineWidth={3}
            />
        )
    }
    else{
        return(
            <LineGraph
                category={info.category.split('_')[0]}
                data={data.results}
                domains={domains}
                unit={info.unit}
                xaxisKeyList={info.xaxisKeyList}
                dataKey={info.dataKey}
                lines={graphLines}
                lineWidth={3}
            />
        )
    }
}

/**
 * グラフコントロール
 * @param {string} chooseCategory タブで選択している値の種類
 * @param {string} category 値自身の種類
 * @param {array} latlng 緯度経度
 * @param {object} info グラフ情報
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @param {string} simulationId シミュレーションID
 * @param {bool} isSimulation シミュレーションか否か
 * @returns {JSX.Element}
 */
export default function GraphControl(props){
    const { chooseCategory, category, latlng, info, disasterId, checkpoint, isArchive, simulationId, isSimulation } = props
    return (
        <div
            role="tabpanel"
            hidden={chooseCategory !== category}
            style={{height: 'calc(100% - 32px)'}}
            id={`wrapped-tabpanel-${category}`}
            aria-labelledby={`wrapped-tab-${category}`}
        >
            {chooseCategory === category && (
                <GraphPlot
                    latlng={latlng}
                    info={info}
                    disasterId={disasterId}
                    checkpoint={checkpoint}
                    isArchive={isArchive}
                    simulationId={simulationId}
                    isSimulation={isSimulation}
                />
            )}
        </div>
    );
}
