import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// 第1目盛り
const Tick1st = styled.div`
  position: absolute;
  margin-top: 2.15rem;
  margin-left: -0.5;
  width: 2px;
  height: 1rem;
  background-color: white;
  left: ${(props) => props.tick.percent}%;
`
// 第2目盛り
const Tick2nd = styled.div`
  position: absolute;
  margin-top: 2.15rem;
  margin-left: -0.5;
  width: 0.5px;
  height: 0.75rem;
  background-color: gray;
  left: ${(props) => props.tick.percent}%;
`
// 目盛り補助テキスト小
const MiniScales = styled.div`
  position: absolute;
  margin-top: 3.25rem;
  font-size: 18px;
  text-align: center;
  margin-left: ${(props) => -(100 / props.count) / 2}%;
  width: ${(props) => 100 / props.count}%;
  left: calc(${(props) => props.tick.percent}% - 18px);
  color: white;
`
const BigScales = styled.div`
  position: absolute;
  margin-top: 4.35rem;
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-left: ${(props) => -(100 / props.count) / 2}%;
  width: 110;
  left: calc(${(props) => props.tick.percent}% - 46px);
`

/**
 * テキストを伴うスライダーのメモリ
 * @param {object} tick メモリの表示位置情報
 * @param {*} count
 * @param {string} time 日付のうちの時間部分
 * @param {string} date 日付のうちの月日部分
 * @param {number} index スライダーのインデックス値
 * @param {bool} isForecast 予測か否か
 * @param {number} chooseFirstNum 最初に選択している日付のインデックス値
 * @returns {JSX.Element}
 */
export default function TickWithText(props){
  const {tick, count, time, date, index, isForecast, chooseFirstNum} = props
  const timesplit = time.split(':')
  // 水位予測のときは，６０分間隔となるため，書式を変更
  if(isForecast){
    const forecastHours = index - chooseFirstNum - 1
    if(forecastHours === 6 || forecastHours === 15){
      return(
        <div>
          <Tick1st
            tick={tick}
          />
          <MiniScales
            tick={tick}
            count={count}
          >
            +{forecastHours}h
          </MiniScales>
        </div>
      )
    }
    else{
      return(
        <div>
          <Tick2nd
            tick={tick}
          />
        </div>
      )
    }
  }
  // 通常時は2時間おきに時間を表示
  else if(Number(timesplit[1]) === 0 && Number(timesplit[0]) % 2 === 0) return(
    <div>
      <Tick1st
        tick={tick}
      />
      <MiniScales
        tick={tick}
        count={count}
      >
        {
          !isForecast ? time
          : null
        }
      </MiniScales>
      {
        time === '00:00'  ? <BigScales
          tick={tick}
          count={count}
        >{date}</BigScales>
        : <React.Fragment />
      }
    </div>

  )
  return(
    <div>
      <Tick2nd
        tick={tick}
      />
    </div>
  )

}
