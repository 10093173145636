import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import axios from 'axios'
import { config } from '../../../config.jsx'
import { dateToMMDDHHmm } from '../../../func/datetoview'

// Item Components
import Meter from '../../items/meter'
import PullDown from '../../items/pulldown'
import Toggles from '../../items/toggles'
import LoadingMeter from '../../items/loadingmeter'
import useSWR from 'swr'

// Atom component
import ErrorMsg from '../../../atoms/errormsg'
import { get_colorclass } from '../../../func/getcolor.jsx'

// MUI Component
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Clear from '@mui/icons-material/Clear'

InfiniteRank.propTypes = {
    // コードを更新する関数
    updateCode: PropTypes.func,
    // 緯度経度を更新する関数
    updateLatlng: PropTypes.func,
    // 日付を変更する関数
    updateChooseDate: PropTypes.func,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
    // シミュレーションID
    simulationId: PropTypes.string,
    // シミュレーションか否か
    isSimulation: PropTypes.bool,
}

// ランクのラッパー
const TabRankWrapper = styled.div`
    height: 75svh;
    background-color: #152451;
`
const RankWrapper = styled.div`
    height: calc(75svh - 80px);
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar{
    display: none;
    }
`
const TabWrapper = styled.div`
    height: 80px;
    margin: 0.25rem 0;
`

// メニューに表示する項目リスト
const menuArray = [
    {
        menu: 'h1_msh',
        text: '1時間雨量',
        unit: 'mm/h'
    },
    {
        menu: 'h24_msh',
        text: '24時間雨量',
        unit: 'mm'
    },
    {
        menu: 'h48_msh',
        text: '48時間雨量',
        unit: 'mm'
    },
    {
        menu: 'sr_msh',
        text: '土壌雨量指数',
        unit: ''
    },
    {
        menu: 'rsk_msh',
        text: '土砂災害発生リスク',
        unit: ''
    },
    // {
    //   menu: 'rfs_msh',
    //   text: '河川洪水発生リスク',
    //   unit: ''
    // },
    {
        menu: 'h1_bsn',
        text: '流域平均1時間雨量',
        unit: 'mm/h'
    },
    {
        menu: 'h24_bsn',
        text: '流域平均24時間雨量',
        unit: 'mm'
    },
    {
        menu: 'h48_bsn',
        text: '流域平均48時間雨量',
        unit: 'mm'
    },
    {
        menu: 'of_bsn',
        text: '流域平均流出量',
        unit: ''
    },
]


const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 雨量ランキング
 * @param {func} updateCode 4次メッシュを更新する関数
 * @param {func} updateLatlng 緯度経度を更新する関数
 * @param {func} updateChooseDate 日付を変更する関数
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @param {bool} isSimulation シミュレーションか否か
 * @param {string} simulationId シミュレーションID
 * @returns {JSX.Element}
 */
export default function InfiniteRank(props){
    const {
        updateLatlng,
        updateChooseDate,
        disasterId='',
        checkpoint='',
        isArchive=false,
        simulationId='',
        isSimulation=false,
        isRankingDrawerOpen,
        displayNavigationDrawer
    } = props

    // 現在選択されている種類が何かを管理する
    const [chooseMenu, setChooseMenu] = useState(menuArray[0].menu)
    // 過去か現在バージョン化を管理する
    const [isPast, setIsPast] = useState(false)

    const { data, error, } = useSWR(
        () =>
            isSimulation ? `${config.restUrl}/sim/${simulationId}/${checkpoint}/rain/rank/${chooseMenu}/${isPast}/`
            : isArchive ? `${config.restUrl}/${disasterId}/${checkpoint}/rain/rank/${chooseMenu}/${isPast}/`
            : `${config.restUrl}/rain/rank/${chooseMenu}/${isPast}/`,
            fetcher,
        {
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
            // 再試行は5回まで
            if(retryCount >= 2) return
            // 5秒後に再試行
            setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
        },
            refreshInterval: 300000
        }
    )

    const isLoading = !data && !error

    // 子コンポーネントで更新されたとき用関数
    function updateIsPast(props){
        setIsPast(props)
    }
    function updateChooseMenu(props){
        setChooseMenu(props)
    }


    const onclickUpdate = (props) => {
        displayNavigationDrawer('map')
        updateLatlng(props[0])
        if(!props[2]){
            updateChooseDate(props[1])
        }
    }

    if(isLoading){
        return(
            <React.Fragment>
                <Drawer anchor="bottom" open={isRankingDrawerOpen} sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: 'transparent',
                        color: '#fff',
                        overflow: 'visible'
                    }
                }}>
                    <TabRankWrapper>
                        <IconButton
                            sx={{
                            position: 'absolute',
                            right: 0,
                            top: '-40px',
                            color: '#fff'
                            }}
                            onClick={() => displayNavigationDrawer('map')}
                        ><Clear /></IconButton>
                        <TabWrapper>
                        <PullDown
                            menuArray={menuArray}
                            menu={chooseMenu}
                            updateMenu={updateChooseMenu}
                        />
                        </TabWrapper>
                        <RankWrapper>
                            {
                                [...Array(6)].map((elem, i) => (
                                <LoadingMeter key={`loading-mtr-${i}`} />
                                ))
                            }
                        </RankWrapper>
                    </TabRankWrapper>
                </Drawer>
            </React.Fragment>
        )
    }

    const isEmpty = data?.results.length === 0

    if(isEmpty || error){
        return(
            <React.Fragment>
                <Drawer anchor="bottom" open={isRankingDrawerOpen} sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: 'transparent',
                        color: '#fff',
                        overflow: 'visible'
                    }
                }}>
                    <TabRankWrapper>
                        <IconButton
                            sx={{
                            position: 'absolute',
                            right: 0,
                            top: '-40px',
                            color: '#fff'
                            }}
                            onClick={() => displayNavigationDrawer('map')}
                        ><Clear /></IconButton>
                        <TabWrapper>
                            <PullDown
                                menuArray={menuArray}
                                menu={chooseMenu}
                                updateMenu={updateChooseMenu}
                            />
                        </TabWrapper>
                        <RankWrapper>
                            <ErrorMsg msg={'データが見つかりませんでした'} />
                        </RankWrapper>
                    </TabRankWrapper>
                </Drawer>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <Drawer anchor="bottom" open={isRankingDrawerOpen} sx={{
                '& .MuiDrawer-paper': {
                    backgroundColor: 'transparent',
                    color: '#fff',
                    overflow: 'visible'
                }
            }}>
                <IconButton
                    sx={{
                    position: 'absolute',
                    right: 0,
                    top: '-40px',
                    color: '#fff'
                    }}
                    onClick={() => displayNavigationDrawer('map')}
                ><Clear /></IconButton>
                <TabRankWrapper>
                    <TabWrapper>
                        <PullDown
                            menuArray={menuArray}
                            menu={chooseMenu}
                            updateMenu={updateChooseMenu}
                        />
                    </TabWrapper>
                    <RankWrapper>
                        <div>
                            {data.results.map((elem, i) =>
                                <Meter
                                    key={`ranks-${i}`}
                                    headtitle={elem.txt_name}
                                    subtitle={dateToMMDDHHmm(elem.dates)}
                                    value={elem.value}
                                    colorclass={get_colorclass(chooseMenu.split('_')[0] !== 'sr' ? elem.value : elem.level, chooseMenu.split('_')[0] !== 'sr' ? chooseMenu.split('_')[0] : 'sr_msh')}
                                    is_btn={true}
                                    unit={menuArray.find(element => element.menu === chooseMenu).unit}
                                    updateStateFunc={onclickUpdate}
                                    funcProps={[[elem.latitude, elem.longitude], elem.dates, isPast]}
                                />
                            )}
                        </div>
                    </RankWrapper>
                </TabRankWrapper>
            </Drawer>
        </React.Fragment>
    )
}
