// 河川水位の上限と下限を決めるリストを取得
// 引数
/*
    dicts : データオブジェクト
    ticklist : グラフの基準となる値のリスト
    maxValue: 過去最多雨量
    is_minus : マイナスの可能性があるか
*/
/**
 * 河川水位の上限と下限を設定するリストを取得
 * @param {object} obj データオブジェクト
 * @param {array} ticklist 値のリスト
 * @param {number} maxValue 過去最大雨量
 * @param {bool} is_minus マイナスがあるか否か
 * @returns {array} 最大値と最小値のリスト
 */
export const getTickList = (obj, ticklist, maxValue, is_minus) => {
    // undefinedを削除
    ticklist = ticklist.filter(v => v)

    const datalist = [];
    for(let i=0; i<obj.length; i++){
        datalist.push(obj[i].value)
    }
    if(maxValue){
        datalist.push(maxValue)
    }

    const tick_max = ticklist.length === 0 ? 0 : Math.max(...ticklist)
    const value_max = Math.max(...datalist)
    const value_min = Math.min(...datalist)

    if(tick_max === 0 || value_max > tick_max){
        return(
            is_minus && value_min < 0 ?
                [Math.floor(value_min - 0.5), Math.ceil(value_max + 0.5)]
                : [0, Math.ceil(value_max + value_max / 10)]
        )
    }
    else{
        return(
            is_minus ?
                [Math.floor(value_min - 0.5), Math.ceil(tick_max + 0.5)]
                : [0, Math.ceil(tick_max + tick_max / 10)]
        )
    }
}
