import React, { useEffect } from 'react'
import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet'
import 'leaflet.vectorgrid'
import { config } from '../../../config.jsx'

/**
 * 河川名のポップアップ表示用のタイル
 * @param {func} setPopupRiverName ポップアップの河川名を更新する関数 
 * @param {func} setIsPopup ポップアップ表示非表示を切り替える関数 
 * @returns 
 */
export default function RiverTile(props) {

  // lambda経由でpmtilesのデータを取得
  // const url = 'https://d2mozqb4llwz5t.cloudfront.net/river_geom/{z}/{x}/{y}.mvt'
  const url = `${config.pmtilesUrl}/static/river_geom/{z}/{x}/{y}.mvt`

  const { layerContainer, map } = useLeafletContext()

  let options = {
    className: 'riverLayers',
    rendererFactory: L.canvas.tile,
    vectorTileLayerStyles: {
      river_geom: function (properties, zoom) {
        return {
          fill: false,
          fillOpacity: 0,
          color: 'rgb(0, 0, 0)',
          weight: 0
        }
      }
    },
    interactive: true
  }

  const vectorGrid = L.vectorGrid.protobuf(url, options)

  // マウスホバー時の挙動
  vectorGrid.on('mouseover', function (e) {
    let name = e.propagatedFrom.properties.name
    props.setPopupRiverName(name)
    props.setPopupLatlng(e.latlng)
    props.setIsPopup(true)
  })
  vectorGrid.on('mouseout', function (e) {
    props.setPopupRiverName('')
    props.setIsPopup(false)
  })

  // ライブラリ内のクリックイベントを上書き（leaflet.vectorgrid内でバグがあるため）
  L.Canvas.Tile.include({
    _onClick: function (e) {
      var point = this._map.mouseEventToLayerPoint(e).subtract(this.getOffset());
      var layer;
      var clickedLayer;

      for (var id in this._layers) {
        layer = this._layers[id];
        if (
          layer.options.interactive &&
          layer._containsPoint(point) &&
          !this._map._draggableMoved(layer)
        ) {
          clickedLayer = layer;
        }
      }
      if (clickedLayer) {
        // 親のクリックイベントを発火
        this._fireEvent([clickedLayer], e);
      }
    },
  });

  const container = layerContainer || map

  const onUpdate = (e) => {
    setTimeout(
      () => {
        container.removeLayer(vectorGrid)
      },
      250
    )
  }

  useEffect(() => {
    container.addLayer(vectorGrid)
    return () => {
      onUpdate();
    }
  }, [])

  return null

}
