import React from 'react'
import FormDatePicker from '../date_picker'
import {TypographySmall} from '../../style/typography';
import { compareDate} from '../../func/datetoview.jsx'

export default function FormDatePickerStartEnd(props) {
  const onStartingDateChange = (newValue) => {
    console.log(newValue)
    const endValue = props.getValues(props.simEndDatetime)
    if (newValue === null || newValue === undefined || newValue === '') {
      props.setStartDateError('開始日を入力してください')
      props.setValue(props.simStartDatetime, null)
      return
    }
    if (Number.isNaN(newValue.getDate())) {
      props.setValue(props.simStartDatetime, newValue)
      props.setStartDateError('開始日を日付形式で入力してください')
      return
    }
    if (endValue !== null && endValue !== undefined && endValue !== ''
      && !compareDate(new Date(newValue), new Date(endValue))) {
      props.setStartDateError('開始日は終了日よりも前の日付を指定してください')
      props.setValue(props.simStartDatetime, newValue)
      return
    }else{
      props.setEndDateError('')
    }
    props.setStartDateError('')
    props.setValue(props.simStartDatetime, newValue)
    return
  }

  const onEndingDateChange = (newValue) => {
    const startValue = props.getValues(props.simStartDatetime)
    if (newValue === null || newValue === undefined || newValue === '') {
      props.setEndDateError('終了日を入力してください')
      props.setValue(props.simEndDatetime, null)
      return
    }
    if (Number.isNaN(newValue.getDate())) {
      props.setEndDateError('終了日を日付形式で入力してください')
      props.setValue(props.simEndDatetime, newValue)
      return
    }
    if (startValue !== null && startValue !== undefined && startValue !== ''
      && !compareDate(new Date(startValue), new Date(newValue))) {
      props.setEndDateError('終了日は開始日よりも後の日付を指定してください')
      props.setValue(props.simEndDatetime, newValue)
      return
    }else{
      props.setStartDateError('')
    }
    props.setEndDateError('')
    props.setValue(props.simEndDatetime, newValue)
    return
  }

  return (
    <>
    <TypographySmall>開始日時</TypographySmall>
      <FormDatePicker
        name={props.simStartDatetime}
        control={props.control}
        hintText="開始日"
        defaultValue={props.startDateTime}
        onChange={onStartingDateChange}
        dateError={props.startDateError}
      />
    <TypographySmall>終了日時</TypographySmall>
      <FormDatePicker
        name={props.simEndDatetime}
        control={props.control}
        hintText="終了日"
        defaultValue={props.endDateTime}
        onChange={onEndingDateChange}
        dateError={props.endDateError}
      />
    </>
  )
}