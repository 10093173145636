import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

LinkText.propTypes = {
  // 遷移先URL
  url : PropTypes.string,
  // どこで遷移するかの設定
  target: PropTypes.string,
  // テキスト
  text: PropTypes.string,
}

/**
 * URL
 * @param {string} url 遷移先URL
 * @param {string} target 遷移時にどのように開くかの設定(新規タブなど)
 * @param {string} text 表示するテキスト
 * @returns {JSX.Element}
 */
export default function LinkText(props){
  const {url, target, text} = props
  return(
    <Wrapper>
      <Link to={url} target={target}>{text}</Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 18px;
  font-weight: bold;
  margin-left: 1.5rem;
  &&& a{
    text-decoration: none;
    color: rgba(255, 255, 255, 0.75);
  }
  &:hover{
    cursor: pointer;
  }
  &&& a:hover{
    cursor: pointer;
  }
`
