import React from 'react'
import ArchivePage from '../../templates/archivedashboard'

/**
 * アーカイブ画面
 * @returns {JSX.Element} アーカイブテンプレート
 */
export default function Archive(){
    return(<ArchivePage/>)
}
