import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
    Marker,
    Popup
} from 'react-leaflet'
import { CRS } from 'leaflet'
import { useFullScreenHandle } from 'react-full-screen'
import {
    Fullscreen,
    FullscreenExit,
} from '@mui/icons-material'

import { config } from '../../../config.jsx'
import {
    StyleMapContainer,
    StyleFullScreen,
    FullScreenIconButton,
} from '../../style/map_components'

// Atomic Component
import RiverTile from '../../atoms/rivertile'

// Item Component
import MapTimeSlider from '../../items/map_slider'
import MapEventHandler from '../../items/map_event_handler'
import CommonMap from '../common_map_view'

// Original function
import { GetData } from '../../../func/get_data_swr';

import { BASE_LAYER_LIST } from '../../../const/general'

// 大分県領域 funcType : prefにアクセスできるIDリスト
const ACCESSIBLE_ID = config['accessibleId']

// Mapコンポーネントのラッパー
const MapWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`
// ポップアップのデザイン
const PopupText = styled.span`
    font-weight: bold;
`

DashboardMap.propTypes = {
    // 緯度経度
    latlng: PropTypes.array,
    // 選択されている日付
    chooseDate: PropTypes.string,
    // 選択されている日付のインデックス
    chooseNum: PropTypes.number,
    // 最新の解析雨量取得時間
    newestDate: PropTypes.string,
    // 日付リスト
    dateArray: PropTypes.array,
    // 現在選択されている時刻が予測か
    isForecast: PropTypes.bool,
    // 位置選択時，緯度経度を更新する関数
    updateLatlng: PropTypes.func,
    // キーとなる日付の状態を更新する関数
    updateChooseDate: PropTypes.func,
    // 日付のインデックスの状態を更新する関数
    updateChooseNum: PropTypes.func,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
    // シミュレーションID
    simulationId: PropTypes.string,
    // シミュレーションか否か
    isSimulation: PropTypes.bool,
}

/**
 * 雨量マップ表示
 * @param {array} latlng 緯度経度
 * @param {string} chooseDate 選択されている日付
 * @param {number} chooseNum 選択されている日付のインデックス値
 * @param {string} newestDate 最新の解析雨量取得時間
 * @param {array} dateArray 日付リスト
 * @param {bool} isForecast 現在表示されている時刻が予想か否か
 * @param {func} updateLatlng 位置選択時，緯度経度を更新する関数
 * @param {func} updateChooseDate 選択している日付を更新する関数
 * @param {func} updateChooseNum 選択している日付のインデックス値を更新する関数
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @param {bool} isSimulation シミュレーションか否か
 * @param {string} simulationId シミュレーションID
 * @returns {JSX.Element}
 */
export default function DashboardMap(props) {
    const {
        disasterId = '',
        checkpoint = '',
        isArchive = false,
        simulationId = '',
        isSimulation = false,
        latlng,
        chooseDate,
        chooseNum,
        newestDate,
        dateArray,
        isForecast,
        updateLatlng,
        updateChooseDate,
        updateChooseNum
    } = props

    // Mapのstateを宣言
    //const [map, setMap] = useState(null)
    // ベクタータイル選択レイヤー名
    const [seletedBaseLayer, setSeletedBaseLayer] = useState(BASE_LAYER_LIST[0])
    // ポップアップに表示する河川名
    const [popupRiverName, setPopupRiverName] = useState('')
    // ポップアップを表示するか否か
    const [isPopup, setIsPopup] = useState(false)
    // ポップアップを表示する緯度経度
    const [popupLatlng, setPopupLatlng] = useState([])
    // 全画面表示切り替え
    const fullscreenHandle = useFullScreenHandle()

    // クロノロジー表示のためのユーザー情報取得
    const userName = JSON.parse(localStorage.getItem('user'))
    // クロノロジーを表示するユーザーか
    const isChrUser = ACCESSIBLE_ID.includes(userName['id'])

    // クロノロジーのポイント情報を取得する
    let url = ''
    let isData = false
    let chrData = null
    if (!isSimulation) {
        url = `/pref/chr/map/`
        if (isArchive) {
            url = `/${disasterId}/${checkpoint}/pref/chr/map/`
        }
        const { data, loading, error } = GetData(url);
        // データ取得時のフラグ
        const isLoading = !data && !error
        isData = !isLoading && !error
        chrData = isData ? data.results.filter(e => e.choose_num <= chooseNum) : false
    }

    // Then click for Map full screen button, switching full screen
    function switchingFullScreen(e) {
        fullscreenHandle.active ?
            fullscreenHandle.exit() :
            fullscreenHandle.enter();
    }
    // 全画面ボタンのスタイル
    const fullscreenButtonStyle = { width: '36px', height: '36px' }

    // ローディングと同様の扱いを行う
    if (latlng === null) return (
        <StyleFullScreen
            handle={fullscreenHandle}
            enabled={true}
        >
            <MapWrapper>
                <MapTimeSlider
                    chooseNum={chooseNum}
                    updateChooseNum={updateChooseNum}
                    dateArray={dateArray}
                    newestDate={newestDate}
                    sliderMax={dateArray.length - 1}
                    startDateTime={new Date(dateArray[0])}
                    is_forecast={isForecast}
                />
            </MapWrapper>
        </StyleFullScreen>
    )

    return (
        <StyleFullScreen
            handle={fullscreenHandle}
            enabled={true}
        >
            <MapWrapper>
                <StyleMapContainer
                    center={latlng}
                    zoom={10}
                    maxZoom={14}
                    crs={CRS.EPSG3857}
                    prefCanvas={true}
                >
                    <CommonMap
                        seletedBaseLayer={seletedBaseLayer}
                        isChrUser={isChrUser}
                        chrData={chrData}
                        isData={isData}
                    />
                    <MapEventHandler
                        latlng={latlng}
                        dateArray={dateArray}
                        chooseNum={chooseNum}
                        seletedBaseLayer={seletedBaseLayer}
                        chooseMax={dateArray.length}
                        updateLatlng={updateLatlng}
                        setSeletedBaseLayer={setSeletedBaseLayer}
                        isArchive={isArchive}
                        isSimulation={isSimulation}
                        disasterId={disasterId}
                        simulationId={simulationId}
                        checkpoint={checkpoint}
                    />
                    {
                        seletedBaseLayer.is_river &&
                        <RiverTile
                            setPopupRiverName={setPopupRiverName}
                            setPopupLatlng={setPopupLatlng}
                            setIsPopup={setIsPopup}
                        />
                    }

                    <Marker position={latlng} />
                    {
                        isPopup &&
                        <Popup
                            position={popupLatlng}
                            closeButton={false}
                            minWidth={5}>
                            <PopupText>{popupRiverName}</PopupText>
                        </Popup>
                    }
                </StyleMapContainer>
                <MapTimeSlider
                    chooseNum={chooseNum}
                    updateChooseNum={updateChooseNum}
                    dateArray={dateArray}
                    newestDate={newestDate}
                    sliderMax={dateArray.length - 1}
                    startDateTime={new Date(dateArray[0])}
                    is_forecast={isForecast}
                />
            </MapWrapper>
        </StyleFullScreen>
    )
}
