import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'

import { Slider, Handles, Tracks, Ticks } from 'react-compound-slider'
import {interval} from 'rxjs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import FastForwardIcon from '@mui/icons-material/FastForward'

// Atom component
import Handle from '../../atoms/handle'
import Track from '../../atoms/track'
import TickWithText from '../../atoms/tickwithtext'
import BtnIcon from '../../atoms/btnicon'
import DateTitle from '../../atoms/datetitle'

LongSliders.propTypes = {
    // 初期選択日付のインデックス
    chooseFirstNum: PropTypes.number,
    // 選択している日付
    chooseDate: PropTypes.string,
    // 自動再生時の間隔時間
    intervals: PropTypes.number,
    // 日付行列
    dateArray: PropTypes.array,
    // 時間行列
    timeArray: PropTypes.array,
    // 変更時にデータをフィルターするキーになるリスト
    keyArray: PropTypes.array,
    // スライダー変更時に選択中の日付を変更する
    updateChooseDate: PropTypes.func,
}

// スライダーのデザイン設定
const StyleSliders = styled(Slider)`
    position: relative;
    width: calc(100% - 2rem);
    z-index: 10000;
    margin-right: 1rem;
    margin-left: 1rem;
    top: 20px;
    height: 55px
`
// スライダーのレールスタイル設定
const StyleRail = styled.div`
    position: absolute;
    width: 100%;
    height: 10px;
    margin-top: 0.85rem;
    border-radius: 5px;
    background-color: #2D4573;
`
// スライダーラッパー設定
const SliderWrapper = styled.div`
    margin: 0 5vw;
    width: 90vw;
    height: 120px;
`

// 日付・アイコンラッパー設定
const HeadWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 5vw 0 calc(5vw - 40px - 0.25rem);
`

// 日付・アイコン・スライダーラッパー設定
const ItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

// 日付ラッパー設定
const DateWrapper = styled.div`
    margin: 0 2rem;
`

// ボタンアイコン設定
const shareBtnStyle = css`
    color: white;
    width: 2.5rem;
    height: 2.5rem;
`
const LeftBtn = styled(ChevronLeftIcon)`
    ${shareBtnStyle}
`
const RightBtn = styled(ChevronRightIcon)`
    ${shareBtnStyle}
`
const PlayBtn = styled(PlayArrowIcon)`
    ${shareBtnStyle}
`
const StopBtn = styled(StopIcon)`
    ${shareBtnStyle}
`
const SkipLeftBtn = styled(FastRewindIcon)`
    ${shareBtnStyle}
`
const SkipRightBtn = styled(FastForwardIcon)`
    ${shareBtnStyle}
`

/**
 * 大きなスライダー
 * @param {number} chooseFirstNum 初期選択したインデックス値
 * @param {string} chooseDate 選択日付
 * @param {number} intervals 自動再生時の間隔時間
 * @param {array} dateArray 日付行列
 * @param {array} timeArray 時間行列
 * @param {array} keyArray 変更時にデータをフィルターにするキーリスト
 * @param {func} updateChooseDate 日付を変更する関数
 * @returns {JSX.Element}
 */
export default function LongSliders(props){
  const {chooseFirstNum, chooseDate, intervals, dateArray, timeArray, keyArray, updateChooseDate} = props
  const length = dateArray.length - 1
  const domains = [0, length]

  // 現在選択している位置
  const [chooseNum, setChooseNum] = useState(chooseFirstNum)
  // 端に来た時ボタンを消す
  const [isDisabledLeftBtn, setIsDisabledLeftBtn] = useState(
    false
  );
  const [isDisabledRightBtn, setIsDisabledRightBtn] = useState(
    false
  );
  // 現在再生しているか
  const [isPlay, setIsPlay] = useState(false)

  // 再生中の挙動
  useEffect(() => {
		if(isPlay){
	    const timeoutId = interval(intervals).subscribe(() => {
	      if(chooseNum === length) setChooseNum(0)
				else setChooseNum(chooseNum + 1)
	    })
	    return () => {
	      timeoutId.unsubscribe()
	    }
		}
  }, [isPlay, chooseNum])

  // スライダーの位置が動いたときの挙動
  useEffect(() => {
    updateChooseDate(keyArray[chooseNum])
    // 左右ボタンが端に行っていた場合、誤操作を防ぐために
    if(isDisabledLeftBtn && (chooseNum !== 0)) setIsDisabledLeftBtn(false)
    else if(!isDisabledLeftBtn && (chooseNum === 0)) setIsDisabledLeftBtn(true)
    if(isDisabledRightBtn && (chooseNum !== length)) setIsDisabledRightBtn(false)
    else if(!isDisabledRightBtn && (chooseNum === length)) setIsDisabledRightBtn(true)
  }, [chooseNum, keyArray])

  // ボタンクリック時の挙動
  const handleChange = e => {
		if(e[0] !== chooseNum) setChooseNum(e[0])
	}
	const rightClick = e => {
		if(chooseNum !== (length - 1)) setChooseNum(chooseNum + 1)
    else{
      setChooseNum(length)
      setIsDisabledRightBtn(true)
    }
	}
	const leftClick = e => {
		if((chooseNum - 1) !== 0) setChooseNum(chooseNum - 1)
    else{
      setChooseNum(0)
      setIsDisabledLeftBtn(true)
    }
	}
	const playButton = e => {
		setIsPlay(!isPlay)
	}
  const SkipLeftBtnClick = e => {
    setChooseNum(0)
  }
  const SkipRightBtnClick = e => {
    setChooseNum(length)
  }

  return(
    <ItemsWrapper>
      <HeadWrapper>
        <BtnIcon
          is_btn={ true }
          label_text={isPlay ? 'Stop' : 'Play'}
          onClickFunc={playButton}
          IconComponent={ isPlay ? <StopBtn /> : <PlayBtn />}
        />
        <BtnIcon
          is_btn={ !isDisabledLeftBtn && !isPlay }
          label_text={'Skip first date'}
          onClickFunc={SkipLeftBtnClick}
          IconComponent={<SkipLeftBtn />}
        />
        <BtnIcon
          is_btn={ !isDisabledLeftBtn && !isPlay }
          label_text={'Before step'}
          onClickFunc={leftClick}
          IconComponent={<LeftBtn />}
        />
        <DateTitle
          color={'white'}
          fontWeight={'bold'}
          fontSize={'36px'}
        >
          <DateWrapper>
            {dateArray[chooseNum]} {timeArray[chooseNum]} {chooseNum > chooseFirstNum ? '予報' : '実測'}
          </DateWrapper>
        </DateTitle>
        <BtnIcon
          is_btn={ !isDisabledRightBtn && !isPlay }
          label_text={'next step'}
          onClickFunc={rightClick}
          IconComponent={<RightBtn />}
        />
        <BtnIcon
          is_btn={ !isDisabledRightBtn && !isPlay }
          label_text={'Skip end date'}
          onClickFunc={SkipRightBtnClick}
          IconComponent={<SkipRightBtn />}
        />
      </HeadWrapper>
      <SliderWrapper>
        <StyleSliders
          domain={domains}
          step={1}
          mode={2}
          values={[chooseNum]}
          onChange={handleChange}
        >
          <StyleRail />
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className='slider-handler'>
                { handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domains}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div className='slider-tracker'>
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks count={length}>
            {({ ticks }) => (
              <div className='slider-ticks'>
                {ticks.map((tick, i) => (
                  <TickWithText
                    key={tick.id}
                    tick={tick}
                    count={ticks.length}
                    time={timeArray[tick.value]}
                    date={dateArray[tick.value]}
                    index={i + 1}
                    isForecast={i > chooseFirstNum}
                    chooseFirstNum={chooseFirstNum}
                  />
                ))}
              </div>
            )}
          </Ticks>
        </StyleSliders>
      </SliderWrapper>
    </ItemsWrapper>
  )
}

