import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// user defined function
import { getCardColor, get_colorclass_from_class } from '../../../func/getcolor'

BorderBand.propTypes = {
  // メーターの基準を表すリスト
  borderArray: PropTypes.array,
  // メーター単位
  borderUnit : PropTypes.string,
  // メーターのラベル
  borderLabel : PropTypes.array
}

/**
 * 水位の基準を表す
 * @param {array} borderArray メーターの基準を表すリスト
 * @param {string} borderUnit 水位の単位
 * @param {array} borderLabel 水位のラベル名リスト
 * @returns {JSX.Element}
 */
export default function BorderBand(props){
  const { borderArray, borderUnit, borderLabel } = props

  if(borderArray.every((elem) => !elem)){
    return(
      <Wrapper>
        <Elem colorclass={'bg-b1'}>基準なし</Elem>
      </Wrapper>
    )
  }
  else{
    return(
      <Wrapper>
        <Elem colorclass={'bg-lsub'}>平常</Elem>
        {
          borderArray.map((elem, i) => (
            !borderArray[i] ? <React.Fragment />
            : (
                <Elem key={elem} colorclass={get_colorclass_from_class(i + 3)}>
                  {borderLabel[i]}<span>{elem}{borderUnit}</span>
                </Elem>
            )
          ))
        }
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  margin-top: auto;
`

const Elem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0.25rem 0;
  background-color: ${(props) => getCardColor(props.colorclass)};
`
