import React from 'react'
import styled, {keyframes} from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { Link } from 'react-router-dom'
import { config } from '../../config.jsx'

import ErrorMsg from '../../atoms/errormsg/index.jsx'
import { dateToYYYYMMDD } from '../../func/datetoview.jsx'

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * アーカイブした災害の一覧
 * @returns {JSX.Element}
 */
export default function ArchiveDisasterList(){
    // アーカイブリストを取得する
    const {data, error} = useSWR(
        () => `${config.restUrl}/arc/disaster/list/`,
        fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, {retryCount }) => {
                // 再試行は5回まで
                if(retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
            },
            refreshInterval: 300000
        }
    )

    const isLoading = !data && !error
    // Loading
    if(isLoading){
        return(
            <Wrapper>
                <TitleWrapper>アーカイブされた災害一覧</TitleWrapper>
                {[...Array(5)].map((e, i) =>
                    <DisasterWrapper key={`disaster-list-wrapper-${i}`}>
                        <DisasterHead key={`disaster-list-head-${i}`}>
                            <DisasterIcons key={`disaster-list-icon-${i}`} />
                            <DisasterPrefName key={`disaster-list-pref-name-${i}`}>都道府県名</DisasterPrefName>
                        </DisasterHead>
                        <LoadingDisasterTitle key={`disaster-list-title-${i}`}>読み込み中...</LoadingDisasterTitle>
                        <DisasterDate key={`disaster-list-period-${i}`}>期間：</DisasterDate>
                        <DisasterDate key={`disaster-list-update-date-${i}`}>最終更新日：</DisasterDate>
                    </DisasterWrapper>
                )}
            </Wrapper>
        )
    }
    else if(error){
        return(
            <Wrapper>
                <TitleWrapper>アーカイブされた災害一覧</TitleWrapper>
                <ErrorMsg msg={'データがありませんでした'} />
            </Wrapper>
        )
    }

    // 表示するアーカイブのフィルタ
    const viewData = data.results

    if(viewData.length === 0){
        return(
            <Wrapper>
                <TitleWrapper>アーカイブされた災害一覧</TitleWrapper>
                <ErrorMsg msg={'データがありませんでした'} />
            </Wrapper>
        )
    }

    return(
        <Wrapper>
            <TitleWrapper>アーカイブされた災害一覧</TitleWrapper>
            {viewData.map((e, i) =>
                <DisasterWrapper key={`disaster-list-wrapper-${i}`}>
                    <DisasterHead key={`disaster-list-head-${i}`}>
                        <DisasterIcons key={`disaster-list-icon-${i}`} />
                        <DisasterPrefName key={`disaster-list-pref-name-${i}`}>{e.prf_name}</DisasterPrefName>
                    </DisasterHead>
                    <Link to={`/archive/${e.disaster_id}/`}>
                        <DisasterTitle key={`disaster-list-title-${i}`}>{e.disaster_name}</DisasterTitle>
                    </Link>
                    <DisasterDate key={`disaster-list-period-${i}`}>期間：{dateToYYYYMMDD(e.disaster_start_date)} ~ {dateToYYYYMMDD(e.disaster_end_date)}</DisasterDate>
                    <DisasterDate key={`disaster-list-update-date-${i}`}>最終更新日：{dateToYYYYMMDD(e.update_date)}</DisasterDate>
                </DisasterWrapper>
                )}
        </Wrapper>
    )
}

const TitleWrapper = styled.div`
    font-weight: bold;
    font-size: 36px;
    color: white;
    margin: 1rem 0;
`

// 災害一覧ラッパー ///////////////////////////////////////////////////
const LoadingAnimation = keyframes`
    to {
        clip-path: inset(0 -0.5ch 0 0)
    }
`
const Wrapper = styled.div`
    margin: 5vh 20vw;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`
const DisasterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    &&& a{
        text-decoration: none;
    }
`
const DisasterHead = styled.div`
    color: white;
    width: 100%;
    display: flex;
    justify-contents: center;
`
const DisasterIcons = styled.div`
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #15335f;
`
const DisasterPrefName = styled.div`
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
`
const DisasterTitle = styled.div`
    color: white;
    font-weight: bold;
    font-size: 24px;
    text-align: left;
    margin: 0.1rem 0;
    width: fit-content;
    &:hover{
        text-decoration: underline;
    }
`
const LoadingDisasterTitle = styled(DisasterTitle)`
    display: inline-block;
    clip-path: inset(0 1ch 0 0);
    animation: ${LoadingAnimation} 1s steps(5) infinite;
`
const DisasterDate = styled.div`
    color: white;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
`
