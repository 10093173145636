import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../../config.jsx'

// Item Component
import AreaGraph from '../../items/areagraph'
import MtrInfo from '../../items/mtrinfo'
import IconButton from '@mui/material/IconButton'
import Clear from '@mui/icons-material/Clear'
import Drawer from '@mui/material/Drawer'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// user defined function
import { getTickList } from '../../../func/getticklist'
import { getGraphLine } from '../../../func/getgraphline'

RiverGraph.propTypes = {
    // 選択中の水位計コード
    mtrCode: PropTypes.number,
    // 選択中の水位計の種類識別子
    mtrCategory: PropTypes.string,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント時間
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
}

const fetcher = url => axios.get(url).then(res => res.data)

const GraphWrapper = styled.div`
    height: 75svh;
    background-color: #152451;
    border-radius: 3px 3px 0 0;
    overflow-y: hidden;
`

/**
 * 河川水位グラフ
 * @param {number} mtrCode 水位計コード
 * @param {string} mtrCategory 水位計の種類識別名
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か 
 * @returns {JSX.Element}
 */
export default function RiverGraph(props){
    const {
        mtrCode,
        mtrCategory,
        isTelmapGraphDrawerOpen,
        displayNavigationDrawer
    } = props

    const { data, error } = useSWR(
        () => `${config.restUrl}/wtl/${mtrCategory}/graph/${mtrCode}/`,
            fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は2回まで
                if(retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
            },
            refreshInterval: 300000
        }
    )

    // ロード，エラー時の設定
    const isLoading = !data && !error
    const isData = !isLoading && !error

    if(isLoading){
        return(<React.Fragment />)
    }
    if(error){
        return(<ErrorMsg text={'データが見つかりませんでした'} />)
    }

    // グラフの設定
    const mtrBorder = [
        data.green_border,
        data.yellow_border,
        data.red_border,
        data.purple_border,
        data.max_border
    ]
    const mtrLabel = [
        '水防団待機',
        '氾濫注意',
        '避難判断',
        '氾濫危険',
        '計画高'
    ]

    const results = data.results
    // ドメインとグラフのライン設定
    const domains = getTickList(results, mtrBorder, null, true)
    const graphLines = getGraphLine(mtrBorder, mtrLabel, 'tel')

    return(
        <Drawer anchor="bottom" open={isTelmapGraphDrawerOpen} sx={{
            '& .MuiDrawer-paper': {
                backgroundColor: 'transparent',
                color: '#fff',
                overflow: 'visible'
            }
        }}>
            <IconButton
                sx={{
                position: 'absolute',
                right: 0,
                top: '-40px',
                color: '#fff'
                }}
                onClick={() => displayNavigationDrawer('map')}
            ><Clear /></IconButton>
            <GraphWrapper>
                <MtrInfo
                    mtrName={data.name}
                    basName={`${data.river_system_name}水系`}
                    riverName={data.river_name}
                    mtrDetail={data.place_info}
                    mtrLat={data.latitude}
                    mtrLng={data.longitude}
                    mtrAddress={data.address}
                    mtrDiffFlag={data.diff_flag}
                    mtrValue={data.value}
                    colorclass={data.colorclass}
                    mtrBorder={mtrBorder}
                    mtrUnit={'m'}
                    mtrLabel={mtrLabel}
                    isEmptyMsg={'欠測'}
                />
                <AreaGraph
                    data={results}
                    domains={domains}
                    unit={'m'}
                    mejer={'mejer'}
                    xaxisKeyList={['date']}
                    dataKey={'value'}
                    lines={graphLines}
                    lineWidth={4}
                    category={mtrCategory}
                    borderArray={mtrBorder}
                />
            </GraphWrapper>
        </Drawer>
    )
}
