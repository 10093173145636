import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Contents components
import EvacList from '../evaclist'

// Atom Components
import EvacSvg from '../../atoms/evacsvg'
import ErrorMsg from '../../atoms/errormsg'

// original function
import { uni2str } from '../../func/getstring'

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 避難所マップ
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @returns 
 */
export default function EvacMap({disasterId='', checkpoint='', isArchive=false}){
    // 選択している市町村コード
    const [ctvCode, setCtvCode] = useState(null)
    // 選択している市町村名
    const [ctvName, setCtvName] = useState('')

    const { data, error } = useSWR(
        () =>
            isArchive ? `${config.restUrl}/${disasterId}/${checkpoint}/pref/evac/ctv/44/`
            : `${config.restUrl}/pref/evac/ctv/44/`,
            fetcher,
        {
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
            // 再試行は5回まで
            if(retryCount >= 2) return
            // 5秒後に再試行
            setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
        },
        refreshInterval: 300000
        }
    )
    function clickEvent(props, array){
        const clickElemId = props.target.parentNode
        const clickCtvCode = clickElemId.getAttribute('id').split('-')[1]/100

        // 他のSVGをクリックしたときの誤動作を防ぐため，
        // 避難情報がないときはクリックを動作させない
        if(array.filter(elem => elem.ctv_code === clickCtvCode).length !== 0){
            setCtvName(uni2str(clickElemId.getAttribute('aria-hidden')))
            setCtvCode(clickCtvCode)
        }
    }

    const isLoading = !data && !error
    
    if(isLoading){
        return(
            <Wrapper />
        )
    }

    if(error){
        return(
            <Wrapper>
                <ContentsWrapper>
                    <EvacSvg
                        evacArray={[]}
                        clickEvent={clickEvent}
                    />
                    <ErrorWrapper>
                        <ErrorMsg msg={'大分県内に発令されている避難情報はありません'} />
                    </ErrorWrapper>
                </ContentsWrapper>
            </Wrapper>
        )
    }
    if (ctvCode === null){
        setCtvCode(data.results[0].ctv_code)
        setCtvName(data.results[0].city_name)
    }
    return(
        <Wrapper>
            <ContentsWrapper>
                <EvacSvg
                    evacArray={data.results}
                    clickEvent={clickEvent}
                />
                <ListWrapper>
                    <EvacList
                        ctvCode={ctvCode}
                        ctvName={ctvName}
                        disasterId={disasterId}
                        checkpoint={checkpoint}
                        isArchive={isArchive}
                    />
                </ListWrapper>
            </ContentsWrapper>
        </Wrapper>
    )

}
const TitleWrapper = styled.div`
    font-weight: bold;
    font-size: 36px;
    color: white;
    margin: 1rem 0;
`
// エラーラッパー
const ErrorWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    width: 50%;
    height: auto;
`
// 避難情報一覧ラッパー
const ListWrapper = styled.div`
    display: flex;
    margin: 2rem 0 2rem 2rem;
    width: 50%;
    height: fit-content;
    max-height: calc(100% - 4rem);
`
const Wrapper = styled.div`
    margin: 5vh 5vw;
    flex-direction: column;
    height: 100%;
    display: flex;
`
const ContentsWrapper = styled.div`
    display: flex;
    height: 100%;
`
