import { Chip } from '@mui/material';
import styled from '@emotion/styled';

// 共通のチップのスタイル
// 角の丸さ指定
export const RadiusChipBase = styled(Chip)(() => ({
  borderRadius: 2,
  //overflow: 'hidden',
  textOverflow: 'ellipsis',
  //height:'22px'
}));



// 通常サイズのタグチップ
export const NomalChip = styled(RadiusChipBase)(() => ({
  backgroundColor: 'rgb(110, 110, 111)',
  color: 'white',
  fontSize: '1rem',
  margin: '0px 8px 5px 0px',
  height: '30px',
  '&:hover': {
    cursor: 'pointer'
  }
}));

export const HoverChip = styled(NomalChip)(() => ({

  '&:hover': {
    backgroundColor: '#acaebe',
    cursor: 'pointer'
  }
}));
