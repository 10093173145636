import { Box, Stack } from '@mui/material';
import { styled } from "@mui/system";

// 横並び
// アイテムを均等配置
// 上下中央揃え
export const BoxSpaceAround = styled(Box)(() => (
    {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    }));

export const BoxSpaceAroundMarginLR10 = styled(BoxSpaceAround)(() => (
    {
        margin: '0 10px'
    }));

// 横並び
// 左端にアイテムを寄せる
// 上下中央揃え
export const BoxFlexStart = styled(Box)(() => (
    {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    }));
export const BoxFlexStartMarginR = styled(BoxFlexStart)(() => (
    {
        margin: '0 15px 0 0'
    }));

// 横並び
// 右端にアイテムを寄せる
// 上下中央揃え
export const BoxFlexEnd = styled(Box)(() => (
    {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }));
export const BoxFlexEndMarginLR10 = styled(BoxFlexEnd)(() => (
    {
        margin: '0 10px'
    }));

export const StackMargin10 = styled(Stack)(() => (
    {
        margin: '10px'
    }));

export const StackTB5LR20 = styled(Stack)(() => (
    {
        margin: '5vh 20vw'
    }));


