import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import Top from '../../contents/top'

/**
 * トップ画面テンプレート
 * @returns {JSX.Element}
 */
export default function TopPage(){
    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const {disaster_id, checkpoint} = useParams()
    const {simulation_id} = useParams()
    if(simulation_id && checkpoint){
        return(
            <React.Fragment>
                <RiskHeader simulationId={simulation_id} checkpoint={checkpoint} isSimulation={true} />
                <ContentsWrapper>
                    <LeftContents>
                        <Top simulationId={simulation_id} checkpoint={checkpoint} isSimulation={true} />
                    </LeftContents>
                    <RightContents />
                </ContentsWrapper>
            </React.Fragment>
        )
    }
    if(disaster_id && checkpoint){
        return(
            <React.Fragment>
                <RiskHeader disasterId={disaster_id} checkpoint={checkpoint} isArchive={true} />
                <ContentsWrapper>
                    <LeftContents>
                        <Top disasterId={disaster_id} checkpoint={checkpoint} isArchive={true} />
                    </LeftContents>
                    <RightContents />
                </ContentsWrapper>
            </React.Fragment>
        )
    }
    return(
        <React.Fragment>
            <RiskHeader />
            <ContentsWrapper>
                <LeftContents>
                    <Top />
                </LeftContents>
                <RightContents>
                </RightContents>
            </ContentsWrapper>
        </React.Fragment>
    )
}
const ContentsWrapper = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const LeftContents = styled.div`
    width: 100vw;
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const RightContents = styled.div`
    width: 0vw;
`
