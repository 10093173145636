export const config = {
  // apiUrl: 'http://localhost:3000',
  // restUrl: 'https://risk-edison.com/rest',
  // tileUrl: 'https://risk-edison.com/tile',
  // restUrl: 'https://r2.risk-edison.com/rest',
  // tileUrl: 'https://r2.risk-edison.com/tile',
  // restUrl: 'http://localhost:8080',
  tileUrl: 'http://localhost:8088',

  // // テスト用ドメイン
  // apiUrl: 'https://dgerv4m0q1jrp.cloudfront.net',
  // restUrl: 'https://dgerv4m0q1jrp.cloudfront.net/api/v1',
  // pmtilesUrl: 'https://dyx5seq8t4xlb.cloudfront.net',

  //// 本番用ドメイン
  apiUrl: 'https://d32iphgb1titni.cloudfront.net',
  restUrl: 'https://d32iphgb1titni.cloudfront.net/api/v1',
  pmtilesUrl: 'https://dyx5seq8t4xlb.cloudfront.net',

  accessibleId: [1, 2],
};
