import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import SimulationList from '../../contents/simulationlist'
import CheckpointList from '../../contents/checkpointlist'
import { TypographyTitleCenter } from '../../style/typography'
import { BoxFlexEnd, StackTB5LR20 } from '../../style/box'
import { SubmitButton } from '../../style/button'

/**
 * シミュレーションテンプレート
 * @returns {JSX.Element}
 */
export default function SimulationPage() {

    const navigate = useNavigate();

    // シミュレーションIDと都道府県コードのパラメータを受け取る
    const { simulation_id } = useParams()
    // パラメータがある場合は，チェックポイントリスト画面に遷移する
    if (simulation_id) {
        return (
            <CheckpointList simulationId={simulation_id} />
        )
    }
    return (
        <StackTB5LR20 spacing={1}>
            <TypographyTitleCenter>
                シミュレーション一覧
            </TypographyTitleCenter>
            
            {/* 未実装のためコメントアウト 2024/03
            <BoxFlexEnd>
                <SubmitButton
                    onClick={() => navigate('/simulation/create')}>
                    新規シュミレーション作成
                </SubmitButton>
            </BoxFlexEnd> */}
            <SimulationList />
        </StackTB5LR20>
    )
}

