import axios from 'axios'
import useSWR, { mutate } from 'swr'
import { config } from '../config'

const fetcher = rest_url => axios.get(rest_url).then(res => res.data)

export const GetData = (url) => {
  const rest_url =config.restUrl + url
  const { data, error } = useSWR(rest_url, fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        console.log(error)
        console.log(key)
        console.log(config)
        if(error.request.status===404){
          // 対象データがない場合はリトライしない
          return
        }
        // 再試行は5回まで
        if (retryCount >= 2) return
        // 5秒後に再試行
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 50000)
      },
      refreshInterval: 300000
    }
  )
  return {
    data: data,
    loading: !error && !data,
    error: error
  }
}

export const LoadMutate = (url) => {
  mutate(url)
}
