import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { HeaderBtnBox, ChoosedHeaderBtnBox } from '../../style/header'

LinkBtn.propTypes = {
  // ボタンに表示する文字
  text: PropTypes.string,
  // リンク先のURL
  url: PropTypes.string,
  // 選択中の場合は選択済みにする
  isChoosed: PropTypes.bool,
  // 選択中の背景色
  choosedBackColod: PropTypes.string,
}


/**
 * クリックしたら遷移を伴うボタン
 * @param {string} text ボタンに表示する文字
 * @param {string} url 遷移先URL
 * @param {bool} isChoosed 選択中か否か
 * @param {string} choosedBackColod 選択時の背景色 
 * @returns 
 */
export default function LinkBtn(props) {
  const { text, url, isChoosed, choosedBackColod } = props

  return (
    <>
      {isChoosed ?
        <ChoosedHeaderBtnBox >
          <Link to={url}>{text}</Link>
        </ChoosedHeaderBtnBox>
        :
        <HeaderBtnBox >
          <Link to={url}>{text}</Link>
        </HeaderBtnBox>

      }
    </>
  )
}
