import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GetData } from '../../func/get_data_swr';

// Atom Commponent
import SwitchBtn from '../../atoms/switchbtn'
import ErrorMsg from '../../atoms/errormsg'

CtvBtn.propTypes = {
    // 市町村コード
    ctvCode : PropTypes.number,
    // 市町村情報を更新する
    updateCtvStatus : PropTypes.func,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
}

/**
 * 市町村選択ボタン
 * @param {number} ctvCode 市町村コード
 * @param {func}  updateCtvStatus 市町村情報を更新する
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント
 * @param {bool} isArchive アーカイブか否か
 * @returns {JSX.Element}
 */
export default function CtvBtn(props){
    const { ctvCode, updateCtvStatus, disasterId='', checkpoint='', isArchive } = props

    // アーカイブか否かで呼び出すAPIを分ける
    let url = `/pref/city/list/`
    if (isArchive) {
        url = `/${disasterId}/${checkpoint}/pref/city/list/`
    }
    const { data, loading, error} = GetData(url);

    // Loading
    if(loading){
        return(<React.Fragment />)
    }

    if(error){
        // 開設中の避難所がない場合や，通信できない場合は
        // 市町村コードを0に，市町村名をNotFoundとする
        updateCtvStatus(0, 'NotFound')
        return(<ErrorMsg msg={'現在，開設中の避難所はありません'} />
        )
    }

    const onClickBtn = (props) => {
        updateCtvStatus(props[0], props[1])
    }
    console.log(data.result)

    const firstPickData = data.result[0]
    if((ctvCode === null || ctvCode === 0) && firstPickData){
        updateCtvStatus(firstPickData.ctv_code, firstPickData.ctv_name)
    }

    return(
        <Wrapper>
            {
                data.result.map((elem, i) => (
                    <SwitchBtn
                        key={elem.ctv_code}
                        text={elem.ctv_name}
                        isChoosed={elem.ctv_code === ctvCode}
                        updateChooseData={onClickBtn}
                        propArray={[elem.ctv_code, elem.ctv_name]}
                    />
                ))
            }
        </Wrapper>
    )

}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 5vw;
`
