import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    Label
} from 'recharts'

// Atomic Component
import GraphToolTip from '../../atoms/graphtooltip'
// original function
import { dateToMMDDHHmmGraph } from '../../../func/datetoview'

BarGraph.propTypes = {
    // 表示するデータの種類を示す
    category: PropTypes.string,
    // グラフで表示するデータ，REST APIのresultsの中身を持ってくれば良い
    data: PropTypes.array,
    // グラフの上限と下限
    domains: PropTypes.array,
    // グラフ上の単位 空の場合あり
    unit: PropTypes.string,
    // グラフのx軸のキーとなるリスト
    xaxisKeyList: PropTypes.array,
    // データのキー，ここではvalue
    dataKey: PropTypes.string,
    // グラフに引く線の指定，値と線の色が入っている
    lines: PropTypes.array,
    // グラフに引く線の太さ，デフォルトは3
    lineWidth: PropTypes.number,
}

const axisStyles = {
    fontSize: '12px',
    fill: 'white'
}

const GraphWrapper = styled.div`
    display: frex;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    padding: 0.5rem;
    background-color: #17295a;
    border-radius: 0 0 5px 5px;
`

/**
 * 棒グラフ
 * @param {string} category データの種類名
 * @param {array} data グラフ表示用データ
 * @param {array} domain グラフの上限と下限
 * @param {string} unit グラフで表示するデータの単位
 * @param {array} xaxisKeyList グラフのx軸のキーとなるリスト
 * @param {string} dataKey データの表示(y軸するキー名
 * @param {array} lines グラフに引く線の場所と色
 * @param {number} lineWidth グラフに引く線の太さ
 * @returns {JSX.Element}
 */
export default function BarGraph(props){
    const {
        category,
        data,
        domains,
        unit,
        xaxisKeyList,
        dataKey,
        lines,
        lineWidth
    } = props

    return(
        <GraphWrapper>
            <ResponsiveContainer width='100%'>
                <BarChart
                    data={data}
                    margin={{
                        // top: 5, right: 100, left: 5, bottom: 5,
                        top: 10, right: 75, left: 0, bottom: 0,
                    }}
                >
                <CartesianGrid strokeDasharray='4 4' />
                {
                    xaxisKeyList.map((elem, i) =>
                        <XAxis
                            key={elem}
                            xAxisId={i}
                            dataKey={xaxisKeyList[i]}
                            allowDuplicatedCategory={i===0}
                            tick={axisStyles}
                            tickFormatter={(dates) => dateToMMDDHHmmGraph(dates)}
                        />
                )}
                <YAxis
                    dataKey={dataKey}
                    unit={unit}
                    domain={domains}
                    interval={0}
                    axisLine={true}
                    tick={false}
                />
                <Tooltip
                    content={
                        <GraphToolTip
                            unit={unit}
                            category={category}
                        />
                    }
                    cursor={{ fill: '#223C6D', opacity: 0.7}}
                />
                <Bar
                    barSize={20}
                    dataKey={dataKey}
                    stroke="#318AB7"
                    fill="#318AB7"
                    fillOpacity={0.9}
                    unit={unit}
                />
                {
                    lines.map((elem, i) =>
                        <ReferenceLine
                            key={elem.label}
                            y={lines[i].target}
                            stroke={lines[i].color}
                            strokeWidth={lineWidth ? lineWidth : 4}
                        >
                        <Label
                            value={elem.label}
                            position={elem.styles ? elem.styles : 'left'}
                            style={axisStyles}
                        />
                        </ReferenceLine>
                    )
                }
                </BarChart>
            </ResponsiveContainer>
        </GraphWrapper>
    )

}
