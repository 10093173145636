import React, { useState } from 'react'

// Contents component
import MsList from '../mslist'
import MeterSlider from '../meterslider'

/**
 * 水位スライダーを構成するコンポーネント
 * @param {number} bsnCode 水系コード
 * @param {string} bsnName 水系名
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @returns {JSX.Element}
 */
export default function MsSliderView(props){
    const { bsnCode, bsnName, disasterId='', checkpoint='', isArchive=false } = props

    // スライダーでどの時間を選択しているか
    const [chooseDate, setChooseDate] = useState('')

    // スライダーを変更時, 時間を更新
    function updateChooseData(props){
        setChooseDate(props)
    }
    return(
        <React.Fragment>
            <MeterSlider
                bsnCode={bsnCode}
                updateChooseDate={updateChooseData}
                disasterId={disasterId}
                checkpoint={checkpoint}
                isArchive={isArchive}
            />
            <MsList
                bsnCode={bsnCode}
                bsnName={bsnName}
                chooseDate={chooseDate}
                disasterId={disasterId}
                checkpoint={checkpoint}
                isArchive={isArchive}
            />
        </React.Fragment>
    )
}
