import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import BsnBtn from '../../contents/bsnbtn'
import MsSliderView from '../../contents/mssliderview'

MsDashboard.propTypes = {
    // 水系コード
    bsnCode: PropTypes.number,
    // 水系名
    bsnName: PropTypes.string,
    // 水系情報を更新する
    updateBsnStatus: PropTypes.func,
}

const AppWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: calc(100vh - 48px);
    flex-direction: column;
`

/**
 * 河川水位スライダーテンプレート
 * @param {number} bsnCode 水系コード
 * @param {string} bsnName 水系名
 * @param {func} updateBsnStatus 水系情報を更新する関数
 * @returns {JSX.Element}
 */
export default function MsDashboard(props){
    const { bsnCode, bsnName, updateBsnStatus } = props
    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const {disaster_id, checkpoint} = useParams()

    if(disaster_id && checkpoint){
        return(
            <React.Fragment>
                <RiskHeader 
                    disasterId={disaster_id}
                    checkpoint={checkpoint}
                    isArchive={true}
                />
                <AppWrapper>
                    <BsnBtn
                        bsnCode={bsnCode}
                        updateBsnStatus={updateBsnStatus}
                    />
                    <MsSliderView
                        bsnCode={bsnCode}
                        bsnName={bsnName}
                        disasterId={disaster_id}
                        checkpoint={checkpoint}
                        isArchive={true}
                    />
                </AppWrapper>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <RiskHeader />
            <AppWrapper>
                <BsnBtn
                    bsnCode={bsnCode}
                    updateBsnStatus={updateBsnStatus}
                />
                <MsSliderView
                    bsnCode={bsnCode}
                    bsnName={bsnName}
                />
            </AppWrapper>
        </React.Fragment>
    )
}
