import React from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import SimulationPage from '../../templates/simulationdashboard'
import RiskHeader from '../../contents/riskheader'

/**
 * シミュレーション画面
 * @returns {JSX.Element} シミュレーションテンプレート
 */
export default function Simulation() {
    return (
        <React.Fragment>
            <RiskHeader />
            <Container maxWidth='100%'>
                <Box sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 'calc(100vh - 48px)',
                    overflow: "hidden",
                    overflowY: "scroll",
                }} >
                    <SimulationPage />
                </Box>
            </Container>
        </React.Fragment>
    )
}
