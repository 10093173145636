import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import ShelterMapView from '../../contents/sheltermapview'

const AppWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: calc(100vh - 48px);
    flex-direction: column;
`

const ContentsWrapper = styled.div`
    display: flex;
    padding: 0 0 1rem 0;
    overflow-y: hidden;
    height: 100%;
`

/**
 * 避難所マップテンプレート
 * @returns {JSX.Element}
 */
export default function ShelMapDashboard(){
    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const {disaster_id, checkpoint} = useParams()

    if(disaster_id && checkpoint){
        return(
            <React.Fragment>
                <RiskHeader 
                    disasterId={disaster_id}
                    checkpoint={checkpoint}
                    isArchive={true}
                />
                <AppWrapper>
                    <ContentsWrapper>
                        <ShelterMapView 
                            disasterId={disaster_id}
                            checkpoint={checkpoint}
                            isArchive={true}
                        />
                    </ContentsWrapper>
                </AppWrapper>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <RiskHeader />
            <AppWrapper>
                <ContentsWrapper>
                    <ShelterMapView />
                </ContentsWrapper>
            </AppWrapper>
        </React.Fragment>
    )
}
