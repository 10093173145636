import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// Item Component
import Meter from '../../items/meter'
import LoadingMeter from '../../items/loadingmeter'

// function
import { get_colorclass_from_class } from '../../func/getcolor'
import { JSTToMMDDHHmm } from '../../func/datetoview'

CrsList.propTypes = {
    // 選択中の流域コード
    bsnCode: PropTypes.number,
    // 選択中の水位計コード
    mtrCode: PropTypes.number,
    // 選択中の水系名
    bsnName: PropTypes.string,
    // 選択水位計更新関数
    updateChooseMtr: PropTypes.func,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント時間
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
}

const ListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    &&& .meter{
        margin: 0.3rem;
        padding: 0.5rem;
        min-width: 180px;
        max-width: 240px;
        min-height: 82px;
    }
`
const TitleWrapper = styled.div`
    margin: 1rem 0;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    width: 100%;
`

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 危機管理水位系一覧
 * @param {number} bsnCode 水系コード
 * @param {number} mtrCode 水位計コード
 * @param {string} bsnName 水系名
 * @param {func} updateChooseMtr 選択中の水位計を変更する関数
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か 
 * @returns {JSX.Element}
 */
export default function CrsList(props){
    const { bsnCode, mtrCode, bsnName, updateChooseMtr, disasterId, checkpoint, isArchive } = props

    const onclickFunc = (props) => {
        updateChooseMtr(props[0], props[1])
    }

    const { data, error } = useSWR(
        () => bsnCode &&
            isArchive ? `${config.restUrl}/${disasterId}/${checkpoint}/wtl/crs/list/${bsnCode}/`
            : `${config.restUrl}/wtl/crs/list/${bsnCode}/`,
            fetcher,
            {
                onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                    // 再試行は5回まで
                    if(retryCount >= 2) return
                    // 5秒後に再試行
                    setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
                },
                refreshInterval: 300000
            }
    )

    const isLoading = !data && !error
    if(isLoading){
        return(
            <ListWrapper>
                <TitleWrapper>
                {
                    bsnName === '九州その他' ? '大分二級河川水位(パーセント)'
                    : bsnName + '水系危機管理水位(パーセント)'
                }
                </TitleWrapper>
                {
                    [...Array(15)].map((elem, i) => (
                        <LoadingMeter key={`loading-crs-${i}`} />
                    ))
                }
            </ListWrapper>
        )
    }
    if(error){
        return(
            <React.Fragment>
                <TitleWrapper>
                    {
                        bsnName === '九州その他' ? '大分二級河川水位(パーセント)'
                        : bsnName + '水系危機管理水位(パーセント)'
                    }
                </TitleWrapper>
                <ErrorMsg msg={'データが見つかりませんでした'} />
            </React.Fragment>
        )
    }

    return(
        <ListWrapper>
            <TitleWrapper>
                {
                    bsnName === '九州その他' ? '大分二級河川水位(パーセント)'
                    : bsnName + '水系危機管理水位(パーセント)'
                }
            </TitleWrapper>
            {
                data.results.map((elem, i) => (
                    <Meter
                        key={ elem.crs_code }
                        headtitle={ `${elem.crs_name} - ${elem.river_name}` }
                        subtitle={ elem.crs_date ? JSTToMMDDHHmm(elem.crs_date) : '' }
                        value={ elem.value !== null ? elem.value : '欠測' }
                        colorclass={ get_colorclass_from_class(elem.colorclass) }
                        is_btn={ mtrCode !== elem.crs_code }
                        isChoosed={ mtrCode === elem.crs_code }
                        unit={elem.colorclass === 1 ? 'm' : elem.colorclass === 0 ? '' : '%'}
                        changes={elem.diff_flag}
                        updateStateFunc={onclickFunc}
                        funcProps={[elem.crs_code, 'crs']}
                    />
                ))
            }
        </ListWrapper>
    )
}
