import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Apps from './App/App';
import configureFakeBackend from './_basicauth/_helpers/fake-backend'

configureFakeBackend()

ReactDOM.render(
  <React.StrictMode>
    <Apps />
  </React.StrictMode>,
  document.getElementById('root')
);

