import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../../config.jsx'

// Item component
import Meter from '../../items/meter'
import LoadingMeter from '../../items/loadingmeter'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Clear from '@mui/icons-material/Clear'

// Atom component
import MeterTitle from '../../atoms/metertitle'
import ErrorMsg from '../../atoms/errormsg'

// original function
import { dateToMMDDHHmm } from '../../../func/datetoview'
import { get_colorclass} from '../../../func/getcolor'

const Wrapper = styled.div`
    height: calc(75svh - 20px);
    background-color: #152451;
    overflow-y : scroll;
    color: white;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display:none;
    }
    padding: 10px;
`

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 選択した地点の雨量情報表示
 * @param {array} latlng 緯度経度
 * @param {string} chooseDate 選択されている日付
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @param {bool} isSimulation シミュレーションか否か
 * @param {string} simulationId シミュレーションID
 * @returns {JSX.Element}
 */
export default function MeshMeter(props){
    const {
        latlng,
        chooseDate,
        disasterId='',
        checkpoint='',
        isArchive=false,
        simulationId='',
        isSimulation=false,
        isMeterDrawerOpen,
        displayNavigationDrawer
    } = props
    const { data, error } = useSWR(
        () => latlng.length !== 0 &&
            isSimulation ? `${config.restUrl}/sim/${simulationId}/${checkpoint}/rain/msh/info/${latlng[0]}/${latlng[1]}/`
            : isArchive ? `${config.restUrl}/${disasterId}/${checkpoint}/rain/msh/info/${latlng[0]}/${latlng[1]}/`
            : `${config.restUrl}/rain/msh/info/${latlng[0]}/${latlng[1]}/`,
            fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は5回まで
                if(retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
            },
            refreshInterval: 300000
        }
    )
    
    const isLoading = !data && !error
    const isData = !isLoading && !error
    const viewData = isData && data.results.filter(elem => elem.date === chooseDate)[0]
    const subtitleText = viewData && dateToMMDDHHmm(viewData.date)

    // Loading
    if((isLoading && !error) || viewData === undefined){
        return(
            <Drawer anchor="bottom" open={isMeterDrawerOpen} sx={{
                '& .MuiDrawer-paper': {
                    backgroundColor: 'transparent',
                    color: '#fff',
                    overflow: 'visible'
                }
            }}>
                <IconButton
                    sx={{
                    position: 'absolute',
                    right: 0,
                    top: '-40px',
                    color: '#fff'
                    }}
                    onClick={() => displayNavigationDrawer('map')}
                ><Clear /></IconButton>
                <Wrapper>
                    <MeterTitle children={'メッシュ情報読み込み中'} />
                    {
                        [...Array(5)].map((elem, i) => (
                            <LoadingMeter key={`loading-one-${i}`} />
                        ))
                    }
                    <MeterTitle children={'河川情報読み込み中'} />
                    {
                        [...Array(4)].map((elem, i) => (
                            <LoadingMeter key={`loading-two-${i}`} />
                        ))
                    }
                    <MeterTitle children={'行政区域読み込み中'} />
                    {
                        [...Array(4)].map((elem, i) => (
                            <LoadingMeter key={`loading-three-${i}`} />
                        ))
                    }
                    <MeterTitle children={'インフラ情報読み込み中'} />
                    {
                        [...Array(2)].map((elem, i) => (
                            <LoadingMeter key={`loading-four-${i}`} />
                        ))
                    }
                </Wrapper>
            </Drawer>
        )
    }

    // error
    if(error){
        return(
            <Drawer anchor="bottom" open={isMeterDrawerOpen} sx={{
                '& .MuiDrawer-paper': {
                    backgroundColor: 'transparent',
                    color: '#fff',
                    overflow: 'visible'
                }
            }}>
                <IconButton
                    sx={{
                    position: 'absolute',
                    right: 0,
                    top: '-40px',
                    color: '#fff'
                    }}
                    onClick={() => displayNavigationDrawer('map')}
                ><Clear /></IconButton>
                <Wrapper>
                    <ErrorMsg msg={'データが見つかりませんでした'} />
                </Wrapper>
            </Drawer>
        )
    }

    return(
        <Drawer anchor="bottom" open={isMeterDrawerOpen} sx={{
            '& .MuiDrawer-paper': {
                backgroundColor: 'transparent',
                color: '#fff',
                overflow: 'visible'
            }
        }}>
            <IconButton
                sx={{
                position: 'absolute',
                right: 0,
                top: '-40px',
                color: '#fff'
                }}
                onClick={() => displayNavigationDrawer('map')}
            ><Clear /></IconButton>
            <Wrapper>
                <MeterTitle children={data.txt_address_name} />
                <Meter
                    headtitle={'1時間雨量'}
                    subtitle={subtitleText}
                    value={viewData.h1_msh}
                    colorclass={get_colorclass(viewData.h1_msh, 'h1')}
                    is_btn={false}
                    unit={'mm/h'}
                />
                <Meter
                    headtitle={'24時間雨量'}
                    subtitle={subtitleText}
                    value={viewData.h24_msh}
                    colorclass={get_colorclass(viewData.h24_msh, 'h24')}
                    is_btn={false}
                    unit={'mm'}
                />
                <Meter
                    headtitle={'48時間雨量'}
                    subtitle={subtitleText}
                    value={viewData.h48_msh}
                    colorclass={get_colorclass(viewData.h48_msh, 'h48')}
                    is_btn={false}
                    unit={'mm'}
                />
                <Meter
                    headtitle={'土壌雨量指数'}
                    subtitle={subtitleText}
                    value={viewData.sr_msh}
                    colorclass={get_colorclass(viewData.sr_level, 'sr_msh')}
                    is_btn={false}
                    unit={''}
                />
                <Meter
                    headtitle={'土砂災害発生リスク'}
                    subtitle={subtitleText}
                    value={viewData.rsk_msh}
                    colorclass={get_colorclass(viewData.rsk_msh, 'rsk')}
                    is_btn={false}
                    unit={''}
                />
                {
                    data.bsn_code && <React.Fragment>
                        <MeterTitle
                            children={`${data.txt_river_system_name}水系${data.txt_river_name}`}
                        />
                        <Meter
                            headtitle={'1時間雨量'}
                            subtitle={subtitleText}
                            value={viewData.h1_bsn}
                            colorclass={get_colorclass(viewData.h1_bsn, 'h1')}
                            is_btn={false}
                            unit={'mm/h'}
                        />
                        <Meter
                            headtitle={'24時間雨量'}
                            subtitle={subtitleText}
                            value={viewData.h24_bsn}
                            colorclass={get_colorclass(viewData.h24_bsn, 'h24')}
                            is_btn={false}
                            unit={'mm'}
                        />
                        <Meter
                            headtitle={'48時間雨量'}
                            subtitle={subtitleText}
                            value={viewData.h48_bsn}
                            colorclass={get_colorclass(viewData.h48_bsn, 'h48')}
                            is_btn={false}
                            unit={'mm'}
                        />
                        <Meter
                            headtitle={'流出量'}
                            subtitle={subtitleText}
                            value={viewData.of_bsn}
                            colorclass={get_colorclass(viewData.of_bsn, 'of')}
                            is_btn={false}
                            unit={''}
                        />
                    </React.Fragment>
                }
                <MeterTitle children={`${data.ctv_name}平均`} />
                <Meter
                    headtitle={'1時間雨量'}
                    subtitle={subtitleText}
                    value={viewData.h1_ctv}
                    colorclass={get_colorclass(viewData.h1_ctv, 'h1')}
                    is_btn={false}
                    unit={'mm/h'}
                />
                <Meter
                    headtitle={'24時間雨量'}
                    subtitle={subtitleText}
                    value={viewData.h24_ctv}
                    colorclass={get_colorclass(viewData.h24_ctv, 'h24')}
                    is_btn={false}
                    unit={'mm'}
                />
                <Meter
                    headtitle={'48時間雨量'}
                    subtitle={subtitleText}
                    value={viewData.h48_ctv}
                    colorclass={get_colorclass(viewData.h48_ctv, 'h48')}
                    is_btn={false}
                    unit={'mm'}
                />
                <Meter
                    headtitle={'土壌雨量指数'}
                    subtitle={subtitleText}
                    value={viewData.sr_ctv}
                    colorclass={get_colorclass(viewData.sr_ctv, 'sr')}
                    is_btn={false}
                    unit={''}
                />
                <MeterTitle children={'交通インフラ'}/>
                <Meter
                    headtitle={'鉄道'}
                    value={data.txt_rail_name}
                    colorclass={'bg-none'}
                    is_btn={false}
                    unit={''}
                />
                <Meter
                    headtitle={'主要道路'}
                    value={data.txt_road_name}
                    colorclass={'bg-none'}
                    is_btn={false}
                    unit={''}
                />
                <MeterTitle children={`更新 : ${dateToMMDDHHmm(data.newest_date)}`}/>
            </Wrapper>
        </Drawer>
    )

}
