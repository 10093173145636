import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// Item Component
import Meter from '../../items/meter'
import LoadingMeter from '../../items/loadingmeter'

// function
import { get_colorclass_from_class } from '../../func/getcolor'
import { JSTToMMDDHHmm } from '../../func/datetoview'

TelList.propTypes = {
    // 選択中の流域コード
    bsnCode: PropTypes.number,
    // 選択中の水位計コード
    mtrCode: PropTypes.number,
    // 選択中の水系名
    bsnName: PropTypes.string,
    // 選択水位計更新関数
    updateChooseMtr: PropTypes.func,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント時間
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
}

const ListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    &&& .meter{
        margin: 0.3rem;
        padding: 0.5rem;
        min-width: 180px;
        max-width: 240px;
        min-height: 82px;
    }
`
const TitleWrapper = styled.div`
    margin: 1rem 0;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    width: 100%;
`

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * テレメータ一覧
 * @param {number} bsnCode 水系コード
 * @param {number} mtrCode テレメータコード
 * @param {string} bsnName 水系名
 * @param {func} updateChooseMtr 選択中のテレメータを変更する関数
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か 
 * @returns {JSX.Element}
 */
export default function TelList(props){
    const { bsnCode, mtrCode, bsnName, updateChooseMtr, disasterId='', checkpoint='', isArchive=false } = props

    const onclickFunc = (props) => {
        updateChooseMtr(props[0], props[1])
    }

    const { data, error } = useSWR(
        () => bsnCode &&
            isArchive ? `${config.restUrl}/${disasterId}/${checkpoint}/wtl/tel/list/${bsnCode}/`
            : `${config.restUrl}/wtl/tel/list/${bsnCode}/`,
            fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は5回まで
                if(retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
            },
            refreshInterval: 300000
        }
    )

    const isLoading = !data && !error
    if(isLoading){
        return(
        <ListWrapper>
            <TitleWrapper>
                {
                    bsnName === '九州その他' ? '大分二級河川水位(パーセント)'
                    : bsnName + '水系水位(パーセント)'
                }
            </TitleWrapper>
            {
                [...Array(15)].map((elem, i) => (
                    <LoadingMeter key={`loading-mtr-${i}`} />
                ))
            }
        </ListWrapper>
        )
    }
    if(error){
        return(
            <React.Fragment>
                <TitleWrapper>
                    {
                        bsnName === '九州その他' ? '大分二級河川水位(パーセント)'
                        : bsnName + '水系水位(パーセント)'
                    }
                </TitleWrapper>
                <ErrorMsg msg={'データが見つかりませんでした'} />
            </React.Fragment>
        )
    }

    // 水位計未選択時に先頭の水位計を自動で選択する
    if(!mtrCode){
        updateChooseMtr(data.results[0].tel_code, 'tel')
    }

    return(
        <ListWrapper>
            <TitleWrapper>
                {
                    bsnName === '九州その他' ? '大分二級河川水位(パーセント)'
                    : bsnName + '水系水位(パーセント)'
                }
            </TitleWrapper>
            {
                data.results.map((elem, i) => (
                    <Meter
                        key={elem.tel_code}
                        headtitle={`${elem.tel_name} - ${elem.river_name}`}
                        subtitle={elem.tel_date ? JSTToMMDDHHmm(elem.tel_date) : ''}
                        value={elem.value !== null ? elem.value : '欠測'}
                        colorclass={get_colorclass_from_class(elem.colorclass)}
                        is_btn={mtrCode !== elem.tel_code}
                        isChoosed={ mtrCode === elem.tel_code}
                        unit={elem.colorclass === 1 ? 'm' : elem.colorclass === 0 ? '' : '%'}
                        changes={elem.diff_flag}
                        updateStateFunc={onclickFunc}
                        funcProps={[elem.tel_code, 'tel']}
                    />
                ))
            }
        </ListWrapper>
    )
}
