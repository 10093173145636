import React from 'react'
import styled from 'styled-components'

import TopPage from '../../templates/toppage'

/**
 * トップ画面(気象警報)
 * @returns {JSX.Element} トップ画面テンプレート
 */
export default function Top(){
    return (
        <TopPage />
    )
}
