import { Box, Slider, IconButton, Stack } from '@mui/material';
import { styled } from "@mui/system";
import Leaflet from "leaflet";
import { MapContainer, } from 'react-leaflet'
import { FullScreen } from 'react-full-screen'
import "./map.css";

export const StyleMapContainer = styled(MapContainer)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    &&& .baseLayers{
        -webkit-filter: grayscale(100%);
        /* filter: invert(100%) grayscale(100%); */
        filter: grayscale(100%);
        z-index: 1 !important;
    }
    &&& .leaflet-control-layers-overlays, .leaflet-control-layers-base{
        text-align: left;
    }
`
export const StyleFullScreen = styled(FullScreen)`
    width: 100%;
    height: 100%;
    &.fullscreen-enabled>div{
        height: 100%;
    }
`
export const FullScreenIconButton = styled(IconButton)(() => (
    {
        position: 'absolute',
        width: '47px',
        height: '47px',
        top: '4.0rem',
        right: '10px',
        zIndex: 500,
        border: '2px solid rgba(0, 0, 0, 0.3)',
        borderRadius: '5px',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'rgb(243, 243, 243)'
        }
    }));

export const RedIcon = Leaflet.icon({
    iconUrl: "https://esm.sh/leaflet@1.9.2/dist/images/marker-icon.png",
    iconRetinaUrl: "https://esm.sh/leaflet@1.9.2/dist/images/marker-icon-2x.png",
    shadowUrl: "https://esm.sh/leaflet@1.9.2/dist/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
    className: "icon-red", // <= ここでクラス名を指定
});

// マップのスライダー表示用ボックス
export const StackMapSlider = styled(Stack)(() => (
    {
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        left: 'auto',
        right: 'auto',
        bottom: 0,
        backgroundColor: 'rgb(6, 16, 39, 0.67)',
        zIndex: 1000,
        padding: '0.25rem 0.2rem'
    }));
export const BoxMapSlider = styled(Box)(() => (
    {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'start',
        width: '100%',
        minWidth: '350px',
    }));

export const MapSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#0a84ff' : '#007bff',
    padding: '15px 0',
    '& .MuiSlider-track': {
        height: '10px',
        backgroundColor: 'rgba(43, 85, 150, 1)',
        border: '2px solid rgba(0, 0, 0, 0.3)',
    },
    '& .MuiSlider-thumb': {
        height: '20px',
        width: '20px',
        backgroundColor: '#fff',
        boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
    },
    '& .MuiSlider-rail': {
        height: '10px',
        opacity: 1,
        boxShadow: 'inset 0px 0px 4px -2px #000',
        backgroundColor: 'rgba(8, 16, 37, 1)',
    },
    // '& .MuiSlider-mark': {
    //     height: '15px',
    // },
    '& .MuiSlider-markLabel': {
        fontSize: '0.8rem',
        color:'#fff',
        fontWeight: 'bold',
    },
}));
