import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { useLocation, useNavigate } from 'react-router-dom'
import { config } from '../../../config.jsx'

// MUI
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import RestoreIcon from '@mui/icons-material/Restore'
import { Place, Analytics, BarChart, Map } from '@mui/icons-material'

export default function TelmapFooter(props) {
    const {
        displayNavigationDrawer
    } = props

    return (
        <Box sx={{ width: '100%', height: '56px' }}>
            <BottomNavigation
                showLabels={true}
                onChange={(event, newValue) => {
                    displayNavigationDrawer(newValue)
                }}
            >
                <BottomNavigationAction label="グラフ" value="graph" icon={<BarChart />} />
            </BottomNavigation>
        </Box>
    )
}