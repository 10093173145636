import React from 'react'
import styled from 'styled-components'

import EvacDashboard from '../../templates/evacdashboard'

/**
 * 避難情報画面
 * @returns {JSX.Element} 避難所テンプレート
 */
export default function Evac(){
    return(
        <EvacDashboard />
    )
}
