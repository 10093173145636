import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

// user defined function
import { getCardColor } from '../../func/getcolor'
import { getEvacColor } from '../../func/getcolor'

// svg file
import {ReactComponent as PrefMap} from '../../svg/alertmap/44.svg'

/**
 * 避難情報を表示するためのSVG
 * @param {array} evacArray 避難情報データ
 * @param {func} clickEvent クリック時に発火する関数
 * @returns {JSX.Element}
 */
export default function EvacSvg(props){
    const { evacArray, clickEvent } = props

    let addstyle ='{}'
    evacArray.map((elm) =>
        addstyle += elm !== false ? `
            #jma-${elm.ctv_code}00{
                fill: ${getCardColor(getEvacColor(elm.alert_level))};
                &:hover{
                filter: brightness(1.5);
                cursor: pointer;
                }
            }
        ` : ''
    )

    const handleClick = event => {
        clickEvent(event, evacArray)
    }

    return(
        <SvgWrapper addstyle={css`${addstyle}`}>
            <PrefMap onClick={handleClick} />
        </SvgWrapper>
    )
}
const SvgWrapper = styled.div`
    width: 50%;
    height: fit-content;
    background-color: #84B8D9;
    max-height: 100%;
    margin: auto;
    &&& svg{
        width: 100%;
        height: 77vh;
        g {
            stroke: rgb(25, 25, 25);
            stroke-wdith: 0.4;
            fill: ${getCardColor('bg-none')};
            filter: brightness(1.1);
        }
        #other{
            fill: rgb(210, 210, 210);
        }
        ${(props) => props};
    }
`
