import muni from './muni';

export default async function getAddress(
    latitude,
    longitude
) {
    const result = await fetch(`https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress?lat=
    ${latitude}&lon=${longitude}`
        , { credentials: "omit" }
    )
        .then(response => response.json())
        .then(data => {
            // 住所取得成功時の処理
            console.log(data.results)
            const { muniCd, lv01Nm } = data.results;
            const cd = muniCd.replace(/^0+/, '')
            const add = muni.getMuni(cd).split(',')
            return `${add[1]} ${add[3]} ${lv01Nm}`
        })
        .catch((error) => {
            console.log(error);
            return '丁目まで入力して検索してください'
        })
    return result

}