import L from 'leaflet'

/**
 * 四角いSVGアイコン
 * @param {string} props 塗りつぶしの色設定 
 * @returns {JSX.Element}
 */
export const svgSquareIcon = (props) => {
    const SvgIcon = L.divIcon({
        html: `
            <svg
                width="50"
                height="50"
                viewBox="0 0 100 100"
                version="1.1"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    width="50"
                    height="50"
                    fill=${props}
                    stroke="white"
                    stroke-width="3"
                />
                <line
                    x1="3"
                    y1="3"
                    x2="47",
                    y2="47"
                    stroke="#ff2600"
                    stroke-width="5"
                />
            </svg>
        `,
        className: 'Icons',
        iconAnchor: [10, 10]
    })
    return SvgIcon
}
