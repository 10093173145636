import React from 'react'

// template component
import NoHeader from '../../templates/noheader'

/**
 * ログイン画面
 * @returns {JSX.Element} ヘッダーなし画面テンプレート
 */
export default function Login(){
    return(
        <NoHeader />
    )
}
