import React from 'react'
import PropTypes from 'prop-types'
import { HeaderStatusBadge } from '../../style/header'

StatusBadge.propTypes = {
  // バッジに表示する文字
  text: PropTypes.string,
  // 文字の色
  color: PropTypes.string,
  // 文字の大きさ
  size: PropTypes.string
}

/**
 * ステータスを示すバッジ(災害対応中など)
 * @param {string} text 表示するテキスト
 * @param {string} color 文字の色
 * @param {string} size 文字の大きさ 
 * @returns {JSX.Element}
 */
export default function StatusBadge(props){
  const { text, backColor, color, size } = props
  return(
    <HeaderStatusBadge>{text}</HeaderStatusBadge>
  )
}
