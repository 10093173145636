import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import MeterSlider from '../../contents/meterslider'
import TelMapView from '../../contents/telmapview'

TelMapDashboard.ropTypes = {
    // 選択しているコード(固定)
    chooseCode: PropTypes.number,
    // 選択している時間
    chooseDate: PropTypes.number,
    // 選択している時間を更新する関数
    updateChooseDate: PropTypes.func,
}

const AppWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: calc(100vh - 48px);
    flex-direction: column;
`

const ContentsWrapper = styled.div`
    display: flex;
    padding: 0 0 1rem 0;
    overflow-y: hidden;
    height: 100%;
`

/**
 * 河川水位マップテンプレート
 * @param {number} chooseCode 選択しているコード
 * @param {number} chooseDate 選択している時間(インデックス値)
 * @param {func} updateChooseDate 選択している時間を更新する関数
 * @returns {JSX.Element}
 */
export default function TelMapDashboard(props){
    const {chooseCode, chooseDate, updateChooseDate} = props
    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const {disaster_id, checkpoint} = useParams()

    if(disaster_id && checkpoint){
        return(
            <React.Fragment>
                <RiskHeader 
                    disasterId={disaster_id}
                    checkpoint={checkpoint}
                    isArchive={true}
                />
                <AppWrapper>
                    <MeterSlider
                        bsnCode={props.chooseCode}
                        updateChooseDate={props.updateChooseDate}
                        disasterId={disaster_id}
                        checkpoint={checkpoint}
                        isArchive={true}
                    />
                    <ContentsWrapper>
                    <TelMapView
                        chooseDate={Number(chooseDate)}
                        disasterId={disaster_id}
                        checkpoint={checkpoint}
                        isArchive={true}
                    />
                    </ContentsWrapper>
                </AppWrapper>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <RiskHeader />
            <AppWrapper>
                <MeterSlider
                    bsnCode={props.chooseCode}
                    updateChooseDate={props.updateChooseDate}
                />
                <ContentsWrapper>
                <TelMapView
                    chooseDate={Number(chooseDate)}
                />
                </ContentsWrapper>
            </AppWrapper>
        </React.Fragment>
    )
}
