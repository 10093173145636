import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GetData } from '../../func/get_data_swr';

// Contents Component
import MstShelterList from '../../contents/mstshelterlist'
import ShelterGraph from '../../contents/sheltergraph'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// Item Component
import Meter from '../../items/meter'
import LoadingMeter from '../../items/loadingmeter'

// function
import { get_colorclass_from_class } from '../../func/getcolor'
import { JSTToMMDDHHmm } from '../../func/datetoview'

ShelterBoard.propTypes = {
    // 選択中の市町村コード
    ctvCode: PropTypes.number,
    // 選択中の市町村名
    ctvName: PropTypes.string,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
}

/**
 * 避難所画面を構成するコンポーネント
 * @param {number} CtvCode 市町村コード
 * @param {string} ctvName 選択中の市町村名
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @returns {JSX.Element}
 */
export default function ShelterBoard(props) {
    const { ctvCode, ctvName, disasterId = '', checkpoint = '', isArchive = false } = props

    // 選択中の避難所名
    const [shelterName, setShelterName] = useState('')
    // 選択中の避難所種類
    const [shelterType, setShelterType] = useState('')
    // 選択中の避難所ID
    const [shelterCode, setShelterCode] = useState('')

    // アーカイブか否かで呼び出すAPIを分ける
    let url = `/pref/shelter/ctv/sum/${ctvCode}/`
    if (isArchive) {
        url = `/${disasterId}/${checkpoint}/pref/shelter/ctv/sum/${ctvCode}/`
    }
    const { data, loading, error } = GetData(url);


    function updateChooseShelter(name, type, code) {
        setShelterName(name)
        setShelterType(type)
        setShelterCode(code)
    }

    if (ctvCode === 0 && ctvName === 'NotFound') {
        return (
            <ErrorMsg msg={'現在，開設中の避難所はありません'} />
        )
    }

    if (loading) {
        return (
            <React.Fragment>
                <ListWrapper>
                    <LoadingMeter key={`loading-mtr-ctv`} />
                    <MstShelterList
                        ctvCode={ctvCode}
                        ctvName={ctvName}
                        shelterName={shelterName}
                        shelterType={shelterType}
                        updateChooseShelter={updateChooseShelter}
                        disasterId={disasterId}
                        checkpoint={checkpoint}
                        isArchive={isArchive}
                    />
                </ListWrapper>
                <GraphWrapper>
                    <ShelterGraph
                        shelterName={shelterName}
                        shelterType={shelterType}
                        disasterId={disasterId}
                        checkpoint={checkpoint}
                        isArchive={isArchive}
                        ctvCode={ctvCode}
                        shelterCode={shelterCode}
                    />
                </GraphWrapper>
            </React.Fragment>
        )
    }

    if (error) {
        return (
            <React.Fragment>
                <ListWrapper>
                    <MstShelterList
                        ctvCode={ctvCode}
                        ctvName={ctvName}
                        shelterName={shelterName}
                        shelterType={shelterType}
                        updateChooseShelter={updateChooseShelter}
                    />
                </ListWrapper>
                <GraphWrapper>
                    <ShelterGraph
                        shelterName={shelterName}
                        shelterType={shelterType}
                    />
                </GraphWrapper>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <ListWrapper>
                <Meter
                    key={'shelter-mtr-ctv-mst'}
                    headtitle={`${ctvName}合計避難者数`}
                    subtitle={data.report_date ? JSTToMMDDHHmm(data.report_date) : ''}
                    value={data.hdcnt_num && data.hhs_num ? `${data.hdcnt_num}人 - ${data.hhs_num}世帯` : '避難者なし'}
                    colorclass={''}
                    is_btn={false}
                    isChoosed={false}
                    unit={''}
                    changes={data.change_flag}
                />
                <MstShelterList
                    ctvCode={ctvCode}
                    ctvName={ctvName}
                    shelterName={shelterName}
                    shelterType={shelterType}
                    updateChooseShelter={updateChooseShelter}
                    disasterId={disasterId}
                    checkpoint={checkpoint}
                    isArchive={isArchive}
                />
            </ListWrapper>
            {shelterName !== null && shelterName !== undefined && shelterName !== '' &&
                shelterType !== null && shelterType !== undefined && shelterType !== '' &&
                <GraphWrapper>
                    <ShelterGraph
                        shelterName={shelterName}
                        shelterType={shelterType}
                        disasterId={disasterId}
                        checkpoint={checkpoint}
                        isArchive={isArchive}
                        ctvCode={ctvCode}
                        shelterCode={shelterCode}
                    />
                </GraphWrapper>
            }
        </React.Fragment>
    )
}

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding-left: 5vw;
    padding-right: 1rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
    overflow-y: scroll;
    height: 100%
`

// グラフラッパー
const GraphWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding-right: 5vw;
    padding-left: 1rem;
    margin: 0.5rem 0;
    height: auto;
    overflow-y: scroll;
`
