import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Atom Commponent
import SwitchBtn from '../../atoms/switchbtn'
import ErrorMsg from '../../atoms/errormsg'

BsnBtn.propTypes = {
    // 水系コード
    bsnCode: PropTypes.number,
    // 水系情報を更新する
    updateBsnStatus: PropTypes.func
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 5vw;
`

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 水系選択ボタン
 * @param {number} bsnCode 水系コード
 * @param {func} updateBsnStatus 選択中の水系情報を変更する関数 
 * @returns {JSX.Element}
 */
export default function BsnBtn(props){
    const { bsnCode, updateBsnStatus } = props

    const { data, error } = useSWR(
        () =>
            `${config.restUrl}/wtl/bsn/`,
            fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は5回まで
                if(retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
            },
        }
    )

    const isLoading = !data && !error
    // Loading
    if(isLoading){
        return(<React.Fragment />)
    }

    if(error){
        return(<ErrorMsg text={'データが見つかりませんでした'} />)
    }

    const onClickBtn = (props) => {
        updateBsnStatus(props[0], props[1])
    }

    return(
        <Wrapper>
            {
                data.results.map((elem, i) => (
                    <SwitchBtn
                        key={elem.code}
                        text={elem.name}
                        isChoosed={elem.code === bsnCode}
                        updateChooseData={onClickBtn}
                        propArray={[elem.code, elem.name]}
                    />
                ))
            }
        </Wrapper>
    )

}
