import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import DashboardMap from '../../contents/dashboardmap'
import InfiniteRank from '../../contents/infiniterank'
import MeshMeter from '../../contents/meshmeter'
import RainGraph from '../../contents/raingraph'
import RainFooter from '../../contents/rainfooter'

RiskDashboard.propTypes = {
    // 緯度経度
    latlng: PropTypes.array,
    // 選択されている日付
    chooseDate : PropTypes.string,
    // 選択されている日付のインデックス
    chooseNum : PropTypes.number,
    // 最新の解析雨量取得時間
    newestDate: PropTypes.string,
    // 日付リスト
    dateArray : PropTypes.array,
    // 現在選択されている時刻が予測か
    isForecast : PropTypes.bool,
    // 位置選択時，緯度経度を更新する関数
    updateLatlng : PropTypes.func,
    // キーとなる日付の状態を更新する関数
    updateChooseDate : PropTypes.func,
    // 日付のインデックスの状態を更新する関数
    updateChooseNum : PropTypes.func,
    }

const AppWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`
const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 20vw;
    margin: 0.5rem;
`
const CenterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100svh - 48px - 56px);
`
const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 20vw;
    height: calc(100vh - 1rem - 48px);
    margin: 0.5rem;
`

/**
 * リスク分析テンプレート
 * @param {array} latlng 緯度経度
 * @param {string} chooseDate 選択されている日付
 * @param {number} chooseNum 選択されている日付のインデックス値
 * @param {string} newestDate 最新の解析雨量取得時間
 * @param {array} dateArray 日付リスト
 * @param {array} vectorList ベクタータイル表示リスト
 * @param {bool} isForecast 現在表示されている時刻が予想か否か
 * @param {func} updateLatlng 位置選択時，緯度経度を更新する関数
 * @param {func} updateChooseDate 選択している日付を更新する関数
 * @param {func} updateChooseNum 選択している日付のインデックス値を更新する関数
 * @returns {JSX.Element}
 */
export default function RiskDashboard(props){
    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const {disaster_id, checkpoint} = useParams()
    // シミュレーションiDがあればパラメータから受け取る
    const {simulation_id} = useParams()
    // グラフドロワー開閉ステータス
    const [bottomNavValue, setBottomNavValue] = useState('map')
    const [isRainGraphDrawerOpen, setIsRainGraphDrawerOpen] = useState(false)
    const [isMeterDrawerOpen, setIsMeterDrawerOpen] = useState(false)
    const [isRankingDrawerOpen, setIsRankingDrawerOpen] = useState(false)

    const displayNavigationDrawer = (navValue) => {
        setBottomNavValue(navValue)
        switch (navValue) {
            case 'map':
                setIsMeterDrawerOpen(false)
                setIsRainGraphDrawerOpen(false)
                setIsRankingDrawerOpen(false)
                break
            case 'detail':
                setIsMeterDrawerOpen(true)
                setIsRainGraphDrawerOpen(false)
                setIsRankingDrawerOpen(false)
                break
            case 'graph':
                setIsMeterDrawerOpen(false)
                setIsRainGraphDrawerOpen(true)
                setIsRankingDrawerOpen(false)
                break
            case 'ranking':
                setIsMeterDrawerOpen(false)
                setIsRainGraphDrawerOpen(false)
                setIsRankingDrawerOpen(true)
                break
            default:
                break
        }
    }

    return(
        <React.Fragment>
            <RiskHeader />
            <AppWrapper>
                <CenterWrapper>
                    <DashboardMap
                        latlng={props.latlng}
                        chooseDate={props.chooseDate}
                        chooseNum={props.chooseNum}
                        newestDate={props.newestDate}
                        dateArray={props.dateArray}
                        isForecast={props.isForecast}
                        updateLatlng={props.updateLatlng}
                        updateChooseDate={props.updateChooseDate}
                        updateChooseNum={props.updateChooseNum}
                    />
                    <RainGraph
                        latlng={props.latlng}
                        isRainGraphDrawerOpen={isRainGraphDrawerOpen}
                        displayNavigationDrawer={displayNavigationDrawer}
                    />
                    <MeshMeter
                        latlng={props.latlng}
                        chooseDate={props.chooseDate}
                        isMeterDrawerOpen={isMeterDrawerOpen}
                        displayNavigationDrawer={displayNavigationDrawer}
                    />
                    <InfiniteRank
                        updateLatlng={props.updateLatlng}
                        updateChooseDate={props.updateChooseDate}
                        isRankingDrawerOpen={isRankingDrawerOpen}
                        displayNavigationDrawer={displayNavigationDrawer}
                    />
                </CenterWrapper>
            </AppWrapper>
            <RainFooter
                displayNavigationDrawer={displayNavigationDrawer}
            />
        </React.Fragment>
    )
}
