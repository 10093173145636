// // 愛媛
// export const DEFAULT_POSITION = {
//   LAT: 33.506399,
//   LNG: 132.544520,
// }

// 大分
export const DEFAULT_POSITION = {
  LAT: 33.2305526,
  LNG: 131.6068285,
}

export const DEFAULT_MAP_ZOOM = 10

// 地図に表示するベクタータイルのリスト
// 1つめは必ず解析雨量レーダーとすること
export const BASE_LAYER_LIST = [
	{
		category: 'h1_msh',
		name: '1時間雨量',
		maxZoomSize: 12,
		is_river: false,
	},
	{
		category: 'h24_msh',
		name: '24時間雨量',
		maxZoomSize: 12,
		is_river: false,
	},
	{
		category: 'h48_msh',
		name: '48時間雨量',
		maxZoomSize: 12,
		is_river: false,
	},
	{
		category: 'sr_msh',
		name: '土壌雨量指数',
		maxZoomSize: 12,
		is_river: false,
	},
	{
		category: 'rsk_msh',
		name: '土砂災害発生リスク',
		maxZoomSize: 12,
		is_river: false,
	},
	// {
	//   category: 'rfs_msh',
	//   name: '河川洪水発生リスク',
	//   maxZoomSize: 12,
	//   is_river: false,
	// },
	{
		category: 'h1_bsn',
		name: '流域平均1時間雨量',
		maxZoomSize: 12,
		is_river: true,
	},
	{
		category: 'h24_bsn',
		name: '流域平均24時間雨量',
		maxZoomSize: 12,
		is_river: true,
	},
	{
		category: 'h48_bsn',
		name: '流域平均48時間雨量',
		maxZoomSize: 12,
		is_river: true,
	},
	{
		category: 'of_bsn',
		name: '流域平均流出量',
		maxZoomSize: 12,
		is_river: true,
	},
]

