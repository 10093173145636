import React from 'react'
import { Button } from '@mui/material';
import { BoxSpaceAroundMarginLR10 } from '../../style/box'
import { SubmitButton, } from '../../style/button'

export default function CancelSubmit(props) {
  return (
    <BoxSpaceAroundMarginLR10>
      <Button
        variant="contained"
        color="inherit"
        sx={{
          width: '40%',
        }}
        onClick={() =>
          props.onCancel()
        }
      >キャンセル</Button>
      <Button
        variant="contained"
        type="submit"
        sx={{
          width: '40%',
          color: 'white',
          backgroundColor: '#318ab7',
          '&:hover': {
            backgroundColor: '#435e7a'
          }
        }}
      >{props.submitButtonName}</Button>
    </BoxSpaceAroundMarginLR10>
  )
}