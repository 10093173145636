import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

// user defined function
import { getCardColor } from '../../func/getcolor'
import { getJMAAlertColor } from '../../func/getcolor'

// svg file
import {ReactComponent as PrefMap} from '../../svg/alertmap/44.svg'

AlertMap.propTypes = {
  // 県コード
  prefcode: PropTypes.number,
}


/**
 * 気象警報を表示するためのSVG
 * @param {array} alertlist 気象警報データリスト 
 * @returns {JSX.Element}
 */
export default function AlertMap(props){
  const { alertlist } = props

  // 警報・注意報ごとに色を塗るcssを設定
  // 何故か，先頭に{}を入れないと一番最初のcssが効かない
  let addstyle = '{}'
  alertlist.map((elm) =>
    addstyle += elm !== false ? `
      #jma-${elm[0]} {
        fill: ${getCardColor(elm[1])};
      }
    ` : ``
  )

  return(
    <SvgWrapper addstyle={css`${addstyle}`}>
      <PrefMap />
    </SvgWrapper>
  )
}

const SvgWrapper = styled.div`
  width: 50%;
  height: fit-content;
  background-color: #84B8D9;
  max-height: 100%;
  &&& svg{
    width: 100%;
    height: 77vh;
    g {
      stroke: black;
      fill: ${getCardColor('bg-none')};
      filter: brightness(1.1);
    }
    #other{
      fill: rgb(210, 210, 210);
    }
    ${(props) => props};
  }
`
