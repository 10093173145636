import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Atomic Components
import JMAAlert from '../../atoms/jmaalert'
import AlertMap from '../../atoms/alertmap'

// original function
import { dateToMMDDHHmmGraph } from '../../func/datetoview'
import { getJMAAlertColor } from '../../func/getcolor'

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * トップ画面(気象警報)
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @param {bool} isSimulation シミュレーションか否か
 * @param {string} simulationId シミュレーションID
 * @returns {JSX.Element}
 */
export default function Top({disasterId='', checkpoint='', isArchive=false, simulationId='', isSimulation=false}){
    // RESTにリクエストするURL
    const requestUrl = isSimulation ? `${config.restUrl}/sim/${simulationId}/${checkpoint}/jma/alert/44/`
                        : isArchive ? `${config.restUrl}/${disasterId}/${checkpoint}/jma/alert/44/`
                        : `${config.restUrl}/jma/alert/44/`
    const { data, error } = useSWR(
        () => requestUrl,
        fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は5回まで
                if(retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
            },
            refreshInterval: 300000
        }
    )

    const isLoading = !data && !error
    const isData = !isLoading && !error

if(isLoading || error){
    return(
        <Wrapper>
        </Wrapper>
    )
}

//  一番大きな警報の色を取得
const alertList = Object.keys(data).map((key) =>
    data[key].length !== 0 ? [Math.max(...data[key].map((elm) => elm.jma_area_code)),
    getJMAAlertColor(Math.max(...data[key].map((elm) => elm.colorCode)))] : [])

return(
    <Wrapper>
        <TitleWrapper>大分県内に発表されている警報・注意報</TitleWrapper>
        <ContentsWrapper>
            <AlertListWrapper>
                <Table stickyHeader aria-label='jma alert table'>
                    <TableHead>
                        <HeadRow>
                            <TableElm width={'150px'}>
                                <TableColumns>市町村名</TableColumns>
                            </TableElm>
                            <TableElmLine>
                                <TableColumns>気象警報</TableColumns>
                            </TableElmLine>
                        </HeadRow>
                    </TableHead>
                <TableBody>
                    {
                    Object.keys(data).map((areaname) => (
                        <tr key={`${areaname}`}>
                        <TableElm>
                            <ElmWrapper>{areaname}</ElmWrapper>
                        </TableElm>
                        <AlertElm>
                            {
                            data[areaname].length !== 0 ? data[areaname].map((elem, i) => (
                                <JMAAlert
                                alertname={elem.alertName}
                                subtitle={dateToMMDDHHmmGraph(elem.announceDate)}
                                colorclass={getJMAAlertColor(elem.colorCode)}
                                />
                            ))
                            : <JMAAlert
                                alertname={'発表なし'}
                                colorclass={'bg-none'}
                                />
                            }
                        </AlertElm>
                        </tr>
                    ))
                    }
                </TableBody>
                </Table>
            </AlertListWrapper>
            <AlertMap alertlist={alertList} />
        </ContentsWrapper>
    </Wrapper>
)
}

const Table = styled.table`
    border-spacing: 0;
    width: 100%;
`
const HeadTableCell = styled.div`
    background-color: rgb(21, 36, 81);
`
const TableHead = styled.thead`
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
`
const TableElm = styled.th`
    border-bottom: thin ridge #6ab0d1;
    padding: 0.25rem 1rem;
    text-align: left;
    width: ${(props) => props.width && props.width};
`
const TableElmLine = styled(TableElm)`
    border-left: thin ridge #6ab0d1;
`
const HeadRow = styled.tr`
    background-color: rgb(21, 36, 81);
`
const TableColumns = styled.div`
    color: white;
    font-weight: bold;
    font-size: 20px;
`
const TableBody = styled.tbody`
`

const Wrapper = styled.div`
    margin: 5vh 5vw;
    flex-direction: column;
    height: 100%;
    display: flex;
`
const ContentsWrapper = styled.div`
    display: flex;
    height: 100%;
`
const AlertListWrapper = styled.div`
    overflow-y: scroll;
    border-radius: 5px;
    width: 50%;
    height: calc(100% - 15vh);
    margin-right: 1rem;
`
const TitleWrapper = styled.div`
    font-weight: bold;
    font-size: 36px;
    color: white;
    margin: 1rem 0;
`
const ElmWrapper = styled.div`
    font-weight: bold;
    color: white;
`
const AlertElm = styled(TableElm)`
    display: flex;
    border-left: thin ridge #6ab0d1;
    padding-top: 0;
    padding-bottom: 10px;
    flex-wrap: wrap;
`
