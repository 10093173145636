import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import CtvBtn from '../../contents/ctvbtn'
import ShelterBoard from '../../contents/shelterboard'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

/**
 * 避難所画面
 * @returns {JSX.Element} 避難所テンプレート
 */
export default function Shelter() {

    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const { disaster_id, checkpoint } = useParams()

    // アーカイブか否かの判定フラグ
    let isArchive = false
    if (disaster_id && checkpoint) {
        isArchive = true
    }

    // 市町村コード
    const [ctvCode, setCtvCode] = useState(null)
    // 市町村名
    const [ctvName, setCtvName] = useState(null)

    // 選択している市町村の避難所情報を更新する
    function updateCtvStatus(code, name) {
        setCtvCode(code)
        setCtvName(name)
    }

    return (
        <React.Fragment>
            <RiskHeader
                disasterId={disaster_id}
                checkpoint={checkpoint}
                isArchive={isArchive}
            />
            <AppWrapper>
                <CtvBtn
                    ctvCode={ctvCode}
                    updateCtvStatus={updateCtvStatus}
                    disasterId={disaster_id}
                    checkpoint={checkpoint}
                    isArchive={isArchive}
                />
                {ctvCode !== null && ctvCode !== undefined && ctvCode !== 0 ?
                    <ContentsWrapper>
                        <ShelterBoard
                            ctvCode={ctvCode}
                            ctvName={ctvName}
                            disasterId={disaster_id}
                            checkpoint={checkpoint}
                            isArchive={isArchive}
                        />
                    </ContentsWrapper>
                    :
                    <ErrorMsg text={'データが見つかりませんでした'} />
                }
            </AppWrapper>
        </React.Fragment>
    )
}

const AppWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: calc(100vh - 48px);
    flex-direction: column;
`

const ContentsWrapper = styled.div`
    display: flex;
    padding: 0 0 1rem 0;
    overflow-y: hidden;
    height: 100%;
`
