import moment from 'moment'

// 見せられる形に日付のstringを変更
/**
 * UTCをJSTに変更して日付のフォーマットを変更
 * @param {string} props 日付
 * @returns {string} 日付
 */
export const dateToMMDDHHmm = (props) => {
    if(!props) return ''
    return moment(props).add(9, 'hours').format('M月D日 HH:mm')
}

/**
 * 日付のフォーマットを変更
 * @param {string} props 日付
 * @returns {string} 日付
 */
export const JSTToMMDDHHmm = (props) => {
    if(!props) return ''
    return moment(props).format('M月D日 HH:mm')
}

/**
 * UTCをJSTにし，日付のフォーマットをグラフ用に変更
 * @param {string} props 日付
 * @returns {string} 日付
 */
export const dateToMMDDHHmmGraph = (props) => {
    if(!props) return ''
    return moment(props).add(9, 'hours').format('MM/DD HH:mm')
}

/**
 * UTCをJSTにし，日付のフォーマットを変更
 * @param {string} props 日付
 * @returns {string} 日付
 */
export const dateToYYYYMMDD = (props) => {
    if(!props) return ''
    return moment(props).add(9, 'hours').format('YYYY年M月D日')
}

/**
 * 日付のフォーマットを変更
 * @param {string} props 日付
 * @returns {string} 日付
 */
export const dateToYYYYMMDDHHMM = (props) => {
    if(!props) return ''
    return moment(props).format('YYYY年M月D日 HH:mm')
}

export const dateToYYYYMMDDHHMM4DashboardParam = (props) => {
    if(!props) return ''
    return moment(props).format('YYYY-MM-DD-HH:mm')
}

export const dateToStrYYYYMMDD = (props) => {
    if(!props) return ''
    return moment(props).format('YYYY年M月D日')
}
export const dateToStrHHMM = (props) => {
    if(!props) return ''
    return moment(props).format('HH:mm')
}
export const dateToStrYYYYMMDDHHmmss = (props) => {
    if(!props) return ''
    return moment(props).format('YYYYMMDDHHmmss')
}
export const descTimeSort = (a, b) => {
  return a < b ? 1 : -1;
}

export const compareDate = (date1, date2) => {
    // date1 が date2 よりも小さい（過去である）
    // 場合に true を返す
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1;
    const day1 = date1.getDate();
  
    const year2 = date2.getFullYear();
    const month2 = date2.getMonth() + 1;
    const day2 = date2.getDate();
  
    if (year1 == year2) {
      if (month1 == month2) {
        if(day1 == day2){
          return true
        }
        return day1 < day2;
      }
      else {
        return month1 < month2;
      }
    } else {
      return year1 < year2;
    }
  }