import React, { useState, useEffect } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useFullScreenHandle } from 'react-full-screen'

import { Box } from '@mui/material';
import {
  Fullscreen,
  FullscreenExit,
} from '@mui/icons-material'

import Leaflet from "leaflet";
import {
  Marker,
} from 'react-leaflet'
import { useMap, useMapEvents } from 'react-leaflet/hooks'
import * as protomapsL from "protomaps-leaflet";

import { config } from '../../config.jsx';
import MapPopup from '../../items/map_popup'
import MapTimeSlider from '../../items/map_slider'
import CommonMap from '../common_map_view'
import {
  StyleMapContainer,
  StyleFullScreen,
  FullScreenIconButton,
  RedIcon,
} from '../../style/map_components'
import "./map.css";


class RainSymbolizer {
  constructor(value) {
    this.value = value
    console.log(value)
  }
  draw(context, geom, z, feature) {
    let fill = "rgb(0, 0, 0, 0)"
    if (feature.props.choose_num == 0) {
      if (feature.props.sint_value_cls_num == 0)
        fill = "rgb(242, 242, 255, 0.65)"
      if (feature.props.sint_value_cls_num == 1)
        fill = "rgb(160, 210, 255, 0.65)"
      if (feature.props.sint_value_cls_num == 2)
        fill = "rgb(33, 140, 255, 0.65)"
      if (feature.props.sint_value_cls_num == 3)
        fill = "rgb(0, 65, 255, 0.65)"
      if (feature.props.sint_value_cls_num == 4)
        fill = "rgb(250, 245, 0, 0.65)"
      if (feature.props.sint_value_cls_num == 5)
        fill = "rgb(255, 153, 0, 0.65)"
      if (feature.props.sint_value_cls_num == 6)
        fill = "rgb(255, 40, 0, 0.65)"
      if (feature.props.sint_value_cls_num == 7)
        fill = "rgb(180, 0, 104, 0.65)"
    }
    context.fillStyle = fill
    // context.strokeStyle = fill
    context.beginPath()
    for (var poly of geom) {
      for (var p = 0; p < poly.length - 1; p++) {
        let pt = poly[p]
        if (p == 0) context.moveTo(pt.x, pt.y)
        else context.lineTo(pt.x, pt.y)
      }
    }
    // context.stroke()
    context.fill()
  }
}

const UpdateMap = (props) => {

  const [tileLayer, setTileLayer] = useState(null);
  const map = useMap();

  const mapEvents = useMapEvents({
    click: (e) => {
      console.log(e)
      const position = e.latlng
      console.log(position)
      // ポップアップ設定
      map.openPopup(Leaflet.popup({
        offset: [0, 0],
        closeButton: true,
        maxWidth: 300,

      })
        .setLatLng(e.latlng)
        .setContent(ReactDOMServer.renderToString(<MapPopup />))
        .openOn(map)
      )
      // ポップアップ内のクリックイベント
      document.getElementById('from_button').addEventListener('click', (e) => {
        props.setFromPosition(position)
        map.closePopup()
      });
      document.getElementById('to_button').addEventListener('click', (e) => {
        props.setToPosition(position)
        map.closePopup()
      });
    },
    zoomend: () => {
      props.setZoom(mapEvents.getZoom());
    },
  })

  useEffect(() => {

    console.log(props.tileDateForUrl)
    let PAINT_RULES = [
      {
        dataLayer: "h1msh_" + props.tileDateForUrl, // PMTiles作成時に定義したレイヤー名
        symbolizer: new RainSymbolizer(0),
        minzoom: 0,
        maxzoom: 14,
      },
    ];
    const tile_year = props.tileDateForUrl.substring(0, 4)
    const tile_month = props.tileDateForUrl.substring(4, 6)
    const tile_day = props.tileDateForUrl.substring(6, 8)
    const p = new protomapsL.PMTiles(
      `${config.pmtilesUrl}/all/${tile_year}/${tile_month}/${tile_day}/h1msh_${props.tileDateForUrl}.pmtiles`);
    console.log(p)

    const data = async () => {
      p.getMetadata().then(res => {

        // let bounds_str = response.antimeridian_adjusted_bounds.split(',')
        // let bounds = [[+bounds_str[1], +bounds_str[0]], [+bounds_str[3], +bounds_str[2]]]
        // console.log(bounds_str)
        // console.log(bounds)
        // const layer = new protomapsL.leafletLayer({ url: p, paintRules: PAINT_RULES, bounds: bounds })
        const layer = new protomapsL.leafletLayer({ url: p, paintRules: PAINT_RULES })
        if (tileLayer !== undefined && tileLayer !== null)
          map.removeLayer(tileLayer)
        layer.addTo(map)
        setTileLayer(layer)
        //map.fitBounds(bounds)
      })
    }
    data()

  }, [props.tileDateForUrl])

  return null
}

const SimulationCreateMap = (props) => {

  const vectorlist = [
    {
      category: 'h1_msh',
      name: '1時間雨量',
      maxZoomSize: 12,
      is_river: false,
    },
    {
      category: 'h24_msh',
      name: '24時間雨量',
      maxZoomSize: 12,
      is_river: false,
    }]

  // 全画面表示切り替え
  const fullscreenHandle = useFullScreenHandle()
  const switchingFullScreen = (e) => {
    fullscreenHandle.active ?
      fullscreenHandle.exit() :
      fullscreenHandle.enter();
  }
  // 全画面ボタンのスタイル
  const fullscreenButtonStyle = { width: '36px', height: '36px' }

  const [zoom, setZoom] = useState(6);
  const [flg, setFlg] = useState(false);
  const [chooseNum, setChooseNum] = useState(0)
  // ベクタータイル選択レイヤー名
  const [category, setCategory] = useState(vectorlist[0].category)

  // // 緯度軽度(大分)
  // const lat = 33.238067
  // const lon = 131.612506

  // 緯度軽度(東京)
  const lat = 36.0047000
  const lon = 137.5936000
  const position = [lat, lon];

  // 開始・終了日時からタイムスライダーの設定を取得する
  // 開始終了の差分取得
  const difference = props.endDateTime - props.startDateTime; // This will give difference in milliseconds

  // // 差分を分に換算
  // const resultInMinutes = Math.round(difference / (1 * 1000 * 60));
  // // 30分刻みでステップを設定する
  // const allCount = Math.round(resultInMinutes / 30);
  // const sliderMax = allCount * 5

  // 差分を時間数に換算
  const resultInHour = Math.round(difference / (1 * 1000 * 60 * 60));
  // 1時間刻みでステップを設定する
  const sliderMax = resultInHour


  return (
    <StyleFullScreen
      handle={fullscreenHandle}
      enabled={true}
    >
      <Box width="100%" height='calc(100vh - 48px)' position="relative">
        <StyleMapContainer
          center={position}
          zoom={zoom}
          scrollWheelZoom={true}
        >
          <UpdateMap
            mapCentre={position}
            setFromPosition={props.setFromPosition}
            setToPosition={props.setToPosition}
            zoom={zoom}
            setZoom={setZoom}
            flg={flg}
            setFlg={setFlg}
            tileDateForUrl={props.tileDateForUrl}
            chooseNum={chooseNum}
            />
          <CommonMap
            vectorlist={vectorlist}
            category={category}
            setCategory={setCategory}
            isChrUser={false}
            />
          <FullScreenIconButton onClick={switchingFullScreen}>
            {fullscreenHandle.active
              ? <FullscreenExit sx={fullscreenButtonStyle} />
              : <Fullscreen sx={fullscreenButtonStyle}
              />
            }
          </FullScreenIconButton>
          {props.fromPosition !== undefined && props.fromPosition !== null &&
            <Marker title='移動元' position={props.fromPosition} > </Marker>}
          {props.toPosition !== undefined && props.toPosition !== null &&
            <Marker title='移動先' position={props.toPosition} icon={RedIcon}> </Marker>}
        </StyleMapContainer>
        <MapTimeSlider
          chooseNum={chooseNum}
          updateChooseNum={setChooseNum}
          sliderMax={sliderMax}
          startDateTime={props.startDateTime}
          setTileDateForUrl={props.setTileDateForUrl}
          is_forecast={false}
          />
      </Box>
    </StyleFullScreen>
  );
}

export default SimulationCreateMap;
