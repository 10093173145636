import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

// user defined function
import { getCardColor } from '../../func/getcolor'

/**
 * 色付きのタイトル
 * @param {string} text タイトル
 * @param {string} colorclass カラークラス名 
 * @returns {JSX.Element}
 */
export default function ColorTitle(props){
  const { text, colorclass } = props
  return(
    <Wrapper colorclass={colorclass}>
      {text}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  background-color: ${(props) => getCardColor(props.colorclass)};
  margin: 0.5rem 0;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  border-radius: 5px;
  padding: 0.5rem 1rem;
`
