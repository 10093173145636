import React from 'react'
import {
    MenuList,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Skeleton,
} from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';
import HistoryIcon from '@mui/icons-material/History';

import { TypographySmallMargin } from '../../style/typography'
import { GetData } from '../../func/get_data_swr';

/**
 * アーカイブメニュー
 * @returns {JSX.Element}
 */
export default function ArchiveMenu() {

    const defaultMenu = () => (
        <>
            <MenuItem selected={true}>
                <ListItemIcon>
                    <HistoryIcon fontSize="medium" sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText sx={{ color: 'white', textAlign: 'left' }}>直近のアーカイブ</ListItemText>
            </MenuItem>
            <TypographySmallMargin>
                アーカイブされた災害
            </TypographySmallMargin>
        </>
    );
    const archiveNotFound = () => (
        <MenuItem sx={{ margin: '8px 16px' }}>
            <ListItemIcon>
                <ArchiveIcon fontSize='medium' sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'white', textAlign: 'left' }}>データがありません</ListItemText>
        </MenuItem>
    );

    // 災害アーカイブ一覧取得
    let url = `/arc/disaster/list/`
    const { data, loading, error } = GetData(url);

    if (loading) {
        return (
            <MenuList >
                {defaultMenu()}
                <MenuItem sx={{ margin: '8px 16px' }}>
                    <ListItemIcon>
                        <Skeleton variant="circular" width={20} height={20} sx={{ bgcolor: 'grey.900' }} />
                    </ListItemIcon>
                    <ListItemText ><Skeleton sx={{ bgcolor: 'grey.900' }}/></ListItemText>
                </MenuItem>
            </MenuList>
        )
    }

    if (error) {
        return (
            <MenuList >
                {defaultMenu()}
                {archiveNotFound()}
            </MenuList>
        )
    }

    const viewData = data.results
    return (
        <MenuList >
            {defaultMenu()}
            {viewData.length === 0 ?
                archiveNotFound()
                :
                viewData.map((e, i) =>
                    <MenuItem sx={{ margin: '8px 16px' }}>
                        <ListItemIcon>
                            <ArchiveIcon fontSize='medium' sx={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText sx={{ color: 'white', textAlign: 'left' }}>{e.disaster_name}</ListItemText>
                    </MenuItem>)}
        </MenuList>
    )
}

