import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

// user defined function
import { getCardColor } from '../../../func/getcolor'

// 引数の型を指定する
Card.propTypes = {
  // 子要素
  children: PropTypes.node,
  // カラークラス名
  colorclass: PropTypes.string,
  // ボタンか
  is_btn: PropTypes.bool,
  // 選択中か，デフォルトはfalse
  isChoosed: PropTypes.bool,
  // ボタンのときにクリックしたら状態を更新する,
  updateStateFunc: PropTypes.func,
  // 更新する状態の引数
  funcProps: PropTypes.array,
}

// styled-componentsでstyleを定義
const StyleCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  color: rgba(255, 255, 255, 1);
  background-color: ${(props) => getCardColor(props.colorclass)};
  margin: 0.5rem 0;
  filter: ${(props) => props.isChoosed && 'brightness(1.25)'};
  ${(props) => props.is_btn && css`
    &:hover {
      cursor: pointer;
      filter: brightness(1.25);
    }
  `}
`

/**
 * 雨量などを表示するカード風コンポーネント
 * @param {node} children 子要素
 * @param {string} colorclass カラークラス名
 * @param {bool} is_btn ボタンか否か
 * @param {bool} isChoosed 選択中か否か
 * @param {func} updateStateFunc ボタンクリック時に発火する関数
 * @param {array} funcProps 関数に与える引数
 * @returns 
 */
export default function Card(props){
  // 引数を取得
  const {children, colorclass, is_btn, isChoosed, updateStateFunc, funcProps} = props

  // 中にAtomsのコンポーネントが来るため，
  // 以下のような形とする
  return(
    <StyleCard
      className={'meter'}
      is_btn={is_btn}
      isChoosed={isChoosed ? true : false}
      colorclass={colorclass}
      onClick={() =>
        is_btn && updateStateFunc && funcProps ? updateStateFunc(funcProps) : undefined
      }
    >
      { children }
    </StyleCard>
  )
}

// デフォルトの引数を定義する
Card.defaultProps = {
  children: '',
  colorclass: '',
  is_btn: false
}
