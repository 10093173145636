import React, { useState, useEffect } from "react";
import {
  useMap,
  useMapEvents,
} from 'react-leaflet'
import * as protomapsL from "protomaps-leaflet";
import { config } from '../../../config.jsx'
import { BASE_LAYER_LIST } from '../../../const/general'
import { getVectorTileStyles } from '../../../func/getvectortilestyles'

/**
 * マップ用のイベントハンドラ
 * @param {func} updateLatlng 緯度経度を更新する関数
 * @param {func} updateCategories 選択中の種類を変更する関数
 * @param {func} updatePopupLatlng ポップアップの出す場所を変更する関数 
 * @returns null
 */
function MapEventHandler(props) {

  // タイルレイヤー格納用
  const [tileLayer, setTileLayer] = useState(null);

  // マップ定義
  const map = useMap();

  // マップイベント定義
  const mapEvents = useMapEvents({
    click: (e) => {
      props.updateLatlng([e.latlng.lat, e.latlng.lng])
    },
    baselayerchange: (e) => {
      let layerName = e.name
      props.setSeletedBaseLayer(BASE_LAYER_LIST.find(p => p.name === layerName))
    },
    zoomlevelschange: (e) => {
      tileUpdate()
    }
  })

  // スライダー移動による指定時間（choose_num）のみ着色
  const tileColor = (item) => {
    if (item.props.choose_num === props.chooseNum) {
      return getVectorTileStyles(
        item.props.sint_value_cls_num,
        props.seletedBaseLayer.category)
    } else {
      return "rgb(0, 0, 0, 0)"
    }
  }

  // 選択カテゴリによるタイルスタイルの指定
  const paintRule = () => {
    if (props.seletedBaseLayer.is_river) {
      return RIVER_RULE;
    }
    return RAIN_RULE;
  }

  // タイルスタイルのみ更新
  const tileUpdate = () => {
    if (tileLayer !== undefined && tileLayer !== null) {
      tileLayer.paintRules = paintRule()
      tileLayer.rerenderTiles()
    }
  }

  // 河川タイル用のスタイル定義
  const RIVER_RULE = [
    {
      dataLayer: props.seletedBaseLayer.category,
      symbolizer: new protomapsL.LineSymbolizer({
        color: (zoom, item) => {
          return tileColor(item)
        }
        , width: (zoom, item) => {
          return zoom / 5 * zoom / 4 * (item.props.sint_value_cls_num + 1) / 6
        }
        ,opacity:0.8
      }),
      minzoom: 0,
      maxzoom: 14,
    },
  ];

  // 雨量タイル用のスタイル定義
  const RAIN_RULE = [
    {
      dataLayer: props.seletedBaseLayer.category,
      symbolizer: new protomapsL.PolygonSymbolizer({
        fill: (zoom, item) => {
          return tileColor(item)
        },
        opacity: 0.65
      }),
      minzoom: 0,
      maxzoom: 14,
    },
  ];

  // ベースレイヤー切り替えによるカテゴリ変更、
  useEffect(() => {
    // 呼び出し元に応じてS3の参照キーを取得
    let str_key = 'forecast'
    if (props.isArchive) {
      str_key = `archive/${props.disasterId}/${props.checkpoint}`
    } else if (props.isSimulation) {
      str_key = `simulation/${props.simulationId}/${props.checkpoint}`
    }

    const layer = protomapsL.leafletLayer({
      url: `${config.pmtilesUrl}/${str_key}/${props.seletedBaseLayer.category}/{z}/{x}/{y}.mvt`,
      paintRules: paintRule()
    })
    layer.addTo(map)

    if (tileLayer !== undefined && tileLayer !== null) {
      map.removeLayer(tileLayer)
    }
    setTileLayer(layer)
  }, [props.seletedBaseLayer, props.dateArray])

  // スライダー移動による時間（choose_num）変更でタイルスタイルのみ変更
  useEffect(() => {
    tileUpdate()
  }, [props.chooseNum])

  // mapの中心を移動
  useEffect(() => {
    map.flyTo(props.latlng)
  }, [props.latlng])

  return null
}
export default MapEventHandler;