import React, { useState, useEffect } from 'react'
import axios from 'axios'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { config } from '../../config.jsx'

// Template components
import RiskDashboard from '../../templates/riskdashboard'

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * リスク分析画面
 * @returns {JSX.Element} リスク分析テンプレート
 */
export default function Meter() {
	// 現在選択している時間
	const [chooseDate, setChooseDate] = useState('')
	// 選択している時間のインデックス
	const [chooseNum, setChooseNum] = useState(null)
	// 選択している時間が予報時間か
	const [isForecast, setIsForecast] = useState(false)
	// 選択している緯度経度
	const [latlng, setLatlng] = useState(null)
	// 河川水位からのリンクのパラメータがある場合，そちらを初期値とする
	const { lat, lng } = useParams()
	// 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
	const { disaster_id, checkpoint } = useParams()
	// シミュレーションIDがあればパラメータから受け取る
	const { simulation_id } = useParams()
	// シミュレーションか，アーカイブ化かを判断
	const isArchive = disaster_id && checkpoint
	const isSimulation = simulation_id && checkpoint

	useEffect(() => {
		// エラーが表示されていたので変更しました。大塚
		// if(lat && lng && latlng !== [lat, lng]){
		if (lat && lng) {
			setLatlng([lat, lng])
		}
		else if (!lat && !lng) {
			setLatlng([lat_center, lng_center])
		}
	}, [lat, lng])

	// リクエストするURL
	const requestUrl = isSimulation ? `${config.restUrl}/sim/${simulation_id}/${checkpoint}/rain/slider/date/`
		: isArchive ? `${config.restUrl}/${disaster_id}/${checkpoint}/rain/slider/date/`
			: `${config.restUrl}/rain/slider/date/`

	const { data, error } = useSWR(
		() => requestUrl,
		fetcher,
		{
			onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
				// 再試行は5回まで
				if (retryCount >= 2) return
				// 5秒後に再試行
				setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 50000)
			},
			refreshInterval: 300000
		}
	)

	// データが取得できたかを確認する
	const isLoadingComplete = data && !error
	const dateArray = isLoadingComplete ? data.result : []
	const newestDate = isLoadingComplete ? data.newest_date : ''

	// データが更新されたときに，もともと選択していた時刻で更新を行う
	useEffect(() => {
		if (dateArray.length !== 0) {
			updateChooseNum(chooseNum === null || chooseNum < 0 ? dateArray.indexOf(newestDate) : chooseNum)
		}
	}, [dateArray, newestDate])

	// 選択された日付が変更されたときに呼び出す関数
	function updateChooseDate(props) {
		if (dateArray.length !== 0) {
			setChooseDate(props)
			setChooseNum(dateArray.indexOf(props))
			// 解析雨量で最新の時間と比較し，インデックスが大きければ予報フラグをtrueに
			if (dateArray.indexOf(props) > dateArray.indexOf(newestDate)) {
				setIsForecast(true)
			}
			else {
				setIsForecast(false)
			}
		}
	}

	// 選択された緯度経度が変更されたとき
	function updateLatlng(props) {
		setLatlng(props)
	}

	// 選択されたインデックスが変わったとき
	function updateChooseNum(props) {
		setChooseNum(props)
		setChooseDate(dateArray[props])

		// 解析雨量で最新の時間と比較し，インデックスが大きければ予報フラグをtrueに
		if (props > dateArray.indexOf(newestDate)) {
			setIsForecast(true)
		}
		else {
			setIsForecast(false)
		}
	}

	if(isLoadingComplete) return (
		<RiskDashboard
			latlng={latlng}
			chooseDate={chooseDate}
			chooseNum={chooseNum}
			newestDate={newestDate}
			dateArray={dateArray}
			isForecast={isForecast}
			updateLatlng={updateLatlng}
			updateChooseDate={updateChooseDate}
			updateChooseNum={updateChooseNum}
		/>
	)
}

// default center position
const lat_center = 33.2305526
const lng_center = 131.6068285


