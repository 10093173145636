import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'

import IconButton from '@mui/material/IconButton'

BtnIcon.propTypes = {
  is_btn: PropTypes.bool,
  label_text: PropTypes.string,
  onClickFunc: PropTypes.func,
  Children: PropTypes.node
}

const StyleBtn = styled.div`
  display: flex;
  margin: auto 0.25rem;
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, 0.75);
  opacity: ${(props) => props.is_btn ? '1' : '0.25'};

  ${(props) => props.is_btn && css`
    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.25);
    }
  `}
`

const StyleIconButton = styled(IconButton)`
  padding: 0px;
  color: white;
`

/**
 * アイコン風ボタン
 * @param {bool} is_btn ボタンか否か
 * @param {string} label_text ボタンのラベル
 * @param {func} onClickFunc ボタンクリック時に発火する関数
 * @param {node} Children 子要素コンポーネント
 * @returns {JSX.Element}
 */
export default function BtnIcon(props){
  const { IconComponent, is_btn, label_text, onClickFunc } = props

  return (
    <StyleBtn
      is_btn={is_btn}
    >
      <label htmlFor='icon-button-file'>
        <StyleIconButton
          aria-label={label_text}
          component='span'
          onClick={onClickFunc}
          disabled={!is_btn}
        >
          {IconComponent}
        </StyleIconButton>
      </label>
    </StyleBtn>
  )
}
