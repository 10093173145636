import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Stack } from "@mui/material";

import CancelSubmitButton from '../../items/cancel_submit'
import FormDatePickerStartEnd from '../../items/date_picker_start_end'
import { TypographySubtitle, TypographySmall, TypographyNomal } from '../../style/typography';
import { BoxFlexStart, BoxFlexEndMarginLR10 } from '../../style/box';
import getAddress from '../../func/get_address';

export default function SimulationCreateFrom(props) {
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');

  console.log(props.startDateTime)
  console.log(props.endDateTime)

  const { control, register, setValue, getValues, handleSubmit, reset, formState: { errors } } = useForm({ mode: 'onBlur' });

  // 移動元の住所取得
  useEffect(() => {
    async function fetchData() {
      const address = await getAddress(props.fromPosition.lat, props.fromPosition.lng)
      setFromAddress(address)
    }
    if(props.fromPosition !== undefined && props.fromPosition !== null){
      fetchData();
    }
}, [props.fromPosition])

    // 移動先の住所取得
    useEffect(() => {
      async function fetchData() {
        const address = await getAddress(props.toPosition.lat, props.toPosition.lng)
        setToAddress(address)
      }
      if(props.toPosition !== undefined && props.toPosition !== null){
        fetchData();
      }
    }, [props.toPosition])
  
  // 成功時の処理
  const onSuccess = async (response, proc, setSuccessMessage) => {
    // props.reload()
    // setSuccessMessage(`タグ：${response.data.tag_names}を${proc}しました`)
    // setStartDateError('')
    // reset()
    // setValue('tag_starting_date', null)
    // setValue('tag_ending_date', null)
  }

  // 失敗時の処理
  const onError = (err, proc, setErrorMessage) => {
    // if (err.response === undefined) {
    //   setErrorMessage(`タグの${proc}に失敗しました。`)
    // } else if (err.response.data.tag_names) {
    //   setErrorMessage('このタグ名は既に登録されています。')
    // } else if (err.response.data.tag_starting_date) {
    //   setErrorMessage('開始日を正しく設定してください。')
    // } else if (err.response.data.tag_ending_date) {
    //   setErrorMessage('終了日を正しく設定してください。')
    // } else {
    //   setErrorMessage(`タグの${proc}に失敗しました。`)
    // }
  }

  const onSubmit = data => {

  }

  // キャンセル動作
  const onCancel = () => {
    // props.setOpen(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1} m={5}>
          <TypographySubtitle>シュミレーション期間</TypographySubtitle>
          <FormDatePickerStartEnd
            simStartDatetime='sim_start_datetime'
            simEndDatetime='sim_end_datetime'
            control={control}
            startDateTime={props.startDateTime}
            endDateTime={props.endDateTime}
            startDateError={startDateError}
            endDateError={endDateError}
            setStartDateError={setStartDateError}
            setEndDateError={setEndDateError}
            setValue={setValue}
            getValues={getValues}
          />
          <BoxFlexEndMarginLR10>
            <Button
              variant="contained"
              type='button'
              sx={{
                width: '40%',
                color: 'white',
                backgroundColor: '#318ab7',
                '&:hover': {
                  backgroundColor: '#435e7a'
                }
              }}
            >プレビュー</Button>
          </BoxFlexEndMarginLR10>
        </Stack>
        <Stack spacing={1} m={5}>
          <TypographySubtitle>地図上から指定</TypographySubtitle>
          <TypographySmall>移動元</TypographySmall>
          <BoxFlexStart>
            <img src="https://esm.sh/leaflet@1.9.2/dist/images/marker-icon.png"
              width={25} height={41} />
            <Stack spacing={1} ml={3}>
              {props.fromPosition !== undefined && props.fromPosition !== null
                ?
                <>
                  <TypographyNomal>{fromAddress}</TypographyNomal>
                  <TypographyNomal>緯度：{props.fromPosition.lat}</TypographyNomal>
                  <TypographyNomal>経度：{props.fromPosition.lng}</TypographyNomal>
                </>
                :
                <TypographyNomal>指定されていません</TypographyNomal>
              }
            </Stack>
          </BoxFlexStart>
          <TypographySmall>移動先</TypographySmall>
          <BoxFlexStart>
            <img src="https://esm.sh/leaflet@1.9.2/dist/images/marker-icon.png"
              width={25} height={41} style={{ filter: 'hue-rotate(150deg)' }} />
            <Stack spacing={1} ml={3}>
              {props.toPosition !== undefined && props.toPosition !== null
                ?
                <>
                  <TypographyNomal>{toAddress}</TypographyNomal>
                  <TypographyNomal>緯度：{props.toPosition.lat}</TypographyNomal>
                  <TypographyNomal>経度：{props.toPosition.lng}</TypographyNomal>
                </>
                :
                <TypographyNomal>指定されていません</TypographyNomal>
              }
            </Stack>
          </BoxFlexStart>
        </Stack>
        <CancelSubmitButton
          onCancel={onCancel}
          submitButtonName='作成'
        />
      </form>
    </>
  )
}

