import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { useLocation } from 'react-router-dom'
import { config } from '../../config.jsx'

// Atomic Components
import DateClock from '../../atoms/dateclock'
import LinkBtn from '../../atoms/linkbtn'
import StatusBadge from '../../atoms/statusbadge'
import { HeaderBtnBox, ChoosedHeaderBtnBox } from '../../style/header'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    width: 96vw;
    padding: 0 2vw;
    border-bottom: thin ridge #15335f;
    justify-content: space-between;
    background-color: ${(props) => props.backColor};
`

const BtnWrapper = styled.div`
    display: flex;
`

// メニューボタンリスト
const btnArray = [
    {
        category: 'top',
        text: 'トップ',
        url: '/',
        isOpen: true,
    },
    {
        category: 'risk',
        text: 'リスク分析',
        url: '/risk',
        isOpen: true,
    },
    {
        category: 'river',
        text: '水位分析',
        url: '/river',
        isOpen: true,
    },
    {
        category: 'telmap',
        text: '水位地図',
        url: '/telmap',
        isOpen: true,
    },
    {
        category: 'msriv',
        text: '水位変化',
        url: '/msriv',
        isOpen: true,
    },
    {
        category: 'shelter',
        text: '避難所',
        url: '/shelter',
        isOpen: false,
    },
    {
        category: 'shelmap',
        text: '避難所地図',
        url: '/shelmap',
        isOpen: false,
    },
    {
        category: 'evac',
        text: '避難情報',
        url: '/evac',
        isOpen: false,
    },
    {
        category: 'archive',
        text: 'アーカイブ',
        url: '/archive',
        isOpen: true,
    },
    {
        category: 'simulation',
        text: 'シミュレーション',
        url: '/simulation',
        isOpen: true,
    },
    {
        category: 'login',
        text: 'ログアウト',
        url: '/login',
        isOpen: true,
    }
]

const fetcher = url => axios.get(url).then(res => res.data)

// 大分県領域 funcType : prefにアクセスできるIDリスト
const ACCESSIBLE_ID = config['accessibleId']

/**
 * ヘッダー
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @param {bool} isSimulation シミュレーションか否か
 * @param {string} simulationId シミュレーションID
 * @returns {JSX.Element}
 */
export default function RiskHeader({ disasterId = '', checkpoint = '', isArchive = false, simulationId = '', isSimulation = false }) {
    const location = useLocation()

    // ユーザー情報取得
    const userName = JSON.parse(localStorage.getItem('user'))
    const isPrefUser = ACCESSIBLE_ID.includes(userName['id'])

    const { data, error } = useSWR(
        () => isPrefUser &&
            `${config.restUrl}/pref/is/disaster/respond/`,
        fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCound }) => {
                // 再試行は5回まで
                if (retryCound >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCound + 1 }), 50000)
            },
            refreshInterval: 300000
        }
    )

    const isLoading = !data && !error

    if (isSimulation) {
        const simulationHeaderList = ['top', 'risk', 'simulation', 'login']
        return (
            <Wrapper backColor={'#c00000cc'}>
                <BtnWrapper>
                    {
                        btnArray.map((elem, i) => (
                            ((isPrefUser || elem.isOpen) && simulationHeaderList.includes(elem.category)) &&
                            <LinkBtn
                                text={elem.text === 'シミュレーション' ? 'シミュレーション終了' : elem.text}
                                url={elem.category === 'simulation' ? `${elem.url}/${simulationId}`
                                    : elem.category === 'top' ? `/simulation/${simulationId}/${checkpoint}`
                                        : elem.category === 'login' ? `${elem.url}`
                                            : `/sim${elem.url}/${simulationId}/${checkpoint}`}
                                isChoosed={
                                    elem.category === 'simulation' ? elem.url === location.pathname
                                        : elem.category === 'top' ? location.pathname === `/simulation/${simulationId}/${checkpoint}`
                                            : location.pathname === `/sim${elem.url}/${simulationId}/${checkpoint}`}
                                choosedBackColod={'#f56161cc'}
                            />
                        ))
                    }
                </BtnWrapper>
                {
                    !isLoading && <StatusBadge
                        text={'シミュレーションモード'}
                        color={'white'}
                        size={'20px'}
                    />
                }
                <DateClock />
            </Wrapper>
        )
    }

    if (isArchive) {
        const archiveHeaderList = ['top', 'risk', 'river', 'telmap', 'msriv', 'shelter', 'shelmap', 'evac', 'archive', 'logout']
        return (
            <Wrapper backColor={'#c00000cc'}>
                <BtnWrapper>
                    {
                        btnArray.map((elem, i) => (
                            ((isPrefUser || elem.isOpen) && archiveHeaderList.includes(elem.category)) &&
                            <LinkBtn
                                text={elem.text === 'アーカイブ' ? 'アーカイブ終了' : elem.text}
                                url={elem.category === 'archive' ? `${elem.url}`
                                    : elem.category === 'top' ? `/archive/${disasterId}/${checkpoint}`
                                        : elem.category === 'login' ? `${elem.url}`
                                            : `${elem.url}/${disasterId}/${checkpoint}`}
                                isChoosed={
                                    elem.category === 'archive' ? elem.url === location.pathname
                                        : elem.category === 'top' ? location.pathname === `/archive/${disasterId}/${checkpoint}`
                                            : location.pathname === `${elem.url}/${disasterId}/${checkpoint}`}
                                choosedBackColod={'#f56161cc'}
                            />
                        ))
                    }
                </BtnWrapper>
                {
                    !isLoading && <StatusBadge
                        text={'アーカイブモード'}
                        color={'white'}
                        size={'20px'}
                    />
                }
                <DateClock />
            </Wrapper>
        )
    }

    if (isLoading || error) {
        return (
            <Wrapper backColor={'#061027'}>
                <BtnWrapper>
                    {
                        btnArray.map((elem, i) => (
                        (isPrefUser || elem.isOpen) &&
                            <LinkBtn
                                key={i.toString()}
                                text={elem.text}
                                url={elem.url}
                                isChoosed={elem.url === location.pathname}
                                choosedBackColod={'#223C6D'}
                            />
                        ))
                    }
                </BtnWrapper>
                <DateClock />
            </Wrapper>
        )
    }

    const isDisasterRespound = data.results

    return (
        <Wrapper
            backColor={isDisasterRespound ? '#c00000cc' : '#061027'}
        >
            <BtnWrapper>
                {
                    btnArray.map((elem, i) => {
                        if ((isPrefUser || elem.isOpen)) {
                            if (elem.category !== 'archive') {
                                return (
                                    <LinkBtn
                                        key={i.toString()}
                                        text={elem.text}
                                        url={elem.url}
                                        isChoosed={elem.url === location.pathname}
                                        choosedBackColod={isDisasterRespound ? '#f56161cc' : '#223C6D'}
                                    />)
                            } else {

                                if (elem.url === location.pathname) {
                                    return (
                                        <ChoosedHeaderBtnBox >
                                            <a href='https://risk.prein.jp/from_edison/archive' target='_blank'>{elem.text}</a>
                                        </ChoosedHeaderBtnBox>
                                    )
                                } else {
                                    return (
                                        <HeaderBtnBox >
                                            <a href='https://risk.prein.jp/from_edison/archive' target='_blank'>{elem.text}</a>
                                        </HeaderBtnBox>
                                    )
                                }
                            }
                        }
                    })
                }
            </BtnWrapper>
            {
                !isLoading && <StatusBadge
                    text={isDisasterRespound ? '災害対応中' : '平常運用中'}
                    color={'white'}
                    size={'20px'}
                />
            }
            <DateClock />
        </Wrapper>
    )
}
