import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import EvacMap from '../../contents/evacmap'

/**
 * 避難所テンプレート
 * @returns {JSX.Element}
 */
export default function EvacDashboard(){
    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const {disaster_id, checkpoint} = useParams()

    if(disaster_id && checkpoint){
        return(
            <React.Fragment>
                <RiskHeader 
                    disasterId={disaster_id}
                    checkpoint={checkpoint}
                    isArchive={true}
                />
                <ContentsWrapper>
                    <EvacMap 
                        disasterId={disaster_id}
                        checkpoint={checkpoint}
                        isArchive={true}
                    />
                </ContentsWrapper>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <RiskHeader />
            <ContentsWrapper>
                <EvacMap />
            </ContentsWrapper>
        </React.Fragment>
    )
}

const ContentsWrapper = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 48px);
`
