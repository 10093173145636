import React from 'react'
import styled from 'styled-components'
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom'

// コンポーネント
import { PrivateRoute } from '../_basicauth/_component/Privateroute'
import Login from '../pages/Login'
import Top from '../pages/Top'
import Meter from '../pages/Meter'
import River from '../pages/River'
import MeterSlider from '../pages/MeterSlider'
import NotFound from '../pages/NotFound'
import TelMap from '../pages/TelMap'
// アーカイブ
import Archive from '../pages/Archive'
// シミュレーション

// モバイル
import MobileMeter from '../mobile/pages/Meter'
import MobileTelMap from '../mobile/pages/TelMap'


// 大分県データページコンポーネント
import Shelter from '../pages/Shelter'
import ShelterMap from '../pages/ShelterMap'
import Evac from '../pages/Evac'
import Simulation from '../pages/Simulation'
import SimulationCreate from '../pages/SimulationCreate'

const Wrapper = styled.div`
    text-align: center;
    background-color: #061027;
    width: 100vw;
    height: 100vh;
    position: fixed;
`

function Apps(){
    return(
        <Wrapper>
            <div className="col-sm-8 col-sm-offset-2">
                <Router>
                    <div>
                        <Routes>
                            {/* シミュレーション機能 */}
                            <Route exact path='/simulation/:simulation_id/:checkpoint' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/simulation/:simulation_id/:checkpoint' element={ <Top /> } />
                            </Route>
                            <Route exact path='/simulation' element={ <PrivateRoute funcType={'open'} />}>
                                <Route exact path='/simulation' element={ <Simulation />} />
                            </Route>
                            <Route exact path='/simulation/create' element={ <PrivateRoute funcType={'open'} />}>
                                <Route exact path='/simulation/create' element={ <SimulationCreate />} />
                            </Route>
                            <Route exact path='/simulation/:simulation_id/' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/simulation/:simulation_id/' element={ <Simulation />} />
                            </Route>
                            <Route exact path='/sim/risk/:lat/:lng/:simulation_id/:checkpoint' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/sim/risk/:lat/:lng/:simulation_id/:checkpoint' element={ <Meter /> } />
                            </Route>
                            <Route exact path='/sim/risk/:simulation_id/:checkpoint' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/sim/risk/:simulation_id/:checkpoint' element={ <Meter /> } />
                            </Route>
                            {/* シミュレーション機能 */}

                            {/* アーカイブ機能 */}
                            <Route exact path='/archive/:disaster_id/:checkpoint' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/archive/:disaster_id/:checkpoint' element={ <Top /> } />
                            </Route>
                            <Route exact path='/archive' element={ <PrivateRoute funcType={'open'} />}>
                                <Route exact path='/archive' element={ <Archive />} />
                            </Route>
                            <Route exact path='/archive/:disaster_id/' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/archive/:disaster_id/' element={ <Archive />} />
                            </Route>
                            <Route exact path='/risk/:lat/:lng/:disaster_id/:checkpoint' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/risk/:lat/:lng/:disaster_id/:checkpoint' element={ <Meter /> } />
                            </Route>
                            <Route exact path='/risk/:disaster_id/:checkpoint' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/risk/:disaster_id/:checkpoint' element={ <Meter /> } />
                            </Route>
                            <Route exact path='/river/:disaster_id/:checkpoint' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/river/:disaster_id/:checkpoint' element={ <River /> } />
                            </Route>
                            <Route exact path='/telmap/:disaster_id/:checkpoint' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/telmap/:disaster_id/:checkpoint' element={ <TelMap /> } />
                            </Route>
                            <Route exact path='/msriv/:disaster_id/:checkpoint' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/msriv/:disaster_id/:checkpoint' element={ <MeterSlider /> } />
                            </Route>
                            <Route exact path='/shelter/:disaster_id/:checkpoint' element={ < PrivateRoute funcType={'pref'} /> }>
                                <Route exact path='/shelter/:disaster_id/:checkpoint' element={ <Shelter /> } />
                            </Route>
                            <Route exact path='/shelmap/:disaster_id/:checkpoint' element={ < PrivateRoute funcType={'pref'} /> }>
                                <Route exact path='/shelmap/:disaster_id/:checkpoint' element={ <ShelterMap /> } />
                            </Route>
                            <Route exact path='/evac/:disaster_id/:checkpoint' element={ <PrivateRoute funcType={'pref'} />}>
                                <Route exact path='/evac/:disaster_id/:checkpoint' element={ <Evac />} />
                            </Route>
                            {/* アーカイブ機能 */}

                            {/* モバイル機能 */}
                            <Route exact path='/m/' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/m/' element={ <MobileMeter /> } />
                            </Route>
                            <Route exact path='/m/telmap' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/m/telmap' element={ <MobileTelMap /> } />
                            </Route>
                            {/* モバイル機能 */}

                            <Route exact path='/' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/' element={ <Top /> } />
                            </Route>
                            <Route exact path='/risk/latlng/:lat/:lng' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/risk/latlng/:lat/:lng' element={ <Meter /> } />
                            </Route>
                            <Route exact path='/risk' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/risk' element={ <Meter /> } />
                            </Route>
                            <Route exact path='/river' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/river' element={ <River /> } />
                            </Route>
                            <Route exact path='/telmap' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/telmap' element={ <TelMap /> } />
                            </Route>
                            <Route exact path='/msriv' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/msriv' element={ <MeterSlider /> } />
                            </Route>
                            <Route exact path='/shelter' element={ < PrivateRoute funcType={'pref'} /> }>
                                <Route exact path='/shelter' element={ <Shelter /> } />
                            </Route>
                            <Route exact path='/shelmap' element={ < PrivateRoute funcType={'pref'} /> }>
                                <Route exact path='/shelmap' element={ <ShelterMap /> } />
                            </Route>
                            <Route exact path='/evac' element={ <PrivateRoute funcType={'pref'} />}>
                                <Route exact path='/evac' element={ <Evac />} />
                            </Route>
                            <Route path='/login' element={ <Login /> } />
                            <Route path='*' element={ <NotFound /> } />
                        </Routes>
                    </div>
                </Router>
            </div>
        </Wrapper>
    )
}

export default Apps;
