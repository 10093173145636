import React, { useState } from 'react'

// Template components
import TelMapDashboard from '../../templates/telmapdashboard'

/**
 * 河川水位計マップ画面
 * @returns {JSX.Element} 河川水位マップテンプレート
 */
export default function TelMap(){
    // 選択している時間
    const [chooseDate, setChooseDate] = useState(null)
    // 選択しているテレメータの水系
    const [chooseBsnCode, setChooseBsnCode] = useState(890918)

    // スライダー変更時，時間を更新
    function updateChooseDate(props){
        setChooseDate(props)
    }

    return(
        <TelMapDashboard
            chooseCode={chooseBsnCode}
            chooseDate={chooseDate}
            updateChooseDate={updateChooseDate}
        />
    )
}
