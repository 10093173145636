import React from 'react';

import {
  Marker,
  TileLayer,
  LayersControl,
  LayerGroup,
} from 'react-leaflet'
import Control from 'react-leaflet-custom-control';

import { svgCircleIcon } from '../../atoms/svgcircleicon'
import MapLegend from '../../items/maplegend/index.jsx'
import { getMapLegendData } from '../../func/getmaplegenddata.jsx'
import { BASE_LAYER_LIST } from '../../const/general'

const CommonMap = (props) => {

  return (
    <>
      <Control position="topright" />
      <TileLayer
        attribution='出典：<a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>'
        url='https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png'
        className='baseLayers'
      />
      <LayersControl>
        {BASE_LAYER_LIST.map((elem) =>
          <LayersControl.BaseLayer
            name={elem.name}
            checked={props.seletedBaseLayer.category === elem.category ? true : false}
          >
            <LayerGroup></LayerGroup>
          </LayersControl.BaseLayer>
        )}
        <LayersControl.Overlay name="土砂災害警戒区域等">
          <LayerGroup>
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/05_dosekiryukeikaikuiki/{z}/{x}/{y}.png"
              className='addLayers'
            />
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/05_kyukeishakeikaikuiki/{z}/{x}/{y}.png"
              className='addLayers'
            />
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/05_jisuberikeikaikuiki/{z}/{x}/{y}.png"
              className='addLayers'
            />
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/05_dosekiryukikenkeiryu/{z}/{x}/{y}.png"
              className='addLayers'
            />
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/05_kyukeisyachihoukai/{z}/{x}/{y}.png"
              className='addLayers'
            />
            <TileLayer
              url="https://disaportaldata.gsi.go.jp/raster/05_jisuberikikenkasyo/{z}/{x}/{y}.png"
              className='addLayers'
            />
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name="洪水浸水想定区域(想定最大規模)">
          <TileLayer
            url="https://disaportaldata.gsi.go.jp/raster/01_flood_l2_shinsuishin_data/{z}/{x}/{y}.png"
            className='addLayers'
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="津波浸水想定">
          <TileLayer
            url="https://disaportaldata.gsi.go.jp/raster/04_tsunami_newlegend_data/{z}/{x}/{y}.png"
            className='addLayers'
          />
        </LayersControl.Overlay>
        {props.isChrUser &&
          <LayersControl.Overlay name="クロノロジー" checked="true">
            <LayerGroup>
              {
                props.isData && props.isChrUser && props.chrData.map((e, i) => (
                  <Marker
                    key={`marker-chr-${i}`}
                    position={[e.latitude, e.longitude]}
                    icon={svgCircleIcon('#ff2600')}
                  />
                ))
              }
            </LayerGroup>
          </LayersControl.Overlay>
        }
      </LayersControl>
      <MapLegend {...getMapLegendData(props.seletedBaseLayer.category)} isChrUser={props.isChrUser} />
    </>
  );
}

export default CommonMap;
