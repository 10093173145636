import React from 'react'
import styled, { keyframes } from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'
import { HoverChip } from '../../style/chip.js'
import { BoxFlexStart } from '../../style/box.js'

import ErrorMsg from '../../atoms/errormsg/index.jsx'
import { 
    dateToStrYYYYMMDD, 
    dateToStrHHMM, 
    dateToStrYYYYMMDDHHmmss,
    descTimeSort 
} from '../../func/datetoview.jsx'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
  } from '@mui/lab/TimelineOppositeContent';

import TimelineDot from '@mui/lab/TimelineDot';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { Link } from 'react-router-dom'

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * アーカイブした災害の一覧
 * @returns {JSX.Element}
 */
export default function ArchiveTimeLine() {
    // アーカイブリストを取得する
    const { data, error } = useSWR(
        () => `${config.restUrl}/arc/recent/list/`,
        fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は5回まで
                if (retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 50000)
            },
            refreshInterval: 300000
        }
    )

    const isLoading = !data && !error
    // Loading
    if (isLoading) {
        return (
            <Wrapper>
                {/* <TitleWrapper>直近のアーカイブ一覧</TitleWrapper> */}
                {/* {[...Array(5)].map((e, i) =>
                    <DisasterWrapper key={`disaster-list-wrapper-${i}`}>
                        <DisasterHead key={`disaster-list-head-${i}`}>
                            <DisasterIcons key={`disaster-list-icon-${i}`} />
                            <DisasterPrefName key={`disaster-list-pref-name-${i}`}>都道府県名</DisasterPrefName>
                        </DisasterHead>
                        <LoadingDisasterTitle key={`disaster-list-title-${i}`}>読み込み中...</LoadingDisasterTitle>
                        <DisasterDate key={`disaster-list-period-${i}`}>期間：</DisasterDate>
                        <DisasterDate key={`disaster-list-update-date-${i}`}>最終更新日：</DisasterDate>
                    </DisasterWrapper>
                )} */}
            </Wrapper>
        )
    }
    else if (error) {
        return (
            <Wrapper>
                <ErrorMsg msg={'データがありませんでした'} />
            </Wrapper>
        )
    }

    // 表示するアーカイブのフィルタ
    const viewData = data.results

    if (viewData.length === 0) {
        return (
            <Wrapper>
                <ErrorMsg msg={'データがありませんでした'} />
            </Wrapper>
        )
    }
      
    function recentList() {
        let objList = []
        let obj = {}
        let array = []
        for (let i = 0; i < viewData.length; i++) {
            const checkpoint_org = viewData[i].tsm_checkpoint_date
            const checkpoint = dateToStrYYYYMMDDHHmmss(viewData[i].tsm_checkpoint_date)
            const str_checkpoint_date = dateToStrYYYYMMDD(viewData[i].tsm_checkpoint_date)
            const str_checkpoint_time = dateToStrHHMM(viewData[i].tsm_checkpoint_date)

            if (i === 0) {
                array.push({'checkpoint': checkpoint, 'checkpoint_time': str_checkpoint_time })
                obj = { 'checkpoint_org':checkpoint_org, 'checkpoint_date': str_checkpoint_date, 'time_list': array }
            }

            if (i !== 0 &&
                str_checkpoint_date !== dateToStrYYYYMMDD(viewData[i - 1].tsm_checkpoint_date)) {
                // 1番目の要素ではなく、日付が変わった場合
                obj.time_list = array
                objList.push(obj)

                obj = {}
                array = []
                array.push({ 'checkpoint': checkpoint, 'checkpoint_time': str_checkpoint_time })
                obj = { 'checkpoint_org':checkpoint_org, 'checkpoint_date': str_checkpoint_date, 'time_list': array }
            }
            if (i !== 0
                && str_checkpoint_date === dateToStrYYYYMMDD(viewData[i - 1].tsm_checkpoint_date)) {
                // 1番目の要素ではなく、日付が変わっていない場合
                array.push({ 'checkpoint': checkpoint, 'checkpoint_time': str_checkpoint_time })
            }
            if (i === viewData.length - 1) {
                obj.time_list = array
                objList.push(obj)
            }
        }
        const sortedObjList =objList.sort((a, b) => descTimeSort(a, b))
        return sortedObjList
    }
    return (
        <Timeline sx={{ overflowY: 'auto'}}>
            {recentList().map((e, i) => (
                <>
                    <TimelineItem 
                          sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                              flex: 0.3,
                            },
                          }}
                          >
                        <TimelineOppositeContent
                            sx={{ m: '27px auto 0 0', minHeight:'50px' }}
                            color="white">
                            {e.checkpoint_date}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector  />
                            <TimelineDot color="primary" sx={{margin:'6px 0px'}}>
                                <CalendarTodayIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent
                            sx={{ m: '25px auto 0 0'}}>
                            <BoxFlexStart>
                                {e.time_list.map((f) => (
                                    <Link to={`/archive/recent/${f.checkpoint}`}>
                                        <HoverChip label={f.checkpoint_time} />
                                    </Link>
                                ))}
                            </BoxFlexStart>
                        </TimelineContent>
                    </TimelineItem>
                </>
            ))}
        </Timeline>
    )
}

// 災害一覧ラッパー ///////////////////////////////////////////////////
const LoadingAnimation = keyframes`
    to {
        clip-path: inset(0 -0.5ch 0 0)
    }
`
const Wrapper = styled.div`
    margin: 5vh 20vw;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`
