import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet'
import 'leaflet.vectorgrid'

// original function
// 各ベクタータイルの種類にあったスタイルを取得
import { getTelVectorStyle } from '../../../func/getvectortilestyles'
import { config } from '../../../config.jsx'

TelVector.propTypes = {
  // 選択している時間
  chooseDate: PropTypes.number,
  // 表示するベクタータイルのキー
  category: PropTypes.string,
  // 河川のタイルか？ デザインが違うため
  is_river: PropTypes.bool,
  // ベクタータイルの最大ズーム数
  maxZoomSize: PropTypes.number,
}

/**
 * 河川水位を示すベクタータイル
 * @param {*} props 
 * @returns 
 */
export default function TelVector(props){
  const { chooseDate, category, maxZoomSize, is_river } = props
  const { layerContainer, map } = useLeafletContext()
  let options = {
    className: 'rainlayers',
    rendererFactory: L.canvas.tile,
    maxNativeZoom: maxZoomSize,
    detectRetina: true,
    vectorTileLayerStyles: {
      [category]: function(properties, zoom){
        let value = properties.sint_value_cls_num
        let fillColor = ''
        let fillOption = true
        if(chooseDate === undefined){
          return{
            fill: false,
            fillOpacity: 0,
            weight: 0
          }
        }
        else if(chooseDate === properties.bint_jst_num){
          fillColor = getTelVectorStyle(value)
        }
        else{
          return{
            fill: false,
            fillOpacity: 0,
            weight: 0,
            opacity: 0
          }
        }
        return is_river ?
        {
          stroke: true,
          fillOpacity: 1,
          fill: true,
          color: fillColor,
          opacity: 0.8,
          weight: 6,
        }
        : {
          illColor: fillColor,
          fillOpacity: 0.65,
          fill: fillOption,
          weight: 0
        }
      },
      interactive: true
    }
  }

  // const url = urlHead + '{z}/{x}/{y}.pbf'
  const url = `${config.pmtilesUrl}/static/tel_riv/{z}/{x}/{y}.mvt`
  const vectorGrid = L.vectorGrid.protobuf(url, options)
  const container = layerContainer || map

  const onUpdate = (e) => {
    setTimeout(
      () => {
        container.removeLayer(vectorGrid)
      },
      50
    )
  }

  useEffect(() => {
    container.addLayer(vectorGrid)
    return() => {
      onUpdate()
    }
  }, [chooseDate])

  return null

}
