// {/*
//   ユーザーがログインしている場合はルートコンポーネントをレンダリング
//   そうでない場合はログインページにリダイレクト
// */}

import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { config } from '../../config.jsx'

// 大分県領域 funcType : prefにアクセスできるIDリスト
const ACCESSIBLE_ID = config['accessibleId']

export const PrivateRoute = ({ component: Component, ...rest }) => {
  // ユーザー情報取得
  const userName = JSON.parse(localStorage.getItem('user'))
  if(userName){
    const userId = userName['id']
    // コンポーネントの公開タイプを確認する
    const funcType = rest['funcType']
    if(funcType === 'pref' && !ACCESSIBLE_ID.includes(userId)){
      return(<Navigate to={{pathname: '/404'}} />)
    }
    return(<Outlet />)
  }
  return(<Navigate to={{pathname: '/login'}} />)
}

// export const PrivateRoute = ({ component: Component, ...rest}) => (
//   <Route {...rest} render ={props => (
//     localStorage.getItem('user')
//       ? <Component {...props} />
//       : <Navigate to={{pathname: '/login', state: { from: props.location} }} />
//   )} />
// )
