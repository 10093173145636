import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { styled as muiStyled } from '@mui/system'

PullDown.propTypes = {
    // メニューリスト
    menuArray: PropTypes.array,
    // 選択された値
    menu: PropTypes.string,
    // 選択された値が変更されたときの更新関数
    updateMenu: PropTypes.func,
}

const StyleSelect = styled(Select)`
    font-weight: bold !important;
    font-size: 18px !important;
    color: white !important;
    .MuiInputBase-root{
        background-color: #152451;
        width: 100%;
    },
    .MuiPopover-paper{
        background-color: #152451;
    },
    .MuiInput-root{
        width: 100%;
    }
    .MuiInput-underline{
        &:after{
        border-bottom: 2px solid #318AB7;
        }
    }
    .MuiSelect-select{
        padding: 0.35rem;
    }
`

const StyleMenuItem = styled(MenuItem)`
    background-color: #152451 !important;
    font-weight: bold !important;
    color: white !important;
    &:hover{
        background-color: #223C6D !important;
    }
`

// メニューの設定
const menuProps = {
  anchorOrigin: {
    vertical: 'buttom'
  },
  disablePortal: true
}

// メニュー本来のデザインを変更
const useStyles = muiStyled(theme => ({
  root:{
    color: 'white',
    width: '100%',
    fontSize: '18px',
    marginLeft: '0.25rem'
  },
  icon: {
    color: '#FFFFFF',
  }
}))

/**
 * プルダウン
 * @param {array} menuArray メニューリスト
 * @param {string} menu 選択された値
 * @param {func} updateMenu プルダウンの値が変更されたときに状態を更新する関数
 * @returns {JSX.Element}
 */
export default function PullDown(props){
  const {menuArray, menu, updateMenu} = props
  const classes = useStyles()

  // プルダウンが変更されたときに発火する関数
  const handleChange = (event) => {
    updateMenu(event.target.value)
  }

  return(
    <StyleSelect
      className={classes.select}
      classes={{
        root: classes.root,
        icon: classes.icon
      }}
      MenuProps={menuProps}
      value={menu}
      name='index'
      inputProps={{ 'aria-label' : 'age' }}
      onChange={handleChange}
    >
      {menuArray.map((elem, i) =>
        <StyleMenuItem value={elem.menu}>{elem.text}</StyleMenuItem>
      )}
    </StyleSelect>
  )
}
