/**
 * グラフの線を取得する
 * @param {array} lists 値のリスト
 * @param {array} label ラベル
 * @param {string} category 種類名
 * @param {number} maxValue 最大値
 * @param {string} unit 単位
 * @param {string} maxLabels 最大値のラベル名
 * @returns 
 */
export const getGraphLine = (lists, label, category, maxValue=null, unit='', maxLabels='') => {
    if(!lists) return []
    const linelists = []
    const telColor = ['#2d8457', '#b7b122', '#c02518', '#880e58', '#0a0412']
    const rainColor = ['#b7b122', '#b5711b', '#c02518', '#880e58']
    const JMAColor = ['#b7b122', '#c02518', '#880e58']
    const maxColor = '#0a0412'
    
    if(!category){
        for(let i=0; i<lists.length; i++){
            if(lists[i]){
                linelists.push(
                    {
                        target: lists[i],
                        color: telColor[i],
                        label: label ? label[i] : lists[i],
                        styles: 'left',
                    }
                )
            }
        }
        return linelists
    }
    else if(category === 'sr_msh'){
        for(let i=0; i<lists.length; i++){
            if(i === lists.length - 1){
                if(lists[i]){
                    linelists.push(
                        {
                            target: lists[i],
                            color: maxColor,
                            label: maxLabels,
                            styles: 'right',
                        }
                    )
                }
            }
            else{
                linelists.push(
                    {
                        target: lists[i],
                        color: JMAColor[i],
                        label: label ? label[i] : lists[i],
                        styles: 'left',
                    }
                )
            }
        }
        return linelists
    }
    else if(category.endsWith('msh') || category.endsWith('bsn')){
        for(let i=0; i<lists.length; i++){
            linelists.push(
                {
                    target: lists[i],
                    color: rainColor[i],
                    label: label ? label[i] + unit : lists[i] + unit,
                    styles: 'left',
                }
            )
        }
        if(maxValue){
            linelists.push(
                {
                    target: maxValue,
                    color: maxColor,
                    label: maxLabels,
                    styles: 'right',
                }
            )
        }
        return linelists
    }
    else{
        for(let i=0; i<lists.length; i++){
            if(lists[i]){
                linelists.push(
                    {
                        target: lists[i],
                        color: telColor[i],
                        label: label ? label[i] : lists[i],
                        styles: 'left',
                    }
                )
            }
        }
        return linelists
    }
}
