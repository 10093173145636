import React from 'react'
import PropTypes from 'prop-types'
import { GetData } from '../../func/get_data_swr';

// Item Component
import AreaGraph from '../../items/areagraph'
import MtrInfo from '../../items/mtrinfo'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// user defined function
import { getTickList } from '../../func/getticklist'
import { getGraphLine } from '../../func/getgraphline'

ShelterGraph.propTypes = {
    // 選択中の避難所名
    shelterName : PropTypes.string,
    // 選択中の避難所タイプ
    shetlerType : PropTypes.string,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
    // 市町村コード
    ctvCode: PropTypes.number,
    // 避難所ID
    shelterCode: PropTypes.string,
}

/**
 * 避難所グラフ
 * @param {string} shelterName 避難所名
 * @param {string} shelterType 避難所種類
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @returns {JSX.Element}
 */
export default function ShelterGraph(props){
    const { shelterName, shelterType, disasterId, checkpoint, isArchive, ctvCode, shelterCode } = props

    // アーカイブか否かで呼び出すAPIを分ける
    let url = `/pref/shelter/graph/${shelterCode}/${ctvCode}/`
    if (isArchive) {
        url = `/${disasterId}/${checkpoint}/pref/shelter/graph/${shelterCode}/${ctvCode}/`
    }
    const { data, loading, error} = GetData(url);
    
    if(loading){
        return(<React.Fragment />)
    }
    if(error){
        return(<ErrorMsg text={'データが見つかりませんでした'} />)
    }

    // グラフの設定
    const mtrBorder = [
        null, null, null, data.shelter_capacity_num, null
    ]
    const mtrLabel = [
        null, null, null, '最大収容可能人数', null
    ]

    const results = data.results
    // ドメインとグラフのライン設定
    const domains = getTickList(results, mtrBorder, false)
    const graphLines = getGraphLine(mtrBorder)

    return(
        <React.Fragment>
            <MtrInfo
                mtrName={data.shelter_name}
                basName={data.city_name}
                riverName={data.community_name}
                mtrDetail={data.shelter_info}
                mtrLat={data.latitude}
                mtrLng={data.longitude}
                mtrAddress={data.address}
                mtrDiffFlag={data.change_flag}
                mtrValue={data.hdcnt_num}
                colorclass={data.colorclass}
                mtrBorder={mtrBorder}
                mtrUnit={'人'}
                mtrLabel={mtrLabel}
                mtrViewer={`${data.hdcnt_num}人 - ${data.hhs_num}世帯`}
                isEmptyMsg={data.sort_name === '閉鎖' ? '閉鎖' : '避難者なし'}
            />
            <AreaGraph
                data={results}
                domains={domains}
                unit={'人'}
                mejer={'mejer'}
                xaxisKeyList={['date']}
                dataKey={'value'}
                lines={graphLines}
                lineWidth={4}
                category={'tel'}
                borderArray={mtrBorder}
            />
        </React.Fragment>
    )
}
