import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { TextField, Alert, AlertTitle } from '@mui/material'
import { styled as muiStyled } from '@mui/system'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'



// user defined function
import login from '../../_basicauth/_services/user.service.jsx'

/**
 * ログイン
 * @returns {JSX.Element}
 */
function LoginFailedMessages(){
  return(
    <MsgWrapper>
      <StyleAlert
        variant='outlined'
        severity='error'
        style={{
          width: '50vw'
        }}
      >
        <StyleAlertTitle>ログインエラー</StyleAlertTitle>
        ユーザー名またはパスワードに誤りがあります。
      </StyleAlert>
    </MsgWrapper>
  )
}

export default function Login(){
  // ローカルストレージを削除する
  localStorage.removeItem('user')

  // 入力されるメールアドレス(ユーザー名)の状態
  const [username, setUsername] = useState('')
  // 入力されるパスワードの状態
  const [password, setPassword] = useState('')
  // サブミット状態
  const [submitted, setSubmitted] = useState(false)
  // 認証中状態
  const [loading, setLoading] = useState(false)
  // エラーの状態
  const [error, setError] = useState('')

  const navigate = useNavigate()
  const classes = useStyles()

  // スマホ判定
  const isMobile = () => {
    const ua = window.navigator.userAgent.toLowerCase()
    const mobileua = /iphone;|(android|nokia|blackberry|bb10;).+mobile|android.+fennec|opera.+mobi|windows phone|symbianos/
    return mobileua.test(ua)
  }

  const { register, handleSubmit, watch, errors } = useForm()
  // 認証
  const onSubmit = () => {
    setSubmitted(true)
    setLoading(true)
    login(username, password)
      .then(
        user => {
          !isMobile() ? navigate('/') : navigate('/m/');
        },
        error => {
          setError('error')
          setLoading(false)
          setSubmitted(false)
        }
      )
  }

  if(loading){
    return(
      <React.Fragment>
        <TitleWrapper>ログイン中. . .</TitleWrapper>
      </React.Fragment>
    )
  }

  return(
    <React.Fragment>
      <TitleWrapper>リスク分析ログインページ</TitleWrapper>
      { error === 'error' && <LoginFailedMessages /> }
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <StyleTextField
          id='username'
          variant='standard'
          label='ユーザー名'
          type='text'
          onChange={(e) => setUsername(e.target.value)}
          className={classes.textField}
          InputProps={{ className: classes.textField }}
          InputLabelProps={{ className: classes.textField }}
        />
        <StyleTextField
          id='password'
          variant='standard'
          label='パスワード'
          type='password'
          onChange={(e) => setPassword(e.target.value)}
        />
        <InputStyle value='ログイン' type='submit' />
      </FormWrapper>
    </React.Fragment>
  )
}

const TitleWrapper = styled.div`
  color: white;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 3rem;

  @media screen and (max-width: 768px){
    font-size: 24px;
  
  }
`
const MsgWrapper = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  margin-bottom: 2rem;
  text-align: left;
`
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin-right: 25vw;
  margin-left: 25vw;
  & input{
    color: white !important;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active{
      transition: background-color 5000s ease-in-out 0s !important;
      -webkit-text-fill-color: white !important;
    }
  }
  &>label{
    color: white !important;
    margin: 0.5rem 0;
    font-size 24px;
    top: calc(-16px - 0.5rem);
  }
`
const InputStyle = styled.input`
  background: #223c6d;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  border-radius: 4px;
  &:hover{
    background: #318AB7;
    cursor: pointer;
  }
`
const useStyles = muiStyled(theme => ({
  root:{
    color: 'white',
    width: '100%',
    fontSize: '18px',
    marginLeft: '0.25rem'
  }
}))
const StyleTextField = styled(TextField)`
  margin: 1rem 0 !important;
  .MuiInputBase-root{
    width: 100%;
  }
  .MuiInput-formControl{
    margin-top: 16px;
  }
  .MuiFormLabel-root{
    color: #318AB7;
    .Mui-focused{
      color: #318AB7;
    }
  }
  .MuiInput-underline{
    &:after{
      border-bottom: 2px solid #318AB7;
    }
  }
`
const StyleAlert = styled(Alert)`
  .MuiAlert-message{
    font-size: 20px;
    color: #f4c7c7;
    div{
      font-size: 24px;
      font-weight: bold;
      color: #ef5350;
    }
  },
  .MuiAlert-icon{
    font-size: 30px;
    color: #ef5350;
  }
`
const StyleAlertTitle = styled(AlertTitle)`
  .MuiAlertTitle-root{
    font-size: 24px;
  }
`
