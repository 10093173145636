import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { useLocation, useNavigate } from 'react-router-dom'
import { config } from '../../../config.jsx'

// Atomic Components
import DateClock from '../../atoms/dateclock'
import LinkBtn from '../../atoms/linkbtn'
import StatusBadge from '../../atoms/statusbadge'

// MUI
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import Drawer from '@mui/material/Drawer'
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    width: 96vw;
    padding: 0 2vw;
    justify-content: space-between;
    background-color: ${(props) => props.backColor};
`
const BtnWrapper = styled.div`
    display: flex;
`

// メニューボタンリスト
const btnArray = [
    {
        category: 'top',
        text: 'リスク分析',
        url: '/m/',
        isOpen: true,
    },
    // {
    //     category: 'risk',
    //     text: 'リスク分析',
    //     url: '/risk',
    //     isOpen: true,
    // },
    // {
    //     category: 'river',
    //     text: '水位分析',
    //     url: '/river',
    //     isOpen: true,
    // },
    {
        category: 'telmap',
        text: '水位地図',
        url: '/m/telmap',
        isOpen: true,
    },
    // {
    //     category: 'msriv',
    //     text: '水位変化',
    //     url: '/msriv',
    //     isOpen: true,
    // },
    // {
    //     category: 'shelter',
    //     text: '避難所',
    //     url: '/shelter',
    //     isOpen: false,
    // },
    // {
    //     category: 'shelmap',
    //     text: '避難所地図',
    //     url: '/shelmap',
    //     isOpen: false,
    // },
    // {
    //     category: 'evac',
    //     text: '避難情報',
    //     url: '/evac',
    //     isOpen: false,
    // },
    // {
    //     category: 'archive',
    //     text: 'アーカイブ',
    //     url: '/archive',
    //     isOpen: true,
    // },
    // {
    //     category: 'simulation',
    //     text: 'シミュレーション',
    //     url: '/simulation',
    //     isOpen: true,
    // },
    {
        category: 'login',
        text: 'ログアウト',
        url: '/login',
        isOpen: true,
    },
    {
        category: 'close',
        text: 'メニューを閉じる',
        url: '',
        isOpen: true,
    }
]

const fetcher = url => axios.get(url).then(res => res.data)

// 大分県領域 funcType : prefにアクセスできるIDリスト
const ACCESSIBLE_ID = config['accessibleId']

/**
 * ヘッダー
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @param {bool} isSimulation シミュレーションか否か
 * @param {string} simulationId シミュレーションID
 * @returns {JSX.Element}
 */
export default function RiskHeader({disasterId='', checkpoint='', isArchive=false, simulationId='', isSimulation=false}){
    const location = useLocation()

    // メニュー
    const navigate = useNavigate()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const handleClick = (url) => { navigate(url) }

    // ユーザー情報取得
    const userName = JSON.parse(localStorage.getItem('user'))
    const isPrefUser = ACCESSIBLE_ID.includes(userName['id'])

    const { data, error } = useSWR(
        () => isPrefUser &&
            `${config.restUrl}/pref/is/disaster/respond/`,
            fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCound }) => {
                // 再試行は5回まで
                if(retryCound >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCound + 1}), 50000)
            },
            refreshInterval: 300000
        }
    )
    
    const isLoading = !data && !error

    return(
        <AppBar position="static" sx={{bgcolor: '#061027' }}>
            <Toolbar variant="dense">
                <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => setIsMenuOpen(true) }>
                    <MenuIcon />
                </IconButton>
            </Toolbar>

            <Drawer anchor="left" open={isMenuOpen} sx={{
                '& .MuiDrawer-paper': {
                    backgroundColor: '#061027',
                    color: '#fff',
                }
            }}>
                <List>
                    {
                        btnArray.map((elem, i) => {
                            return (
                                (isPrefUser || elem.isOpen) &&
                                <ListItem key={i.toString()} onClick={ () => elem.url !== '' ? handleClick(elem.url) : setIsMenuOpen(false) }>
                                    <ListItemButton>
                                        <ListItemText primary={elem.text} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Drawer>
        </AppBar>
    )
}
