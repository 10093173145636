import React, { useEffect } from "react";
import { TypographyTitle } from "../../style/typography";
import styled from "styled-components";
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../../config.jsx'


const fetcher = url => axios.get(url).then(res => res.data)

const TelmapDatetimeWrapper = styled.div`
    position: absolute;
    left: auto;
    right: auto;
    bottom: 0;
    width: 100%;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(6, 16, 39, 0.67);
    z-index: 1000;
`

export default function TelmapDatetime(props) {
    const {
        bsnCode,
        updateChooseDate,
    } = props

    const { data, error } = useSWR(
        () => `${config.restUrl}/wtl/slider/date/${bsnCode}/`,
            fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は5回まで
                if(retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
            },
            refreshInterval: 300000
        }
    )

    // データが取得できたかを確認する
    // ローディングフラグ
    const isLoading = !data && !error
    // データ取得フラグ
    const isLoadingComplete = data && !error
    // 最新の更新時間
    const newestDate = isLoadingComplete ? data.newest_date : ''
    // データリスト
    const dataArray = isLoadingComplete ? data.results : []

    useEffect(() => {
        if (newestDate !== undefined) {
            updateChooseDate(newestDate)
        }
    }, [newestDate])

    if(isLoading){
        return(
            <TelmapDatetimeWrapper>
                <TypographyTitle>読み込み中</TypographyTitle>
            </TelmapDatetimeWrapper>
        )
    }
    if(error){
        return(
            <TelmapDatetimeWrapper>
                <TypographyTitle>データが見つかりませんでした</TypographyTitle>
            </TelmapDatetimeWrapper>
        )
    }
    return (
        <TelmapDatetimeWrapper>
            <TypographyTitle>{dataArray[0]['date']} {dataArray[0]['time']} 実測</TypographyTitle>
        </TelmapDatetimeWrapper>
    )
}