import React, { useState } from "react";
import {
	Grid,
} from "@mui/material";
import RiskHeader from '../../contents/riskheader'
import SimulationCreateFrom from '../../contents/simulation_create_form'
import SimulationCreateMap from '../../contents/simulation_create_map'
import { dateToStrYYYYMMDDHHmmss } from '../../func/datetoview.jsx'

/**
 * シミュレーション画面
 * @returns {JSX.Element} シミュレーションテンプレート
 */
export default function SimulationCreate() {

	const new_date = new Date();
	let m = new_date.getMinutes()
	if(m >= 30) {
		m=30
	}else{
		m=0
	}
	let end_date = new_date.setDate(5);
	end_date = new_date.setHours(0);
	end_date = new_date.setMinutes(0);
	end_date = new_date.setSeconds(0);
	const start_date = (new_date.setDate(new_date.getDate() - 1))

	const [startDateTime, setStartDateTime] = useState(start_date);
	const [endDateTime, setEndDateTime] = useState(end_date);
	const [tileDateForUrl, setTileDateForUrl] = useState(dateToStrYYYYMMDDHHmmss(start_date));

	const [fromPosition, setFromPosition] = useState(null);
	const [toPosition, setToPosition] = useState(null);

	return (
		<React.Fragment>
			<RiskHeader />
			<Grid container >
				<Grid item xs={3} >
					<SimulationCreateFrom
						startDateTime={startDateTime}
						endDateTime={endDateTime}
						setStartDateTime={setStartDateTime}
						setEndDateTime={setEndDateTime}
						fromPosition={fromPosition}
						toPosition={toPosition}
					/>
				</Grid>
				<Grid item xs={9}>
					<SimulationCreateMap
						setFromPosition={setFromPosition}
						setToPosition={setToPosition}
						setTileDateForUrl={setTileDateForUrl}
						tileDateForUrl={tileDateForUrl}
						fromPosition={fromPosition}
						toPosition={toPosition}
						startDateTime={startDateTime}
						endDateTime={endDateTime}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}