import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../../config.jsx'

// Contents Component
import RiverGraph from '../rivergraph'
import RiverMap from '../rivermap'

TelMapView.propTypes = {
    // 選択している日付
    chooseDate: PropTypes.number,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント時間
    checkpoint: PropTypes.string,
    // アーカイブか否か
    isArchive: PropTypes.bool,
}

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 河川水位マップを構成するコンポーネント
 * @param {number} chooseDate 選択した日付
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か 
 * @returns {JSX.Element}
 */
export default function TelMapView(props){
    const {
        chooseDate,
        disasterId='',
        checkpoint='',
        isArchive=false,
        updateChooseMtr
    } = props

    const { data, error } = useSWR(
        () =>
            isArchive ? `${config.restUrl}/${disasterId}/${checkpoint}/wtl/map/`
            : `${config.restUrl}/${chooseDate}/wtl/map/`,
            fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は2回まで
                if(retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
            },
            refreshInterval: 300000
        }
    )

    const isLoading = !data && !error
    const isData = !isLoading && !error
    const pointsData = isData && data.results.filter(elem => elem.bints === chooseDate)

    return(
        <React.Fragment>
            <ListWrapper>
                <RiverMap
                    chooseDate={chooseDate}
                    pointsData={pointsData}
                    firstZoomLebel={10}
                    vectorlist={vectorlist}
                    updateChooseMtr={updateChooseMtr}
                />
            </ListWrapper>
        </React.Fragment>
    )

}

const vectorlist = [
    {
        category: 'h1_msh',
        name: '1時間雨量',
        maxZoomSize: 12,
        is_river: false,
    },
    {
        category: 'h24_msh',
        name: '24時間雨量',
        maxZoomSize: 12,
        is_river: false,
    },
    {
        category: 'h48_msh',
        name: '48時間雨量',
        maxZoomSize: 12,
        is_river: false,
    },
    {
        category: 'sr_msh',
        name: '土壌雨量指数',
        maxZoomSize: 12,
        is_river: false,
    },
    {
        category: 'rsk_msh',
        name: '土砂災害発生リスク',
        maxZoomSize: 12,
        is_river: false,
    }
]

// マップラッパー
const ListWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: 100%;
`

// グラフラッパー
const GraphWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding-right: 5vw;
    padding-left: 1rem;
    margin: 0.5rem 0;
    height: auto;
    overflow-y: scroll;
`
