import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import MeterSlider from '../../contents/meterslider'
import TelMapView from '../../contents/telmapview'
import TelmapFooter from '../../contents/telmapfooter'
import TelmapDatetime from '../../items/telmapdatetime'
import RiverGraph from '../../contents/rivergraph'

TelMapDashboard.ropTypes = {
    // 選択しているコード(固定)
    chooseCode: PropTypes.number,
    // 選択している時間
    chooseDate: PropTypes.number,
    // 選択している時間を更新する関数
    updateChooseDate: PropTypes.func,
}

const AppWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: calc(100svh - 48px - 56px);
    flex-direction: column;
`

const ContentsWrapper = styled.div`
    display: flex;
    overflow-y: hidden;
    height: 100%;
    position: relative;
`

/**
 * 河川水位マップテンプレート
 * @param {number} chooseCode 選択しているコード
 * @param {number} chooseDate 選択している時間(インデックス値)
 * @param {func} updateChooseDate 選択している時間を更新する関数
 * @returns {JSX.Element}
 */
export default function TelMapDashboard(props){
    const {chooseCode, chooseDate, updateChooseDate} = props
    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const {disaster_id, checkpoint} = useParams()
    // ドロワー開閉ステータス
    const [bottomNavValue, setBottomNavValue] = useState('map')
    const [isTelmapGraphDrawerOpen, setIsTelmapGraphDrawerOpen] = useState(false)
    // ドロワー開閉操作
    const displayNavigationDrawer = (navValue) => {
        setBottomNavValue(navValue)
        switch (navValue) {
            case 'map':
                setIsTelmapGraphDrawerOpen(false)
                break
            case 'graph':
                setIsTelmapGraphDrawerOpen(true)
                break
            default:
                break
        }
    }

    // 水位計関連
    // 選択中の水位計のID
    const [ mtrCode, setMtrCode ] = useState(1126500016)
    // 選択中の水位計の種類
    const [ mtrCategory, setMtrCategory ] = useState('tel')
    // 選択中の推計情報アップデート
    function updateChooseMtr(code, category){
        setMtrCode(code)
        setMtrCategory(category)
        displayNavigationDrawer('graph')
    }

    if(disaster_id && checkpoint){
        return(
            <React.Fragment>
                <RiskHeader 
                    disasterId={disaster_id}
                    checkpoint={checkpoint}
                    isArchive={true}
                />
                <AppWrapper>
                    <MeterSlider
                        bsnCode={props.chooseCode}
                        updateChooseDate={props.updateChooseDate}
                        disasterId={disaster_id}
                        checkpoint={checkpoint}
                        isArchive={true}
                    />
                    <ContentsWrapper>
                    <TelMapView
                        chooseDate={Number(chooseDate)}
                        disasterId={disaster_id}
                        checkpoint={checkpoint}
                        isArchive={true}
                    />
                    </ContentsWrapper>
                </AppWrapper>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <RiskHeader />
            <AppWrapper>
                <ContentsWrapper>
                    <TelMapView
                        chooseDate={Number(chooseDate)}
                        updateChooseMtr={updateChooseMtr}
                    />
                    <TelmapDatetime
                        bsnCode={props.chooseCode}
                        updateChooseDate={props.updateChooseDate}
                    />
                    <RiverGraph
                        mtrCode={mtrCode}
                        mtrCategory={mtrCategory}
                        isTelmapGraphDrawerOpen={isTelmapGraphDrawerOpen}
                        displayNavigationDrawer={displayNavigationDrawer}
                    />
                </ContentsWrapper>
            </AppWrapper>
            <TelmapFooter
                displayNavigationDrawer={displayNavigationDrawer}
            />
        </React.Fragment>
    )
}
