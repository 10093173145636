import React, { useState, useEffect } from "react";
import { interval } from 'rxjs';
import { IconButton } from '@mui/material';
import {
  PlayArrow,
  Stop,
  ChevronRight,
  ChevronLeft,
} from '@mui/icons-material'

import {
  BoxMapSlider,
  MapSlider,
  StackMapSlider
} from '../../style/map_components'
import { TypographyTitle } from '../../style/typography'
import { dateToYYYYMMDDHHMM, dateToStrYYYYMMDDHHmmss, dateToMMDDHHmm } from '../../../func/datetoview.jsx'

export default function MapTimeSlider(props) {

  const [isPlay, setIsPlay] = useState(false)
  const [isForward, setIsForward] = useState(false)
  const [isBack, setIsBack] = useState(false)
  const [sliderDateTime, setSliderDateTime] = useState('')
  const [marks, setMarks] = useState([])

  // スライダーの更新間隔は1秒
  const intervals = 1 * 1000

  // アイコンボタンのスタイル
  const ableButtonStyle = { color: '#fff', width: '2.5rem', height: '2.5rem' }
  const disableButtonStyle = { color: '#666', width: '2.5rem', height: '2.5rem' }

  // スライダー表示のスタイル
  const sliderForecastStyle = () => {
    if (props.is_forecast) {
      return { backgroundColor: '#1f6fa9c4' }
    }
  }

  // 再生・停止
  const handlePlayPause = () => {
    setIsPlay(!isPlay);
  }

  // タイル取得用日付文字列の変更
  const setTileDate = (value) => {
    let start_date = props.startDateTime
    if (typeof props.startDateTime !== 'number') {
      start_date = props.startDateTime.getTime()
    }
    // 実績値の場合30分刻
    let add_value = value * 30 * 60 * 1000
    let str_forecast = ''
    if (props.is_forecast) {
      // 予報の場合１時間刻(実績分も１時間刻みで足し込まれてしまうので差し引く)
      add_value = (value * 60 * 60 * 1000) - (12 * 30 * 60 * 1000)
      str_forecast = '　予報'
    }
    const currentDateTime = start_date + add_value
    const current = new Date(currentDateTime)
    // スライダー上部のツールチップに日付を表示
    setSliderDateTime(`${dateToMMDDHHmm(current)}${str_forecast}`)
    //props.setTileDateForUrl(dateToStrYYYYMMDDHHmmss(current))
  }

  const handleChange = (e) => {
    props.updateChooseNum(e.target.value)
  }

  const handleForward = () => {
    props.updateChooseNum(props.chooseNum + 1);
  }

  const handleBack = () => {
    props.updateChooseNum(props.chooseNum - 1);
  }

  // データが更新された時
  useEffect(() => {
    setTileDate(props.chooseNum)
  }, [props.dateArray])

  // 再生中の挙動
  useEffect(() => {
    if (isPlay) {
      const timeoutId = interval(intervals).subscribe(() => {
        if (props.chooseNum < props.sliderMax) {
          props.updateChooseNum(props.chooseNum + 1);
          setTileDate(props.chooseNum + 1)
        } else {
          props.updateChooseNum(0)
          setTileDate(0)
        }
      })
      return () => {
        timeoutId.unsubscribe()
      }
    }
  }, [isPlay, props.chooseNum])

  // スライダーの位置が動いたときの挙動
  useEffect(() => {
    setTileDate(props.chooseNum)
    // 左右ボタンが端に行っていた場合、誤操作を防ぐために
    if (!isBack && (props.chooseNum !== 0)) setIsBack(true)
    else if (isBack && (props.chooseNum === 0)) setIsBack(false)
    if (!isForward && (props.chooseNum !== props.sliderMax)) setIsForward(true)
    else if (isForward && (props.chooseNum === props.sliderMax)) setIsForward(false)
  }, [props.chooseNum])

  // マーカー設定
  useEffect(() => {
    let mark_array = []
    props.dateArray.map((item, i) => {
      if (item === props.newestDate) {
        mark_array.push({
          value: i,
          label: '現在',
        })
      } else {
        mark_array.push({
          value: i,
          label: '',
        })
      }
    })
    setMarks(mark_array)
  }, [])

  return (
    <StackMapSlider sx={sliderForecastStyle}>
      <TypographyTitle>{sliderDateTime}</TypographyTitle>
      <BoxMapSlider >
        <IconButton sx={{ padding: '0 5px' }} disabled={!isBack} onClick={handleBack}>
          <ChevronLeft sx={isBack ? ableButtonStyle : disableButtonStyle} />
        </IconButton>
        <IconButton sx={{ padding: '0 5px' }} onClick={handlePlayPause}>
          {isPlay ?
            <Stop sx={ableButtonStyle} />
            :
            <PlayArrow sx={ableButtonStyle} />
          }
        </IconButton>
        <MapSlider
          value={props.chooseNum}
          aria-label="Default"
          valueLabelDisplay="off"
          min={0}
          max={props.sliderMax}
          step={1}
          onChange={handleChange}
          marks={marks}
        />
        <IconButton sx={{ padding: '0 5px' }} disabled={!isForward} onClick={handleForward}>
          <ChevronRight sx={isForward ? ableButtonStyle : disableButtonStyle} />
        </IconButton>
      </BoxMapSlider>
    </StackMapSlider>
  );
}
