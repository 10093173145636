import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// atom components
import LinkText from '../../atoms/linktext'
import ChangeIcon from '../../atoms/changeicon'
import BorderBand from '../../atoms/borderband'

// user defined function
import { get_colorclass_from_class, getCardColor } from '../../../func/getcolor'

MtrInfo.propTypes = {
    // メーター名
    mtrName: PropTypes.string,
    // メーターのカテゴリ名
    basName: PropTypes.string,
    // メーターの詳細カテゴリ名
    riverName: PropTypes.string,
    // メーター情報
    mtrDetail : PropTypes.string,
    // メーター設置緯度
    mtrLat : PropTypes.number,
    // メーター設置経度
    mtrLng : PropTypes.number,
    // メーター設置住所
    mtrAddress : PropTypes.string,
    // メーター変化フラグ
    mtrDiffFlag : PropTypes.number,
    // メーター値
    mtrValue : PropTypes.number,
    // メーターに応じた色クラス番号
    colorclass : PropTypes.number,
    // メーターの基準線
    mtrBorder : PropTypes.array,
    // メーターの単位
    mtrUnit : PropTypes.string,
    // メーターの表示文言
    mtrLabel : PropTypes.array,
    // メーターの表示機能(特別ケース時)
    mtrViewer : PropTypes.string,
    // 値が無いときにボードに表示する文言
    isEmptyMsg : PropTypes.string,
}


/**
 * 河川水位の情報表示
 * @param {string} mtrName 水位計名
 * @param {string} basName 水位計の水系名
 * @param {string} riverName 水位計の河川名
 * @param {string} mtrDetail 水位計情報
 * @param {number} mtrLat 水位計設置緯度
 * @param {number} mtrLng 水位計設置経度
 * @param {string} mtrAddress 水位計設置住所
 * @param {number} mtrDiffFlag 水位変化フラグ
 * @param {number} mtrValue 水位
 * @param {number} colorclass 水位に応じた色クラス番号 
 * @param {array} mtrBorder 水位の基準線
 * @param {string} mtrUnit 水位の単位
 * @param {array} mtrLabel 水位の表示文言リスト
 * @param {string} mtrView 水位の表示機能
 * @param {string} isEmptyMsg 値がないときに表示する文言
 * @returns {JSX.Element}
 */
export default function MtrInfo(props){
    const {
        mtrName,
        basName,
        riverName,
        mtrDetail,
        mtrLat,
        mtrLng,
        mtrAddress,
        mtrDiffFlag,
        mtrValue,
        colorclass,
        mtrBorder,
        mtrUnit,
        mtrLabel,
        mtrViewer,
        isEmptyMsg
    } = props

    return(
        <Wrapper colors={get_colorclass_from_class(colorclass)}>
            <Head>
                <HeadName>
                    <Title>{mtrName}</Title>
                    <RiverName>{basName} - {riverName}</RiverName>
                    { mtrDetail ? <RiverName>{mtrDetail}</RiverName> : null }
                    {/* <LinkText
                        url={`/risk/latlng/${mtrLat}/${mtrLng}`}
                        target={'_blank'}
                        text={'雨量を確認'}
                    /> */}
                </HeadName>
            </Head>
            <DetailWrapper>{mtrAddress}</DetailWrapper>
            <MtrWrapper>
                <ChangeIcon flag={mtrDiffFlag} />
                {
                    mtrDiffFlag === 3 || !mtrValue ? <ValueWrapper>{isEmptyMsg}</ValueWrapper>
                    : mtrViewer ? <ValueWrapper>{mtrViewer}</ValueWrapper>
                    : <ValueWrapper>{mtrValue} {mtrUnit}</ValueWrapper>
                }
            </MtrWrapper>
            <BorderBand
                borderArray={mtrBorder}　
                borderUnit={mtrUnit}
                borderLabel={mtrLabel}
            />
        </Wrapper>
    )
}


const Wrapper = styled.div`
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;
    background-color: ${(props) => getCardColor(props.colors)};
    height: 200px;
`
const Head = styled.div`
    // display: flex;
    margin: 0.5rem 1rem 0 1rem;
    // justify-content: space-between;
}
`
const HeadName = styled.div`
    display: block;
`
const Title= styled.div`
    font-size: 18px;
    color: white;
    font-weight: bold;
    width: 100%;
`
const RiverName = styled.div`
    font-size: 12px;
    color: white;
    display: inline-block;

    & + &::before {
        content: '|';
        margin: 0 0.5rem;
        display: inline-block;
    }
`
const DetailWrapper = styled.div`
    font-size: 14px;
    color: white;
    text-align: left;
    margin: 0.5rem 1rem;
`
const MtrWrapper = styled.div`
    display: flex;
    justify-content: center;
    color: white;
    font-size: 18px;
    align-items: center;
    margin: 0.5rem 0;
`
const ValueWrapper = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin: 0 1.5rem;
`
