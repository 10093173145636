import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { Link } from 'react-router-dom'
import { config } from '../../config.jsx'
import { dateToYYYYMMDDHHMM, dateToYYYYMMDDHHMM4DashboardParam } from '../../func/datetoview.jsx'
import { get_colorclass} from '../../func/getcolor'

// Item component
import Meter from '../../items/meter'
import LoadingMeter from '../../items/loadingmeter'
import ErrorMsg from '../../atoms/errormsg'

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 作成したチェックポイント一覧
 * @param {string} disasterId 災害ID
 * @param {string} simulationId シミュレーションID 
 * @returns {JSX.Element}
 */
export default function CheckpointList({disasterId='', simulationId=''}){
    // チェックポイントリストを取得する
    const {data, error} = useSWR(
        () => disasterId !== ''  ? `${config.restUrl}/arc/disaster/list/${disasterId}/`
                : `${config.restUrl}/sim/simulation/list/${simulationId}/`,
        fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は5回まで
                if(retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
            },
            refreshInterval: 300000
        }
    )

    const isLoading = !data && !error

    if(isLoading){
        return(
            <Wrapper>
                <TitleWrapper>チェックポイントデータ一覧</TitleWrapper>
                {[...Array(3)].map((e, i) => 
                    <ContentsWrapper key={`checkpoint-list-wrapper-${i}`}>
                        <LoadingCheckpointWrapper key={`checkpoint-date-${i}`}>0000年00月00日 00:00 ~ 0000年00月00日 00:00</LoadingCheckpointWrapper>
                        <MeterWrapper key={`checkpoint-meter-wrapper-${i}`}>
                            {[...Array(3)].map((f, j) => 
                                <MeterRowWrapper key={`cp-meter-row-${i}-${j}`}>
                                    <CityNameWrapper key={`cp-city-name-${i}-${j}`}>読み込み中</CityNameWrapper>
                                    {[...Array(4)].map((g, k) => <LoadingMeter key={`loading-three-${i}-${j}-${k}`} />)}
                                </MeterRowWrapper>
                            )}
                        </MeterWrapper>
                    </ContentsWrapper>
                )}
            </Wrapper>
        )
    }
    
    else if(error || (disasterId === '' && simulationId === '')){
        return(
            <Wrapper>
                <TitleWrapper>チェックポイントデータ一覧</TitleWrapper>
                <ErrorMsg msg={'データがありませんでした'} />
            </Wrapper>
        )
    }

    // 雨量が取得できていないデータは非表示にする
    const viewData = data.results.filter(elem => elem.max_values !== false)
    const titleName = disasterId !== '' ? data.disaster_name : simulationId !== '' ? data.simulation_name : ''
    const dashboardLinkSim00000005 = {
        '2020-07-02-12:00': 'https://cerd-edison.jp10.hcs.cloud.sap/sap/fpa/ui/tenants/968da/app.html#/analyticapp?shellMode=embed&/aa/17D0710688AAFF594AAFA52536039F3D?p_time=2020-07-02-18:00',
        '2020-07-02-18:00': 'https://cerd-edison.jp10.hcs.cloud.sap/sap/fpa/ui/tenants/968da/app.html#/analyticapp?shellMode=embed&/aa/17D0710688AAFF594AAFA52536039F3D?p_time=2020-07-03-00:00',
        '2020-07-03-00:00': 'https://cerd-edison.jp10.hcs.cloud.sap/sap/fpa/ui/tenants/968da/app.html#/analyticapp?shellMode=embed&/aa/17D0710688AAFF594AAFA52536039F3D?p_time=2020-07-03-06:00',
        '2020-07-03-06:00': 'https://cerd-edison.jp10.hcs.cloud.sap/sap/fpa/ui/tenants/968da/app.html#/analyticapp?shellMode=embed&/aa/17D0710688AAFF594AAFA52536039F3D?p_time=2020-07-03-12:00',
        '2020-07-03-12:00': 'https://cerd-edison.jp10.hcs.cloud.sap/sap/fpa/ui/tenants/968da/app.html#/analyticapp?shellMode=embed&/aa/17D0710688AAFF594AAFA52536039F3D?p_time=2020-07-03-18:00',
        '2020-07-03-18:00': 'https://cerd-edison.jp10.hcs.cloud.sap/sap/fpa/ui/tenants/968da/app.html#/analyticapp?shellMode=embed&/aa/17D0710688AAFF594AAFA52536039F3D?p_time=2020-07-04-00:00',
        '2020-07-04-00:00': 'https://cerd-edison.jp10.hcs.cloud.sap/sap/fpa/ui/tenants/968da/app.html#/analyticapp?shellMode=embed&/aa/17D0710688AAFF594AAFA52536039F3D?p_time=2020-07-04-06:00',
        '2020-07-04-06:00': 'https://cerd-edison.jp10.hcs.cloud.sap/sap/fpa/ui/tenants/968da/app.html#/analyticapp?shellMode=embed&/aa/17D0710688AAFF594AAFA52536039F3D?p_time=2020-07-04-12:00'
    }
    return(
        <Wrapper>
            <TitleWrapper>{titleName} - {data.prf_name}</TitleWrapper>
            {viewData.map((e, i) => 
                <ContentsWrapper key={`checkpoint-list-wrapper-${i}`}>
                    <Link to={disasterId !== '' ? `/archive/${disasterId}/${e.checkpoint}` : `/simulation/${simulationId}/${e.checkpoint}`}>
                        <CheckpointWrapper key={`checkpoint-date-${i}`}>{dateToYYYYMMDDHHMM(e.start_date)} ~ {dateToYYYYMMDDHHMM(e.end_date)}</CheckpointWrapper>
                    </Link>
                    {
                        simulationId == 'sim00000005' &&
                        dateToYYYYMMDDHHMM4DashboardParam(e.start_date) in dashboardLinkSim00000005 &&
                        <a href={dashboardLinkSim00000005[dateToYYYYMMDDHHMM4DashboardParam(e.start_date)]} target="_blank"><DashboardLinkWrapper>ダッシュボード</DashboardLinkWrapper></a>
                    }
                    <MeterWrapper key={`checkpoint-meter-wrapper-${i}`}>
                        { e.max_values.map((f, j) => 
                            <MeterRowWrapper key={`cp-meter-row-${i}-${j}`}>
                                <CityNameWrapper key={`cp-city-name-${i}-${j}`}>{f.ctv_name}</CityNameWrapper>
                                <Meter
                                    key={`cp-h1-${i}-${j}`}
                                    headtitle={'1時間雨量'}
                                    value={f.h1}
                                    colorclass={get_colorclass(f.h1, 'h1')}
                                    is_btn={false}
                                    unit={'mm/h'}
                                />
                                <Meter
                                    key={`cp-h24-${i}-${j}`}
                                    headtitle={'24時間雨量'}
                                    value={f.h24}
                                    colorclass={get_colorclass(f.h24, 'h24')}
                                    is_btn={false}
                                    unit={'mm'}
                                />
                                <Meter
                                    key={`cp-h48-${i}-${j}`}
                                    headtitle={'48時間雨量'}
                                    value={f.h48}
                                    colorclass={get_colorclass(f.h48, 'h48')}
                                    is_btn={false}
                                    unit={'mm'}
                                />
                                <Meter
                                    key={`cp-sr-${i}-${j}`}
                                    headtitle={'土壌雨量指数'}
                                    value={f.sr}
                                    colorclass={get_colorclass(f.sr, 'sr')}
                                    is_btn={false}
                                    unit={''}
                                />
                            </MeterRowWrapper>
                        )}
                    </MeterWrapper>
                </ContentsWrapper>
            )}
        </Wrapper>
    )
}
// 市町村名スタイル
const CityNameWrapper = styled.div`
    color: white;
    font-size: 20px;
    margin: auto 1rem;
    min-width: 12.5rem;
`
// 雨量表示1行スタイル
const MeterRowWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &&& .meter{
        margin: 0.3rem;
        padding: 0.5rem;
        min-width: 180px;
        max-width: 240px;
        height: 80px;
    }
`
// 雨量表示ラッパースタイル
const MeterWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
// チェックポイント表示スタイル
const CheckpointWrapper = styled.div`
    color: white;
    font-size: 20px;
    border-bottom: thin ridge white; 
    text-align: left;
    padding-left: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    &:hover{
        text-decoration: underline;
    }
`
// ダッシュボードリンク表示スタイル
const DashboardLinkWrapper = styled.div`
    color: white;
    text-align: left;
    padding-left: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    &:hover{
        text-decoration: underline;
    }
`
const LoadingCheckpointWrapper = styled.div`
    color: white;
    font-size: 20px;
    border-bottom: thin ridge white; 
    text-align: left;
    padding-left: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
`
// コンテンツラッパー
const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    margin-top: 2rem;
    &&& a{
        text-decoration: none;
    }
`
// タイトルスタイル
const TitleWrapper = styled.div`
    font-weight: bold;
    font-size: 36px;
    color: white;
    margin: 1rem 0;
`
// 全体ラッパー
const Wrapper = styled.div`
    margin: 5vh 10vw;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`
