import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import RiskHeader from '../../contents/riskheader'
import ArchiveMenu from '../../contents/archive_menu'
import ArchiveDisasterList from '../../contents/archivedisasterlist'
import ArchiveTimeLine from '../../contents/archive_recent_timeline'
import CheckpointList from '../../contents/checkpointlist'
import { TypographySmallMargin } from '../../style/typography'
import { GetData } from '../../func/get_data_swr';
import ErrorMsg from '../../atoms/errormsg/index.jsx'

import {
    Divider,
    Paper,
    MenuList,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Grid,
} from "@mui/material";

import ArchiveIcon from '@mui/icons-material/Archive';
import HistoryIcon from '@mui/icons-material/History';

/**
 * アーカイブテンプレート
 * @returns {JSX.Element}
 */
export default function ArchivePage() {
    const [popupRiverName, setPopupRiverName] = useState('')

    // 災害IDと都道府県コードのパラメータを受け取る
    const { disaster_id } = useParams()

    // パラメータがある場合は，チェックポイントリスト画面に遷移する
    if (disaster_id) {
        return (
            <React.Fragment>
                <RiskHeader isArchive={false} />
                <ContentsWrapper>
                    <CheckpointList disasterId={disaster_id} />
                </ContentsWrapper>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <RiskHeader isArchive={false} />
            <Grid container>
                <Grid item md={3}
                    sx={{ borderRight: '1px solid rgba(54, 61, 78, 0.75)' }}>
                    <Paper sx={{
                        width: '100%',
                        height: 'calc(100svh - 48px)',
                        paddingTop: 2,
                        paddingLeft: 2,
                        backgroundColor: '#080625',
                        borderRight: '1px solid rgba(54, 61, 78, 0.75)'
                    }}>
                        <ArchiveMenu />
                    </Paper>
                </Grid>
                <Grid item md={9}>
                    <ContentsWrapper>
                        {/* アーカイブされた災害一覧 */}
                        {/* <ArchiveDisasterList /> */}
                        {/* 直近のアーカイブ一覧 */}
                        {/* <ArchiveTimeLine /> */}
                        <ErrorMsg msg={'データがありませんでした'} />

                    </ContentsWrapper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 48px);
`
