import React from 'react'
import styled, { keyframes } from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { Link } from 'react-router-dom'
import { config } from '../../config.jsx'

import ErrorMsg from '../../atoms/errormsg'
import { dateToYYYYMMDD } from '../../func/datetoview.jsx'

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 作成したシミュレーションの一覧
 * @returns {JSX.Element}
 */
export default function SimulationList() {
    // シミュレーションリストを取得する
    const { data, error } = useSWR(
        () => `${config.restUrl}/sim/simulation/list/`,
        fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 再試行は5回まで
                if (retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 50000)
            },
            refreshInterval: 300000
        }
    )

    const isLoading = !data && !error
    // Loading
    if (isLoading) {
        return (
            <>
                {[...Array(5)].map((e, i) =>
                    <SimulationWrapper key={`simulation-list-wrapper-${i}`}>
                        <SimulationHead key={`simulation-list-head-${i}`}>
                            <SimulationIcons key={`simulation-list-icon-${i}`} />
                            <SimulationPrefName key={`simulation-list-pref-name-${i}`}>都道府県名</SimulationPrefName>
                        </SimulationHead>
                        <LoadingSimulationTitle key={`simulation-list-title-${i}`}>読み込み中...</LoadingSimulationTitle>
                        <SimulationDate key={`simulation-list-period-${i}`}>期間：</SimulationDate>
                        <SimulationDate key={`simulation-list-update-date-${i}`}>最終更新日：</SimulationDate>
                    </SimulationWrapper>
                )}
            </>
        )
    }
    else if (error) {
        return (
            <ErrorMsg msg={'データがありませんでした'} />
            )
    }

    // 表示するシミュレーションのフィルタ
    const viewData = data.results

    if (viewData.length === 0) {
        return (
            <ErrorMsg msg={'データがありませんでした'} />
            )
    }

    return (
        <>
            {viewData.map((e, i) =>
                <SimulationWrapper key={`simulation-list-wrapper-${i}`}>
                    <SimulationHead key={`simulation-list-head-${i}`}>
                        <SimulationIcons key={`simulation-list-icon-${i}`} />
                        <SimulationPrefName key={`simulation-list-pref-name-${i}`}>{e.prf_name}</SimulationPrefName>
                    </SimulationHead>
                    <Link to={`/simulation/${e.simulation_id}/`}>
                        <SimulationTitle key={`simulation-list-title-${i}`}>{e.simulation_name}</SimulationTitle>
                    </Link>
                    <SimulationDate key={`simulation-list-period-${i}`}>期間：{dateToYYYYMMDD(e.simulation_start_date)} ~ {dateToYYYYMMDD(e.simulation_end_date)}</SimulationDate>
                    <SimulationDate key={`simulation-list-update-date-${i}`}>最終更新日：{dateToYYYYMMDD(e.update_date)}</SimulationDate>
                </SimulationWrapper>
            )}
        </>
    )
}

// シミュレーションラッパー ////////////////////
const LoadingAnimation = keyframes`
    to {
        clip-path: inset(0 -0.5ch 0 0)
    }
`

const SimulationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    &&& a{
        text-decoration: none;
    }
`
const SimulationHead = styled.div`
    color: white;
    width: 100%;
    display: flex;
    justify-contents: center;
`
const SimulationIcons = styled.div`
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #15335f;
`
const SimulationPrefName = styled.div`
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
`
const SimulationTitle = styled.div`
    color: white;
    font-weight: bold;
    font-size: 24px;
    text-align: left;
    margin: 0.1rem 0;
    width: fit-content;
    &:hover{
        text-decoration: underline;
    }
`
const LoadingSimulationTitle = styled(SimulationTitle)`
    display: inline-block;
    clip-path: inset(0 1ch 0 0);
    animation: ${LoadingAnimation} 1s steps(5) infinite;
`
const SimulationDate = styled.div`
    color: white;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
`
