import React, { useState } from 'react'
import styled from 'styled-components'

// Contents Component
import RiverGraph from '../rivergraph'
import TelList from '../tellist'
import CrsList from '../crslist'

// 水位系一覧のラッパー
const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding-left: 5vw;
    padding-right: 1rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
    overflow-y: scroll;
    height: 100%
`

// グラフラッパー
const GraphWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding-right: 5vw;
    padding-left: 1rem;
    margin: 0.5rem 0;
    height: auto;
    overflow-y: scroll;
`

/**
 * 河川水位画面を構成するコンポーネント
 * @param {number} bsnCode 水系コード
 * @param {string} bsnName 水系名
 * @param {string} chooseDate 選択した日付
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か 
 * @returns {JSX.Element}
 */
export default function RiverBoard(props){
    const { bsnCode, bsnName, disasterId='', checkpoint='', isArchive=false } = props

    // 選択中の水位計のID
    const [mtrCode, setMtrCode] = useState(null)
    // 選択中の水位計の種類
    const [mtrCategory, setMtrCategory] = useState('')

    function updateChooseMtr(code, category){
        setMtrCode(code)
        setMtrCategory(category)
    }

    return(
        <React.Fragment>
            <ListWrapper>
                <TelList
                    bsnCode={bsnCode}
                    mtrCode={mtrCode}
                    bsnName={bsnName}
                    updateChooseMtr={updateChooseMtr}
                    disasterId={disasterId}
                    checkpoint={checkpoint}
                    isArchive={isArchive}
                />
                <CrsList
                    bsnCode={bsnCode}
                    mtrCode={mtrCode}
                    bsnName={bsnName}
                    updateChooseMtr={updateChooseMtr}
                    disasterId={disasterId}
                    checkpoint={checkpoint}
                    isArchive={isArchive}
                />
            </ListWrapper>
            <GraphWrapper>
                <RiverGraph
                    mtrCode={mtrCode}
                    mtrCategory={mtrCategory}
                    disasterId={disasterId}
                    checkpoint={checkpoint}
                    isArchive={isArchive}
                />
            </GraphWrapper>
        </React.Fragment>
    )

}
