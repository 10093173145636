import * as React from 'react';
import style from 'styled-components'
import { BoxSpaceAround, BoxFlexStart } from '../../style/box'
import { Stack, Button, Box } from '@mui/material';


export default function LevelTitle(props) {

  return (
    <>
    {/* <BoxSpaceAround> */}
      {/* <AvatarIcon iconType={props.emergency_level} popup={props.popup} />*/}
      {/* <Stack>  */}
      <Button 
            id='from_button'
            variant="contained"
              sx={{
                width: '40%',
                color: 'white',
                backgroundColor: '#318ab7',
                '&:hover': {
                  backgroundColor: '#435e7a'
                }
              }}
            >ここを移動元に指定</Button>
            {/* <Box width='10px' height='10px' ></Box> */}
            <Button
            id='to_button'
              variant="contained"
            //   type="submit"
              sx={{
                width: '40%',
                color: 'white',
                backgroundColor: '#318ab7',
                '&:hover': {
                  backgroundColor: '#435e7a'
                }
              }}
            >ここを移動先に指定</Button> 
                 {/* </Stack> */}
    {/* </BoxSpaceAround> */}
    </>
  );
}
